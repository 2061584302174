<script setup lang="ts">
import ZyroFieldToggle from '@/components/global/ZyroFieldToggle.vue';
import { GAMIFICATION_TASK_CHANGE_LOGO } from '@/constants';
import { useGamificationStore } from '@/stores/gamificationStore';
import { useAddElement } from '@/use/useAddElement';
import { useGamification } from '@/use/useGamification';
import {
	ELEMENT_TYPE_BUTTON,
	ELEMENT_TYPE_SOCIAL_ICONS,
	PLACEHOLDER_POSITION,
} from '@zyro-inc/site-modules/constants';
import {
	computed,
	reactive,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

const SETTINGS_KEYS = {
	LOGO: 'showLogo',
	SHOPPING_CART: 'isCartVisible',
	LANGUAGE_SWITCHER: 'isLanguageSwitcherHidden',
} as const;

const ELEMENTS_TYPE = {
	BUTTON: {
		defaultElementKey: 'button',
		elementType: ELEMENT_TYPE_BUTTON,
	},
	SOCIAL_ICONS: {
		defaultElementKey: 'socialIcons',
		elementType: ELEMENT_TYPE_SOCIAL_ICONS,

	},
} as const;

type SettingsKey = typeof SETTINGS_KEYS[keyof typeof SETTINGS_KEYS];
type ElementType = typeof ELEMENTS_TYPE[keyof typeof ELEMENTS_TYPE];

const { t } = useI18n();
const {
	dispatch,
	getters,
	state,
} = useStore();

const {
	completeAchievement,
	isGamificationVisible,
} = useGamification();

const { defaultElements } = useAddElement();
const gamificationStore = useGamificationStore();

const currentBlockId = computed(() => state.currentBlockId);
const currentBlockSettings = computed(() => getters.currentBlockSettings);
const headerComponentsIds = computed(() => getters.headerBlock.components);

const getHeaderElementId = (type: string) => headerComponentsIds.value
	.find((componentId: string) => getters.siteElements[componentId].type === type);

const updateHeaderData = (key: SettingsKey, value: boolean) => {
	dispatch('updateBlockData', {
		blockId: currentBlockId.value,
		blockData: {
			settings: {
				[key]: value,
			},
		},
		merge: true,
	});
};

const toggleHeaderElement = (type: ElementType, value: boolean) => {
	if (value) {
		dispatch('addElement', {
			blockId: 'header',
			elementData: {
				...defaultElements.value[type.defaultElementKey].content,
				...PLACEHOLDER_POSITION,
			},
		});

		return;
	}

	dispatch('removeElement', {
		blockId: 'header',
		elementId: getHeaderElementId(type.elementType),
	});
};

const completeLogoGamification = (value: boolean) => {
	const changeLogoStep = gamificationStore.achievements.find(({ id }: { id: string}) => id === GAMIFICATION_TASK_CHANGE_LOGO);

	if (value && !changeLogoStep?.isCompleted && isGamificationVisible.value) {
		completeAchievement(GAMIFICATION_TASK_CHANGE_LOGO);
	}
};

const elementsList = reactive([
	{
		id: 'logo',
		title: t('builder.showLogo'),
		value: !!currentBlockSettings.value[SETTINGS_KEYS.LOGO],
		action: (newValue: boolean) => {
			completeLogoGamification(newValue);
			updateHeaderData(SETTINGS_KEYS.LOGO, newValue);
		},
	},
	{
		id: 'social-icons',
		title: t('builder.showSocialIcons'),
		value: !!getHeaderElementId(ELEMENTS_TYPE.SOCIAL_ICONS.elementType),
		action: (newValue: boolean) => toggleHeaderElement(ELEMENTS_TYPE.SOCIAL_ICONS, newValue),
	},
	{
		id: 'button',
		title: t('builder.showButton'),
		value: !!getHeaderElementId(ELEMENTS_TYPE.BUTTON.elementType),
		action: (newValue: boolean) => toggleHeaderElement(ELEMENTS_TYPE.BUTTON, newValue),
	},
	{
		id: 'shopping-cart',
		title: t('builder.showShoppingCart'),
		value: !!currentBlockSettings.value[SETTINGS_KEYS.SHOPPING_CART],
		isHidden: !getters['ecommerce/isStoreTypeZyro'],
		action: (newValue: boolean) => updateHeaderData(SETTINGS_KEYS.SHOPPING_CART, newValue),
	},
	{
		id: 'language-switcher',
		title: t('builder.showLanguageSwitcher'),
		value: !currentBlockSettings.value[SETTINGS_KEYS.LANGUAGE_SWITCHER],
		isHidden: !getters.hasLanguages,
		action: (newValue: boolean) => updateHeaderData(SETTINGS_KEYS.LANGUAGE_SWITCHER, !newValue),
	},
]);
</script>

<template>
	<ul class="list">
		<template
			v-for="item in elementsList"
			:key="item.id"
		>
			<li
				v-if="!item.isHidden"
				class="list__item"
			>
				<ZyroFieldToggle
					:id="`toggle-${item.id}`"
					v-qa="`toggle-${item.id}`"
					:label="item.title"
					:model-value="item.value"
					@update:model-value="item.action($event)"
				/>
			</li>
		</template>
	</ul>
</template>

<style lang="scss" scoped>
.list {
	list-style: none;
	padding: 0;
	margin: 0;

	&__item {
		cursor: pointer;
		margin-bottom: 16px;
		display: flex;

		&:nth-last-child(1) {
			margin-bottom: 0;
		}

		:deep(.zyro-field-toggle) {
			padding: 0;
		}
	}
}
</style>
