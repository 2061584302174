<template>
	<div
		:data-element-ref="elementId"
		class="ecommerce-button layout-element__component layout-element__component--GridEcommerceButton"
	>
		<GridButton
			ref="ecommerceRef"
			v-qa="'builder-gridelement-gridecommercebutton'"
			tag-name="button"
			:content="content"
			:type="type"
			:font-size-mobile="fontSizeMobile"
			:font-size-desktop="fontSizeDesktop"
			:font-family="fontFamily"
			:font-weight="fontWeight"
			:border-radius="borderRadius"
			:background-color="backgroundColor"
			:font-color="fontColor"
			:border-color="borderColor"
			:background-color-hover="backgroundColorHover"
			:font-color-hover="fontColorHover"
			:border-color-hover="borderColorHover"
			:border-width="borderWidth"
			is-in-builder
			@click.prevent
			@drag.prevent
			@dragstart.prevent
		/>
		<div
			v-if="!isProductValid"
			:style="warningStyle as Record<string, any>"
		>
			<ZyroTooltip
				toggle-event="hover"
				mode="dark"
				position="right"
			>
				<template #trigger>
					<ZyroSvgDeprecated
						name="warning"
						dimensions="32px"
					/>
				</template>
				{{ $t('builder.zyroEcommerceNoProducts') }}
			</ZyroTooltip>
		</div>
	</div>

	<ElementEditControls
		:target-ref="ecommerceRef?.$el"
		:element-id="elementId"
		:is-element-active="isActive"
		:enter-edit-mode-button-title="$t('builder.editButton.title')"
	>
		<template #edit-mode-popup>
			<EditButton @close="closeElementEditPopupHandler" />
		</template>
	</ElementEditControls>
</template>

<script setup lang="ts">
import {
	ref,
	computed,
	onMounted,
} from 'vue';

import ElementEditControls from '@/components/builder-controls/ElementEditControls.vue';
import ZyroSvgDeprecated from '@/components/global/ZyroSvgDeprecated.vue';
import ZyroTooltip from '@/components/global/ZyroTooltip.vue';
import EditButton from '@/components/builder-controls/edit-button/EditButton.vue';

import GridButton from '@zyro-inc/site-modules/components/elements/button/GridButton.vue';
import { useEcommerceStore } from '@/stores/ecommerceStore';
import { useGridButton } from '@zyro-inc/site-modules/components/elements/button/useGridButton';
import { useEcommerceGridButton } from '@zyro-inc/site-modules/components/elements/button/useGridEcommerceButton';
import { useElementEditPopup } from '@/use/useElementEditPopup';
import { SiteElement } from '@hostinger/builder-schema-validator';
import { useStore } from 'vuex';

const WARNING_SIZE = 32;

type Props = {
	data: SiteElement,
	elementId: string,
	isActive: boolean
}
const props = defineProps<Props>();

const { dispatch } = useStore();
const ecommerceStore = useEcommerceStore();
const ecommerceRef = ref<InstanceType <typeof ElementEditControls> | null>(null);

const {
	content,
	type,
	fontSizeMobile,
	fontSizeDesktop,
	fontFamily,
	fontWeight,
	borderRadius,
	backgroundColor,
	fontColor,
	borderColor,
	backgroundColorHover,
	borderWidth,
	fontColorHover,
	borderColorHover,
} = useGridButton(props, {
	href: computed(() => props.data.href),
});

const { productVariantId } = useEcommerceGridButton(props);

const { closeElementEditPopupHandler } = useElementEditPopup({
	elementId: props.elementId,
});

const warningStyle = computed(() => {
	if (!ecommerceRef.value) {
		return null;
	}

	return {
		top: `-${WARNING_SIZE / 2}px`,
		right: `-${WARNING_SIZE / 2}px`,
		position: 'absolute',
		zIndex: 1,
	};
});

// Need to check if the assigned product is still in the list.
// If not, make it -1 and indicate that the product is gone from the button.
const modifiedProductId = computed(() => {
	const isProductValid = productVariantId.value
				&& ecommerceStore.products?.some(
					(product) => product.variants.some((variant) => variant.id === productVariantId.value),
				);

	return isProductValid ? productVariantId.value : -1;
});

const isProductValid = computed(() => /^(?!(\s*|-1)$)/.test(modifiedProductId.value));

const fetchProductsData = async () => {
	// Need to fetch all products to ensure correct product id validaton in modifiedProductId computed logic
	if (ecommerceStore.count > ecommerceStore.products?.length) {
		await ecommerceStore.fetchProducts({
			limit: ecommerceStore.count,
		});
	}
};

const resetProductId = () => {
	if (modifiedProductId.value !== productVariantId.value) {
		dispatch('mergeElementData', {
			elementId: props.elementId,
			elementData: {
				settings: {
					productVariantId: modifiedProductId.value,
				},
			},
		});
	}
};

onMounted(async () => {
	await fetchProductsData();
	resetProductId();
});

</script>
<style scoped lang="scss">
.ecommerce-button {
	width: 100%;
	height: 100%;
}
</style>
