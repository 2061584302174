<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import {
	ref,
	onMounted,
	computed,
	watch,
} from 'vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import ZyroRadioList from '@/components/global/ZyroRadioList.vue';
import ZyroModal from '@/components/global/ZyroModal.vue';
import ZyroFieldToggle from '@/components/global/ZyroFieldToggle.vue';
import ZyroFieldColorPicker from '@/components/global/ZyroFieldColorPicker.vue';
import ColorPickerButton from '@/components/global/color-picker/ColorPickerButton.vue';
import ZyroSelect from '@/components/global/ZyroSelect.vue';
import { CLOSE_MODAL } from '@/store/builder/gui';
import { useQrModal } from '@/use/useQrModal';
import { EcommerceProduct } from '@zyro-inc/site-modules/types';
import {
	QrImageFormatType,
	QrPageOptionType,
} from '@/types';
import {
	QR_IMAGE_FORMATS,
	SYSTEM_LOCALE,
} from '@zyro-inc/site-modules/constants';
import EventLogApi from '@/api/EventLogApi';
import {
	MODAL_CREATE_QR,
	SELECTOR_DATA_PORTAL_MODAL,
	DATA_PORTAL_MODAL,
} from '@/constants';
import tinycolor from 'tinycolor2';
import {
	SitePage,
	SiteLanguage,
} from '@hostinger/builder-schema-validator';
import { isProductPage } from '@zyro-inc/site-modules/utils/ecommerce';
import { useEcommerceStore } from '@/stores/ecommerceStore';

const { t } = useI18n();
const {
	dispatch,
	state,
	getters,
} = useStore();
const ecommerceStore = useEcommerceStore();
const {
	selectedPage,
	selectedBackgroundColor,
	selectedColor,
	selectedFormat,
	isBackgroundEnabled,
	isBackgroundColorPickerOpen,
	isColorPickerOpen,
	pageUrlPreview,
	getQrCodePng,
	getQrCodeSvg,
} = useQrModal();

const qrPreview = ref('');

const pageId = computed(() => state.gui.activeModalSettings?.pageId);
const location = computed(() => state.gui.activeModalSettings?.location);
const currentLanguageData = computed(() => getters.currentLanguageData);
const siteLanguages = computed(() => getters.siteLanguages);
const sortPagesList = (pages: QrPageOptionType[]) => pages.sort((currentPage, nextPage) => currentPage.name.localeCompare(nextPage.name));

const isDefaultLanguage = (languageId: string) => languageId === getters.defaultLocale;
const isHomepage = (id: string, languageId: string) => siteLanguages.value[languageId].homePageId === id;

const getSlugFromMeta = (page: SitePage) => {
	if (!isProductPage(page.type as string)) {
		return page.slug;
	}

	const product = ecommerceStore.products.find((productData: EcommerceProduct) => productData.id === page.productId);

	return product?.seo_settings?.slug;
};

const getPageSlug = (page: SitePage, language: SiteLanguage, id: string, languageId: string) => `${isProductPage(page.type as string) || isDefaultLanguage(languageId) ? '' : `/${languageId}`}${isHomepage(id, languageId) ? '' : `/${getSlugFromMeta(page)}`}`;

const getPagesOptions = (languagesData: Record<string, SiteLanguage>) => {
	const allPages: SitePage[] = Object.entries(languagesData)
		.flatMap(([languageId, language]) => Object.entries(language.pages)
			.map(([id, page]) => ({
				...page,
				id,
				slug: getPageSlug(page, language, id, languageId),
			})));

	const allPagesOptions = allPages.map((page) => ({
		name: page.name,
		slug: page.slug,
		// TODO: Check schema types
		pageId: (page as any).id,
	} as QrPageOptionType));

	return sortPagesList(allPagesOptions);
};

const pagesList = computed(() => {
	if (Object.keys(siteLanguages.value).length === 1) {
		return getPagesOptions(siteLanguages.value);
	}

	const {
		[SYSTEM_LOCALE]: _,
		...languagesWithoutSystem
	} = siteLanguages.value;

	return getPagesOptions(languagesWithoutSystem);
});

const getSelectedPageById = (): QrPageOptionType => {
	const pageInItsLanguage = currentLanguageData.value.pages[pageId.value];

	if (pageInItsLanguage) {
		return {
			name: pageInItsLanguage.name,
			slug: getPageSlug(
				pageInItsLanguage,
				currentLanguageData.value,
				pageId.value,
				state.currentLocale,
			),
			pageId: pageId.value,
		};
	}

	// needed for ecommerce pages since they only exist in the default language
	const pageById = pagesList.value.find((item: QrPageOptionType) => item.pageId === pageId.value) || pagesList.value[0];

	return pageById;
};

const base64ToByteArray = (base64String: string) => {
	const byteCharacters = atob(base64String.split(',')[1]);
	const byteNumbers = [...byteCharacters].map((char) => char.charCodeAt(0));

	return new Uint8Array(byteNumbers);
};

const handleDownloadClick = async () => {
	EventLogApi.logEvent({
		eventName: 'website_builder.qr_code.download',
	});

	const isPng = selectedFormat.value === 'PNG';
	const qrCode = isPng ? qrPreview.value : await getQrCodeSvg();
	const type = isPng ? 'image/png' : 'image/svg+xml';

	const data = isPng ? base64ToByteArray(qrCode) : qrCode;

	const blob = new Blob([data], {
		type,
	});
	const url = URL.createObjectURL(blob);

	const link = document.createElement('a');

	const fileName = `qrcode-${selectedPage.value.name.toLowerCase().replaceAll(' ', '-')}`;

	link.href = url;
	link.download = `${fileName}.${selectedFormat.value.toLowerCase()}`;

	document.body.appendChild(link);

	link.click();

	document.body.removeChild(link);
};

const handleBackgroundColorChange = (color: string) => {
	selectedBackgroundColor.value = `#${tinycolor(color).toHex()}`.toUpperCase();
};

const handlePageChange = (page: { name: string, slug: string, pageId: string }) => {
	selectedPage.value = page;
};

const handleColorChange = (color: string) => {
	selectedColor.value = `#${tinycolor(color).toHex()}`.toUpperCase();
};

const handleFormatChange = (format: QrImageFormatType) => {
	selectedFormat.value = format;
};

const handleBackgroundTransparencyToggle = (value: boolean) => {
	isBackgroundEnabled.value = value;
};

const handleCloseClick = () => {
	dispatch(`gui/${CLOSE_MODAL}`, {
		name: MODAL_CREATE_QR,
	});
};

onMounted(async () => {
	EventLogApi.logEvent({
		eventName: 'website_builder.qr_code.get',
		eventProperties: {
			location: location.value,
		},
	});

	if (pageId.value) {
		selectedPage.value = getSelectedPageById();
	}

	qrPreview.value = await getQrCodePng();
});

watch([
	selectedPage,
	selectedColor,
	selectedBackgroundColor,
	isBackgroundEnabled,
], async () => {
	qrPreview.value = await getQrCodePng();
});

</script>

<template>
	<ZyroModal
		max-width="360px"
		height="auto"
		:data-portal="DATA_PORTAL_MODAL"
		content-padding="0 16px 16px"
		header-padding="16px"
		footer-padding="16px"
		class="qr-modal"
		:title="t('builder.pageSettingsQrCodeTitle')"
		@close-modal="handleCloseClick"
	>
		<div>
			<i18n-t
				tag="p"
				keypath="builder.pageSettingsQrCodeDefinition"
				class="text-body-2"
			>
				<a
					v-qa="'aibuilder-freemiummodal-link-login'"
					class="qr-modal__link z-link"
					href="//support.hostinger.com/en/articles/8908627-website-builder-how-to-generate-a-qr-code-of-a-page"
					target="_blank"
					rel="noopener noreferrer"
					v-text="t('common.learnMore')"
				/>
			</i18n-t>
			<div class="qr-modal__preview-wrapper">
				<div class="qr-modal__image-wrapper">
					<img
						v-if="qrPreview"
						:src="qrPreview"
						class="qr-modal__qr-preview"
						:alt="`${selectedPage.name} ${t('builder.pageSettingsQrCodeTitle')}`"
					>
				</div>
			</div>
			<div class="qr-modal__transparency-wrapper">
				<p class="text-bold-2">
					{{ t('common.background') }}
				</p>
				<ZyroFieldToggle
					id="toggleSchedulePostSwitch"
					v-qa="'editor-qrcodecreatemodal-btn-transparentbackgroundtoggle'"
					:model-value="isBackgroundEnabled"
					@update:model-value="handleBackgroundTransparencyToggle"
				/>
			</div>
			<div class="qr-modal__background-wrapper">
				<p class="text-bold-2">
					{{ t('common.backgroundColor') }}
				</p>
				<ZyroFieldColorPicker
					v-if="isBackgroundEnabled"
					:is-open="isBackgroundColorPickerOpen"
					:color="selectedBackgroundColor"
					class="qr-modal__background-color-picker"
					:portal-selector="SELECTOR_DATA_PORTAL_MODAL"
					@update-color="handleBackgroundColorChange"
					@toggle="isBackgroundColorPickerOpen = !isBackgroundColorPickerOpen"
					@click-outside="isBackgroundColorPickerOpen = false"
				/>
				<ColorPickerButton v-else />
			</div>
			<div class="qr-modal__color-wrapper">
				<p class="text-bold-2">
					{{ t('builder.pageSettingsQrCodePatternColor') }}
				</p>
				<ZyroFieldColorPicker
					:is-open="isColorPickerOpen"
					:color="selectedColor"
					class="qr-modal__color-picker"
					:portal-selector="SELECTOR_DATA_PORTAL_MODAL"
					@update-color="handleColorChange"
					@toggle="isColorPickerOpen = !isColorPickerOpen"
					@click-outside="isColorPickerOpen = false"
				/>
			</div>
			<div class="qr-modal__link-wrapper">
				<p class="text-bold-2">
					{{ t('common.linkTo') }}
				</p>
				<ZyroSelect
					v-qa="'editor-qrcodecreatemodal-select-changepagelink'"
					class="qr-modal__page-select"
					:options="pagesList"
					label-key="name"
					:model-value="selectedPage"
					:placeholder="t('builder.pageSettingsQrCodeFormatPlaceholder')"
					@update:model-value="handlePageChange"
				/>
				<p class="text-body-3 qr-modal__full-url">
					{{ t('builder.pageSettingsQrCodeFullUrl') }} {{ pageUrlPreview }}
				</p>
			</div>
			<div class="qr-modal__format-wrapper">
				<p class="text-bold-2">
					{{ t('common.format') }}
				</p>
				<ZyroRadioList
					v-qa="'editor-qrcodecreatemodal-select-changefileformat'"
					:model-value="selectedFormat"
					class="qr-modal__format-select"
					:options="QR_IMAGE_FORMATS"
					color="var(--color-primary)"
					border-width="2px"
					@update:model-value="handleFormatChange"
				/>
			</div>
		</div>

		<template #footer>
			<HostingerButton
				v-qa="'editor-qrcodecreatemodal-btn-download'"
				class="qr-modal__download-button"
				:disabled="!selectedFormat || !selectedPage"
				@click="handleDownloadClick"
			>
				{{ t('common.download') }}
			</HostingerButton>
		</template>
	</ZyroModal>
</template>

<style scoped lang="scss">
.qr-modal {
	padding: 16px;

	&__download-button {
		width: 100%;
	}

	&__image-wrapper {
		margin: 0 auto;
		border: 1px solid $color-gray-border;
		border-radius: 8px;
		overflow: hidden;
		box-sizing: content-box;
	}

	&__preview-wrapper {
		display: flex;
		margin-top: 16px;
	}

	&__image-wrapper,
	&__qr-preview {
		width: 160px;
		height: 160px;
	}

	&__full-url {
		color: $color-gray;
		margin-top: 4px;
	}

	&__background-wrapper,
	&__color-wrapper,
	&__transparency-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__background-wrapper,
	&__color-wrapper,
	&__link-wrapper {
		margin-bottom: 16px;
	}

	&__format-select,
	&__page-select {
		margin-top: 4px;
	}

	&__format-select {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}

	:deep() {
		.datalist {
			margin-top: 4px;
			font-size: 14px;
			color: $color-gray-dark;
			background-color: $color-light;
			border: 1px solid $color-gray-border;
			border-radius: 8px;
			transition: border 0.3s;

			&--opened {
				border-color: $color-primary;
			}

			.vs__search,
			.vs__selected {
				padding: 0;
				margin: 4px 4px 0 0;
				font-family: inherit;
				font-size: inherit;
				letter-spacing: inherit;
			}

			.vs__actions {
				padding: 6px 0 0 4px;
			}
		}

		.vs__dropdown-toggle {
			padding: 0 16px 4px;
		}

		.vs__dropdown-menu {
			left: -2px;
			width: calc(100% + 2px);
			height: auto;
			max-height: 130px;
			margin-top: 2px;
		}
	}
}
</style>
