<script setup lang="ts">
import ZyroTooltip from '@/components/global/ZyroTooltip.vue';
import Icon from '@/components/global/Icon.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import {
	GAMIFICATION_EVENT_NAMES,
	DRAWER_GAMIFICATION,
	NPS_TYPE_FEATURE_GAMIFICATION,
	SIDEBAR_WIDTH,
	HEADER_HEIGHT,
} from '@/constants';
import EventLogApi from '@/api/EventLogApi';
import {
	computed,
	ref,
	onMounted,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { OPEN_DRAWER } from '@/store/builder/gui';
import ZyroProgressBar from '@/components/reusable-components/ZyroProgressBar.vue';
import { useGamification } from '@/use/useGamification';
import { useStore } from 'vuex';
import BuilderNps from '@/components/builder-nps/BuilderNps.vue';
import { useNpsStore } from '@/stores';

const SUCCESS_TOOLTIP_TIMEOUT = 7000;

const emit = defineEmits(['close']);

const {
	completedAchievementsCount,
	achievements,
	lastCompletedAchievement,
	areAllAchievementsCompleted,
} = useGamification();
const { dispatch } = useStore();
const { t } = useI18n();
const npsStore = useNpsStore();

const isFeedbackSent = ref(false);
const isRatingSelected = ref(false);
const headingText = ref<string | undefined>('');
const timeoutId = ref<any>(null);

const progressPercentage = computed(() => (completedAchievementsCount.value / achievements.value.length) * 100);

const handleClose = () => {
	if (timeoutId.value) {
		clearTimeout(timeoutId.value);
	}

	emit('close');
};

const handleOpenDrawerClick = () => {
	handleClose();

	dispatch(`gui/${OPEN_DRAWER}`, {
		id: DRAWER_GAMIFICATION,
	});
};

const startCloseTimer = (timeout: number) => {
	if (timeoutId.value) {
		clearTimeout(timeoutId.value);
	}

	timeoutId.value = setTimeout(() => {
		emit('close');
	}, timeout);
};

const handleFeedBackSend = (event: {score: number, comment: string}) => {
	headingText.value = t('builder.gamificationFinishThanks');
	isFeedbackSent.value = true;

	EventLogApi.logEvent({
		eventName: GAMIFICATION_EVENT_NAMES.RATED,
		eventProperties: {
			score: event.score,
			comment: event.comment,
		},
	});

	window.hj('event', 'website_builder.gamefication.rated');

	startCloseTimer(SUCCESS_TOOLTIP_TIMEOUT);
};

const handleMouseEnter = () => {
	if (!areAllAchievementsCompleted.value) {
		clearTimeout(timeoutId.value);
	}
};

const handleMouseLeave = () => {
	if (!areAllAchievementsCompleted.value || isFeedbackSent.value) {
		startCloseTimer(SUCCESS_TOOLTIP_TIMEOUT);
	}
};

onMounted(() => {
	if (areAllAchievementsCompleted.value) {
		headingText.value = t('builder.gamificationCompletedTooltip');

		npsStore.setNpsData({
			isVisible: false,
			formType: NPS_TYPE_FEATURE_GAMIFICATION,
			newQuestion: t('builder.gamificationNpsQuestion'),
		});

		return;
	}

	headingText.value = lastCompletedAchievement.value?.tooltipText || '';

	startCloseTimer(SUCCESS_TOOLTIP_TIMEOUT);
});
</script>

<template>
	<ZyroTooltip
		position="right"
		content-position="absolute"
		toggle-event="none"
		:forced-position="{
			left: `${SIDEBAR_WIDTH + 10}px`,
			top: `${HEADER_HEIGHT + 8}px`,
			padding: '8px',
		}"
		:custom-triangle-style=" {
			top: '14px',
			bottom: 'unset',
		}"
		class="gamification-tooltip"
		:close-on-outside-click="false"
		has-close-icon
		@on-close="handleClose"
	>
		<div
			v-qa="'gamification-tooltip-task-completed'"
			class="gamification-tooltip__tooltip-content"
			@mouseenter="handleMouseEnter"
			@mouseleave="handleMouseLeave"
		>
			<div
				v-if="!isRatingSelected"
				class="gamification-tooltip__heading text-bold-2"
				:class="{ 'gamification-tooltip__heading--centered' : areAllAchievementsCompleted }"
			>
				<Icon
					v-if="!areAllAchievementsCompleted"
					class="gamification-tooltip__check"
					name="check_circle"
					color="success"
					is-filled
					dimensions="24px"
				/>
				{{ headingText }}
			</div>
			<div v-if="areAllAchievementsCompleted">
				<BuilderNps
					is-close-button-hidden
					class="gamification-tooltip__nps"
					:class="[
						{ 'gamification-tooltip__nps--comment-step' : isRatingSelected },
						{ 'gamification-tooltip__nps--thanks-step' : isFeedbackSent }
					]"
					@send-feedback="handleFeedBackSend"
					@select-rate="isRatingSelected = true"
					@click-back="isRatingSelected = false"
				>
					<template #thanks>
						<a
							v-if="isFeedbackSent"
							v-qa="'builder-gamificationtooltip-link-seereward'"
							class="gamification-tooltip__link z-link text-body-2"
							@click="handleOpenDrawerClick"
						>
							{{ t('builder.gamificationFinishRewardReady') }}
						</a>
					</template>
				</BuilderNps>
			</div>
			<div
				v-else
				class="gamification-tooltip__progress-wrapper"
			>
				<p class="gamification-tooltip__progress-text text-body-2">
					{{ t('builder.gamificationProgressTextSteps', [completedAchievementsCount, achievements.length]) }}
				</p>
				<ZyroProgressBar
					with-border
					class="gamification-tooltip__progress-bar"
					:percentage="progressPercentage"
					color="success"
				/>
				<HostingerButton
					v-qa="'builder-gamificationtooltip-link-opendrawer'"
					button-type="outlined"
					size="sm"
					class="gamification-tooltip__progress-button"
					@click="handleOpenDrawerClick"
				>
					<p class="gamification-tooltip__progress-button-text">
						{{ t('builder.gamificationCompleteSteps') }}
					</p>
					<Icon
						name="keyboard_arrow_right"
						dimensions="16px"
					/>
				</HostingerButton>
			</div>
		</div>
	</ZyroTooltip>
</template>

<style lang="scss" scoped>
.gamification-tooltip {
	box-shadow: $box-shadow-base;

	&__progress-bar {
		width: 276px;
	}

	&__progress-wrapper {
		margin-left: 28px;
	}

	&__heading {
		display: flex;
		align-items: center;
		margin-bottom: 4px;

		&--centered {
			margin-bottom: 0;
			justify-content: center;
		}
	}

	&__heading-img {
		height: 16px;
		object-fit: cover;
		margin-left: 4px;
	}

	&__nps {
		width: 100%;

		:deep() {
			.back-button {
				left: 0;
				top: 0;
			}

			.feedback-container {
				padding: 0 8px 8px;

				&--fixed-width {
					width: 452px;
				}

				&--fixed-height {
					min-height: 256px;
					height: unset;
				}

				&__title {
					font-size: 14px;
				}

				&__ratings-row {
					gap: 4px;
				}

				&__text-area {
					min-height: 96px;
				}

			}
		}

		&--comment-step {
			:deep(.feedback-container__title) {
				margin-top: 16px;
			}
		}

		&--thanks-step {
			:deep(.feedback-container--fixed-width) {
				width: 354px;
			}
		}
	}

	&__link {
		margin-top: 4px;
		text-decoration: underline;
	}

	&__progress-button-text {
		margin-right: 8px;
	}

	&__progress-button {
		margin-top: 4px;
		width: 100%;
	}

	&__check {
		animation: checkmark-scale 1s ease-in-out 0.7s;
		margin-right: 8px;
		will-change: transform;
		transform: scale(1);
		flex-shrink: 0;
	}

	@keyframes checkmark-scale {
		0% {
			transform: scale(1);
		}

		60% {
			transform: scale(1.3);
		}

		100% {
			transform: scale(1);
		}
	}
}
</style>
