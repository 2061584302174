import {
	computed,
	ref,
} from 'vue';

import {
	initializeChatbot,
	rateChatbotConversation,
	respondChatbot,
	restartChatbot,
} from '@/api/ChatbotApi';

import { sendNpsScore } from '@/api/NpsApi';
import { useStore } from 'vuex';
import { MEDIA_MOBILE_BREAKPOINT } from '@zyro-inc/site-modules/constants';
import { useI18n } from 'vue-i18n';
import { useNotifications } from '@/use/useNotifications';
import { captureException } from '@sentry/vue';
import { AxiosError } from 'axios';
import EventLogApi from '@/api/EventLogApi';
import {
	ChatbotConversationMessage,
	ChatbotRole,
	ChatbotTexts,
} from '@hostinger/hcomponents';
import { storeToRefs } from 'pinia';
import {
	useAiToolsStore,
	useNpsStore,
} from '@/stores';
import {
	AI_CHATBOT_FUNCTIONS,
	AI_CHATBOT_FUNCTION_NAME,
	AiTool,
} from '@/types';
import {
	AI_CHATBOT_FUNCTION_MAP,
	AI_CHATBOT_FUNCTION_TOOL_MAP,
} from '@/constants';

export const useChatbot = () => {
	const { state } = useStore();
	const { t } = useI18n();
	const { notify } = useNotifications();
	const { aiTools } = storeToRefs(useAiToolsStore());
	const npsStore = useNpsStore();

	const conversationHistory = ref<ChatbotConversationMessage[]>([]);
	const isChatbotResponding = ref(false);
	const isChatbotRestarting = ref(false);
	const isChatbotFeedbackLoading = ref(false);

	const isChatbotInitialized = computed(() => !!conversationHistory.value.length);
	const initialChatbotMessage = computed<ChatbotConversationMessage[]>(() => [
		{
			role: ChatbotRole.assistant,
			content: `${t('builder.aiChatbotGreetMessagePart1')}\n\n${t('builder.aiChatbotGreetMessagePart2')}`,
		},
	]);

	const chatbotTexts = computed<ChatbotTexts>(() => ({
		main: {
			title: t('builder.kodee'),
			betaBadge: t('common.beta'),
			tooltipFeedback: t('builder.aiChatbotLeaveFeedback'),
			tooltipReset: t('builder.aiChatbotRestart'),
			tooltipClose: t('common.close'),
			questionInputPlaceholder: t('builder.aiChatbotWriteQuestion'),
			disclaimer: t('builder.aiChatbotInaccurateInformation'),
			button: t('builder.aiChatbotAskAi'),
		},
		modalFeedback: {
			title: t('builder.aiChatbotRateYourExperience'),
			question: t('builder.nps.commentTitle'),
			scorePoor: t('builder.nps.poor'),
			scoreExcellent: t('builder.nps.excellent'),
			commentInputPlaceholder: t('builder.aiChatbotWriteYourFeedback'),
			confirmButton: t('common.send'),
			thanksMessage: t('builder.aiChatbotThankYouForFeedback'),
		},
		modalRestart: {
			title: t('builder.aiChatbotClearChat'),
			description: t('builder.aiChatbotClearChatDescription'),
			cancelButton: t('common.cancel'),
			confirmButton: t('builder.aiChatbotClearChat'),
		},
		functions: {
			[AI_CHATBOT_FUNCTION_MAP.AI_IMAGE]: t('builder.aiChatbotOpenAiImage'),
			[AI_CHATBOT_FUNCTION_MAP.AI_LOGO]: t('builder.aiChatbotOpenAiLogo'),
			[AI_CHATBOT_FUNCTION_MAP.AI_WRITER]: t('builder.aiChatbotOpenAiWriter'),
			[AI_CHATBOT_FUNCTION_MAP.AI_BLOG]: t('builder.aiChatbotOpenAiBlog'),
			[AI_CHATBOT_FUNCTION_MAP.AI_HEATMAP]: t('builder.aiChatbotOpenAiHeatmap'),
			[AI_CHATBOT_FUNCTION_MAP.AI_PAGE_GENERATOR]: t('builder.aiChatbotOpenAiPageGenerator'),
		},
	}));

	const chatbotInitialize = async () => {
		if (isChatbotInitialized.value) {
			return;
		}

		EventLogApi.logEvent({
			eventName: 'website_builder.chatbot.enter',
		});

		try {
			const { data } = await initializeChatbot();

			conversationHistory.value = data.history.length ? [
				...initialChatbotMessage.value,
				...data.history,
			] : initialChatbotMessage.value;
		} catch (error) {
			const errorCode = (error as AxiosError).response?.status as number;

			console.error(error);
			captureException(error);
			notify({
				message: t('builder.aiChatbotFailedToInitialize'),
			});

			if ([
				404,
				503,
			].includes(errorCode)) {
				conversationHistory.value = [
					...conversationHistory.value,
					{
						role: ChatbotRole.system,
						content: t('builder.aiChatbotUnavailable'),
					},
				];
			}
		}
	};

	const chatbotRespond = async (content: string) => {
		if (isChatbotResponding.value) {
			return;
		}

		conversationHistory.value = [
			...conversationHistory.value,
			{
				role: ChatbotRole.user,
				content,
			},
			{
				role: ChatbotRole.assistant,
			},
		];

		EventLogApi.logEvent({
			eventName: 'website_builder.chatbot.question_sent',
			eventProperties: {
				prompt: content,
			},
		});

		isChatbotResponding.value = true;

		try {
			const { data } = await respondChatbot({
				content,
			});

			isChatbotResponding.value = false;

			conversationHistory.value.pop();

			conversationHistory.value = [
				...conversationHistory.value,
				{
					...data.message,
					functions: data.functions,
				},
			];

			EventLogApi.logEvent({
				eventName: 'website_builder.chatbot.answer_generated',
			});
		} catch (error) {
			console.error(error);
			captureException(error);
			const errorCode = (error as AxiosError).response?.status as number;

			isChatbotResponding.value = false;
			conversationHistory.value.pop();

			if ([
				404,
				503,
			].includes(errorCode)) {
				conversationHistory.value = [
					...conversationHistory.value,
					{
						role: ChatbotRole.system,
						content: t('builder.aiChatbotUnavailable'),
					},
				];

				return;
			}

			if (errorCode === 500) {
				notify({
					message: t('builder.aiChatbotStatus500Message'),
				});
			}

			conversationHistory.value = [
				...conversationHistory.value,
				{
					role: 'assistant',
					content: t('builder.aiChatbotRephraseQuestion'),
				},
			];
		}
	};

	const chatbotRestart = async () => {
		try {
			isChatbotRestarting.value = true;

			await restartChatbot();

			conversationHistory.value = initialChatbotMessage.value;
		} catch (error) {
			console.error(error);
			captureException(error);
		}

		isChatbotRestarting.value = false;
	};

	const chatbotSubmitFeedback = async ({
		score,
		comment,
	}: { score: number, comment: string }) => {
		isChatbotFeedbackLoading.value = true;

		const npsRequest = sendNpsScore({
			score,
			comment,
			formType: 'ai-chatbot',
			siteId: state.websiteId,
			device: window.screen.width <= MEDIA_MOBILE_BREAKPOINT ? 'mobile' : 'desktop',
			importedWebsiteUrl: npsStore.npsData.importedWebsiteUrl,
		});

		const chatbotApiRequest = rateChatbotConversation({
			rating: score,
			message: comment || undefined,
		});

		await Promise.all([
			npsRequest,
			chatbotApiRequest,
		]);

		EventLogApi.logEvent({
			eventName: 'website_builder.feedback.sent',
			eventProperties: {
				rating: score,
				feedback: comment,
				location: 'chatbot',
			},
		});

		isChatbotFeedbackLoading.value = false;
	};

	const handleFunctionClick = ({
		name,
		arguments: args,
	}:{ name: AI_CHATBOT_FUNCTIONS | string, arguments?: { description?: string }}) => {
		const aiWriterTool = computed<AiTool | undefined>(
			() => aiTools.value.find((tool) => tool.id === AI_CHATBOT_FUNCTION_TOOL_MAP[name as AI_CHATBOT_FUNCTION_NAME]),
		);

		if (!aiWriterTool.value) {
			return;
		}

		aiWriterTool.value.clickHandler({
			location: 'ai_function',
			...(args?.description && {
				description: args.description,
			}),
		});
	};

	return {
		chatbotInitialize,
		chatbotRespond,
		conversationHistory,
		isChatbotResponding,
		chatbotRestart,
		isChatbotRestarting,
		chatbotSubmitFeedback,
		isChatbotFeedbackLoading,
		chatbotTexts,
		handleFunctionClick,
	};
};
