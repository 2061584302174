import { computed } from 'vue';
import { useStore } from 'vuex';
import { useEcommerceStore } from '@/stores/ecommerceStore';
import { useSeoStore } from '@/stores';

export const usePageSeoSettings = () => {
	const {
		getters,
		state,
		dispatch,
	} = useStore();
	const ecommerceStore = useEcommerceStore();
	const seoStore = useSeoStore();

	const productMetaUpdates = computed(() => state.ecommerce.productMetaUpdates);
	const currentPageId = computed(() => state.currentPageId);
	const sitePages = computed(() => getters.sitePages);
	const pageData = computed(() => (getters.isCurrentPageTypeDynamicProduct
		? seoStore.ecommercePages[ecommerceStore.currentDynamicPageProductId]
		: sitePages.value[currentPageId.value]));
	const isCurrentPageTypeEcommerceProduct = computed(() => getters.isCurrentPageTypeEcommerceProduct);
	const isDemoEcommerce = computed(() => !getters.siteMeta.ecommerceStoreId);
	const isSeoSettingsHidden = computed(
		() => isDemoEcommerce.value && isCurrentPageTypeEcommerceProduct.value,
	);
	const productSeoSettings = computed(() => {
		if (isCurrentPageTypeEcommerceProduct.value) {
			const product = ecommerceStore.productsSeo.find(
				({ id }) => id === pageData.value.productId,
			);

			if (!product) {
				return {};
			}

			return product.seo_settings;
		}

		return {};
	});
	const pageMeta = computed(() => {
		if (isCurrentPageTypeEcommerceProduct.value) {
			const metaChanges = (productMetaUpdates.value)[pageData.value.productId] || {};

			return {
				...productSeoSettings.value,
				...metaChanges,
			};
		}

		return pageData.value.meta ?? {};
	});
	const pageSlug = computed(() => {
		if (isCurrentPageTypeEcommerceProduct.value) {
			return pageMeta.value?.slug;
		}

		return pageData.value.slug;
	});
	const pageMetaTitle = computed(() => pageMeta.value.title ?? '');
	const pageMetaDescription = computed(() => pageMeta.value.description ?? '');
	const pageMetaKeywords = computed<string[]>(() => pageMeta.value.keywords ?? []);
	const pageMetaFocusKeyword = computed<string | null>(() => pageMeta.value.focusKeyword ?? null);
	const pageMetaNoIndex = computed(() => pageMeta.value.noindex ?? false);
	const pageName = computed(() => pageData.value.name);

	const mergePageMeta = (dataToMerge: Record<string, any>) => {
		if (isCurrentPageTypeEcommerceProduct.value && !isDemoEcommerce.value) {
			dispatch('ecommerce/setProductMetaUpdates', {
				productId: pageData.value.productId,
				productMeta: dataToMerge,
			});

			return;
		}

		dispatch('mergePageData', {
			pageId: currentPageId.value,
			pageData: {
				meta: {
					...dataToMerge,
				},
			},
		});
	};

	return {
		mergePageMeta,
		pageData,
		pageName,
		pageSlug,
		pageMetaTitle,
		pageMetaDescription,
		pageMetaKeywords,
		pageMetaFocusKeyword,
		pageMetaNoIndex,
		isSeoSettingsHidden,
	};
};
