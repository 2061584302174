<template>
	<div
		class="datalist"
		:class="[{ 'datalist--opened': isOpened }, { 'datalist--error': error }]"
	>
		<VueSelect
			v-qa="`dropdown-button-select-${modelValue?.[labelKey as string] ?? ''}`"
			:input-id="inputId"
			class="datalist__select"
			:class="[{ 'datalist__select--disabled': disabled }]"
			:options="options"
			:model-value="modelValue"
			:placeholder="placeholder"
			:label="labelKey"
			:filterable="filterable"
			:taggable="taggable"
			:create-option="createOption"
			:dropdown-should-open="getShouldDropdownOpen"
			:selectable="selectable"
			:searchable="searchable"
			:filter-by="filterBy"
			:disabled="disabled"
			@update:model-value="$emit('update:model-value', $event)"
			@open="onOpen"
			@close="onClose"
			@search="$emit('search', $event)"
			@search:blur="$emit('blur', $event)"
		>
			<template #open-indicator>
				<Icon
					v-show="!isArrowVisible"
					class="datalist__select-arrow"
					name="expand_more"
				/>
			</template>

			<template #no-options>
				<div class="datalist__no-results text-body-2">
					{{ $t('common.searchNoResults') }}
				</div>
			</template>

			<template #option="option: any">
				<slot
					name="option"
					:option="option"
				/>
			</template>

			<template #selected-option="option: any">
				<slot
					name="selected-option"
					:option="option"
				/>
			</template>
			<template #list-footer>
				<li
					v-show="isInfiniteScrollEnabled && hasNextPage"
					ref="infiniteScrollTriggerRef"
					class="datalist__loader-wrapper"
				>
					<ZyroLoader
						size="16px"
						weight="2px"
						color="black"
					/>
				</li>
			</template>
		</VueSelect>
	</div>
</template>

<script lang="ts" setup>
import Icon from '@/components/global/Icon.vue';
import VueSelect from 'vue-select';

import {
	computed,
	nextTick,
	onMounted,
	ref,
} from 'vue';
import ZyroLoader from '@zyro-inc/site-modules/components/ZyroLoader.vue';

type Props = {
	options: any;
	inputId?: string;
	labelKey?: string;
	placeholder?: string;
	modelValue: any;
	taggable?: boolean;
	createOption?: (newOption: string) => Record<string, unknown>;
	filterable?: boolean;
	filterBy?: (option: any, label: string, search: string) => boolean;
	searchable?: boolean;
	disabled?: boolean;
	shouldOpenDropdownOnInput?: boolean;
	isInfiniteScrollEnabled?: boolean;
	totalOptionsCount?: number;
	error?: string;
	selectable?: Function;
	isArrowVisible?: boolean;
};

const emit = defineEmits<{
	'update:model-value': [any],
	search: [string],
	'option:created': [],
	blur: [string],
	'load-more': [],
}>();

const props = withDefaults(defineProps<Props>(), {
	placeholder: '',
	filterable: true,
	searchable: true,
	disabled: false,
	shouldOpenDropdownOnInput: false,
	isInfiniteScrollEnabled: false,
	error: '',
	selectable: () => true,
});

const infiniteScrollTriggerRef = ref<HTMLElement | null>(null);
const isOpened = ref(false);
const intersectionObserver = ref<IntersectionObserver | null>(null);

const hasNextPage = computed(() => props.totalOptionsCount && props.options.length < props.totalOptionsCount);

const getShouldDropdownOpen = ({ search }: {search: string}) => (props.shouldOpenDropdownOnInput ? !!search.length : isOpened.value);
const onOpen = async () => {
	isOpened.value = true;

	await nextTick();

	if (!props.isInfiniteScrollEnabled || props.totalOptionsCount === props.options.length || !infiniteScrollTriggerRef.value) {
		return;
	}

	intersectionObserver.value?.observe(infiniteScrollTriggerRef.value);
};

const onClose = () => {
	isOpened.value = false;

	if (props.isInfiniteScrollEnabled) {
		intersectionObserver.value?.disconnect();
	}
};

onMounted(() => {
	if (!props.isInfiniteScrollEnabled) {
		return;
	}

	intersectionObserver.value = new IntersectionObserver(async ([
		{
			isIntersecting,
			target,
		},
	]) => {
		if (isIntersecting) {
			const ul = (target as any).offsetParent as HTMLElement;
			const { scrollTop } = (target as any).offsetParent;

			emit('load-more');

			await nextTick();
			ul.scrollTop = scrollTop;
		}
	});
});
</script>

<style lang="scss" scoped>
.datalist {
	$this: &;

	height: 48px;
	border-radius: 8px;
	background-color: var(--color-light);
	border: 1px solid var(--color-gray-border);

	&--opened {
		#{$this}__select-arrow {
			transform: rotateZ(180deg);
		}
	}

	&--error {
		border-color: $color-danger;
	}

	&__select {
		height: 100%;

		&--disabled {
			opacity: 0.5;
		}
	}

	&__select-arrow {
		transition: transform 0.2s;
	}

	&__no-results {
		padding: 8px;
	}

	&__loader-wrapper {
		display: flex;
		justify-content: center;
	}

	// Styles that overrides vue-select internal styles
	:deep() {
		// disable webkit added elements
		input::-webkit-search-decoration {
			display: none;
		}

		.v-select {
			position: relative;
			box-sizing: border-box;
			font-family: inherit;

			& * {
				box-sizing: border-box;
			}
		}

		$this: ".vs";

		// .vs--single.vs--open
		#{$this}--single#{$this}--open {
			// .vs--single.vs--open .vs__selected
			& #{$this}__selected {
				position: absolute;
				opacity: 0.4;
			}

			// .vs--single.vs--open.vs--searching .vs__selected
			&#{$this}--searching {
				& #{$this}__selected {
					opacity: 0;
				}
			}
		}

		.vs {
			&__clear {
				display: none;
			}

			&__dropdown-toggle {
				display: flex;
				height: 100%;

				// padding: 0 10px 4px;
			}

			&__selected {
				display: flex;
				align-items: center;
				height: 100%;
				padding: 12px 16px;
				font-size: 14px;
				line-height: 24px;
			}

			&__search,
			&__search:focus {
				z-index: 1;
				flex-grow: 1;
				width: 0;
				max-width: 100%;
				padding: 12px 16px;
				line-height: 24px;
				font-size: 14px;
				background: transparent;
				border: none;
				outline: none;

				&::-webkit-search-cancel-button {
					appearance: none;
				}
			}

			&__actions {
				display: flex;
				align-items: center;
				padding: 4px 16px 0 3px;
			}

			&__selected-options {
				position: relative;
				display: flex;
				flex-basis: 100%;
				flex-grow: 1;
				flex-wrap: wrap;
			}

			&__dropdown-menu {
				position: absolute;
				z-index: 2;
				width: 100%;
				min-width: 160px;
				max-height: 350px;
				padding: 8px 0;
				border-radius: 8px;
				overflow-y: auto;
				list-style: none;
				background-color: white;
				box-shadow: 0 0 12px 0 rgba(29, 30, 32, 16%);
			}

			&__dropdown-option {
				padding: 8px 16px;
				font-size: 14px;
				line-height: 24px;
				cursor: pointer;

				&--highlight {
					color: $color-dark;
					background-color: $color-gray-light;
					transition: background-color 0.1s;
				}
			}
		}
	}
}
</style>
