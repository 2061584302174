<script setup lang="ts">
import Icon from '@/components/global/Icon.vue';
import { useStore } from 'vuex';

import { getOptimizedSrc } from '@zyro-inc/site-modules/utils/getSrcsets';

import AssetManager from '@/components/builder-modals/modals/AssetManager.vue';
import { ASSETS_CATEGORY_IMAGE } from '@/constants';
import DOMPurify from 'dompurify';

import {
	computed,
	ref,
} from 'vue';
import { useI18n } from 'vue-i18n';

const PREVIEW_IMAGE_WIDTH = 400;
const PREVIEW_IMAGE_HEIGHT = 200;

type ImageSelection = {
	fullResolutionWidth: number;
	fullResolutionHeight: number;
	origin: string;
	path: string;
	url: string;
	alt: string;
}

type Props = {
	origin: string | null;
	path: string | null;
	objectFit: 'contain' | 'cover';
	logoSvg: string | null;
};

const props = withDefaults(defineProps<Props>(), {
	origin: null,
	path: '',
	objectFit: 'contain',
	logoSvg: null,
});

const emit = defineEmits(['update']);

const { state } = useStore();
const { t } = useI18n();

const showAssetManager = ref(false);

const sanitizedLogoSvg = computed(() => props.logoSvg && DOMPurify.sanitize(props.logoSvg));

const websiteId = computed(() => state.websiteId);
const selectedImageSrc = computed(() => getOptimizedSrc(props.origin, props.path as string, websiteId.value, {
	isLossless: true,
	width: PREVIEW_IMAGE_WIDTH,
	height: PREVIEW_IMAGE_HEIGHT,
	shouldContain: props.objectFit === 'contain',
}));

const computedStyles = computed(() => ({
	'--z-object-fit': props.objectFit || undefined,
}));

const selectorText = computed(() => (selectedImageSrc.value ? t('common.replaceImage') : t('common.addImage')));

const handleClick = () => {
	showAssetManager.value = true;
};

const handleImageSelection = (imageSelection: ImageSelection) => {
	showAssetManager.value = false;

	emit('update', imageSelection);
};
</script>

<template>
	<div
		class="zyro-image-selector"
		:style="computedStyles"
	>
		<button
			v-qa="'builder-sectionsettings-btn-addimage'"
			class="zyro-image-selector__button"
			:title="selectorText"
			@click="handleClick"
		>
			<img
				v-if="selectedImageSrc"
				:src="selectedImageSrc"
				alt=""
				class="zyro-image-selector__image"
			>
			<div
				v-else-if="sanitizedLogoSvg"
				class="zyro-image-selector__image--svg"
				v-html="sanitizedLogoSvg"
			/>
			<div class="zyro-image-selector__description">
				<Icon
					name="photo_library"
					class="zyro-image-selector__icon"
				/>
				<p class="text-body-2">
					{{ selectorText }}
				</p>
			</div>
		</button>
		<Teleport
			v-if="showAssetManager"
			to="body"
		>
			<AssetManager
				:visible-categories="[ASSETS_CATEGORY_IMAGE]"
				@select-image="handleImageSelection"
				@close="showAssetManager = false"
			/>
		</Teleport>
	</div>
</template>

<style lang="scss" scoped>
.zyro-image-selector {
	margin-bottom: 16px;

	&__button {
		position: relative;
		display: block;
		width: 100%;
		height: 200px;
		cursor: pointer;
		border-radius: 4px;
		background-color: $color-gray-border;
	}

	// Image is used instead of background image, because background-image flickers
	&__image {
		object-fit: var(--z-object-fit, contain);
		width: 100%;
		height: 100%;
		object-position: center center;
		border-radius: 4px;

		&--svg {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	&__icon {
		margin-bottom: 4px;
	}

	&__description {
		position: absolute;
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
		bottom: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		padding: 16px;
		color: $color-light;
		background: rgb(0 0 0 / 60%);
		backdrop-filter: blur(2px);
	}
}
</style>
