<template>
	<div class="maintenance-banner">
		<p class="maintenance-banner__message">
			{{ $t('builder.scheduledMaintenanceMessage', {
				month: $t('common.october'),
				day: 11,
				year: 2024
			}) }}
			<span>{{ $t('builder.scheduledMaintenanceThanYouMessage') }}</span>
		</p>
	</div>
</template>

<style lang="scss" scoped>
  .maintenance-banner {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    z-index: 1;
    width: 100%;
    padding: 12px 16px;
    background-color: $color-primary;
    height: 45px;

    &__message {
      color: $color-light;
      font-size: 16px;
      text-align: center;
    }

    @media screen and (max-width: $media-mobile) {
      padding: 4px 8px;

      &__message {
        color: $color-light;
        font-size: 12px;

        span {
          display: none;
        }
      }
    }
  }
  </style>
