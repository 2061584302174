<script setup lang="ts">
import Icon from '@/components/global/Icon.vue';
import SegmentedProgressBar from '@/components/global/SegmentedProgressBar.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import ZyroFieldTextArea from '@/components/global/ZyroFieldTextArea.vue';
import ZyroModal from '@/components/global/ZyroModal.vue';
import ZyroSelect from '@/components/global/ZyroSelect.vue';
import {
	AI_BLOG_TONE_OF_VOICE_INPUT_OPTIONS,
	AI_BLOG_LENGTH_OF_CONTENT_INPUT_OPTIONS,
	MODAL_UPGRADE_TO_BUSINESS_PLAN,
	ECOMMERCE_FUNNEL_LOCATIONS,
	AI_GENERATE_MAX_LENGTH,
} from '@/constants';
import { OPEN_MODAL } from '@/store/builder/gui';
import ZyroLoader from '@zyro-inc/site-modules/components/ZyroLoader.vue';
import {
	computed,
	ref,
	onMounted,
} from 'vue';
import { useI18n } from 'vue-i18n';
import {
	useUserStore,
	useAiToolsStore,
} from '@/stores';
import { useStore } from 'vuex';
import FeatureLockBanner from '@/components/FeatureLockBanner.vue';
import { useAiField } from '@/use/useAiField';

type Props = {
	isBlogBeingGenerated?: boolean,
	isSkipButtonHidden?: boolean
};

type Emits = {
	close: [],
	skip: [],
	generate: [{
		toneOfVoice: string,
		lengthOfContent: string,
		blogDescription: string,
	}]
}

const props = withDefaults(defineProps<Props>(), {
	isBlogBeingGenerated: false,
	isSkipButtonHidden: false,
});

const { dispatch } = useStore();

const emit = defineEmits<Emits>();

const { t } = useI18n();

const aiToolsStore = useAiToolsStore();
const userStore = useUserStore();

const {
	fieldValue,
	fieldError,
	fieldProgress,
	setFieldValue,
	onFieldBlur,
	validateField,
} = useAiField({
	initialValue: '',
});

const toneOfVoice = ref(AI_BLOG_TONE_OF_VOICE_INPUT_OPTIONS[0]);
const lengthOfContent = ref(AI_BLOG_LENGTH_OF_CONTENT_INPUT_OPTIONS[0]);

const isButtonDisabled = computed(() => props.isBlogBeingGenerated);

const handleToneOfVoiceUpdate = (newToneOfVoice: string) => {
	toneOfVoice.value = newToneOfVoice;
};

const handleLengthOfContentUpdate = (newToneOfVoice: string) => {
	lengthOfContent.value = newToneOfVoice;
};

const handleBlogGeneration = () => {
	if (!validateField()) {
		return;
	}

	if (userStore.areFeaturesLocked) {
		dispatch(`gui/${OPEN_MODAL}`, {
			name: MODAL_UPGRADE_TO_BUSINESS_PLAN,
			settings: {
				location: ECOMMERCE_FUNNEL_LOCATIONS.AI_BLOG_GENERATOR_MODAL,
			},
		});

		return;
	}

	emit('generate', {
		toneOfVoice: toneOfVoice.value,
		lengthOfContent: lengthOfContent.value,
		blogDescription: fieldValue.value,
	});
};

onMounted(() => {
	if (aiToolsStore.getAiToolsPrompt) {
		setFieldValue(aiToolsStore.getAiToolsPrompt);

		aiToolsStore.clearAiToolsPrompt();
	}
});
</script>

<template>
	<Teleport to="body">
		<ZyroModal
			:content-padding="'40px'"
			:max-width="'600px'"
			:height="'auto'"
			@close-modal="$emit('close')"
		>
			<form
				class="ai-blog-generator"
				@submit.prevent
			>
				<slot name="title">
					<div class="ai-blog-generator__title">
						<h2 class="ai-blog-generator__title-text text-heading-2">
							{{ $t('builder.aiBlogGeneratorCreateWithAi') }}
						</h2>
					</div>
				</slot>

				<div class="ai-blog-generator__form-inputs">
					<div class="ai-blog-generator__selects">
						<div class="ai-blog-generator__tone-of-voice-container">
							<label>{{ $t('builder.aiBlogGenerationToneOfVoice') }}</label>
							<ZyroSelect
								v-qa="'tone-of-voice-selectbox'"
								class="ai-blog-generator__tone-of-voice"
								:options="AI_BLOG_TONE_OF_VOICE_INPUT_OPTIONS"
								:model-value="toneOfVoice"
								:placeholder="$t('builder.aiBlogGenerationToneOfVoice')"
								label-key="key"
								@update:model-value="handleToneOfVoiceUpdate"
							>
								<template #selected-option="{ option }">
									<span class="ai-blog-generator__tone-of-voice">
										{{ t(`common.${option.key}`) }}
									</span>
								</template>

								<template #option="{ option }">
									<span
										v-qa="`tone-of-voice-option-${option.key}`"
										class="ai-blog-generator__tone-of-voice"
									>
										{{ t(`common.${option.key}`) }}
									</span>
								</template>
							</ZyroSelect>
						</div>

						<div class="ai-blog-generator__length-of-content-container">
							<label>{{ $t('builder.aiBlogGenerationLengthOfContent') }}</label>
							<ZyroSelect
								v-qa="'content-length-selectbox'"
								class="ai-blog-generator__content-length"
								:options="AI_BLOG_LENGTH_OF_CONTENT_INPUT_OPTIONS"
								:model-value="lengthOfContent"
								:placeholder="$t('builder.aiBlogGenerationLengthOfContent')"
								label-key="key"
								@update:model-value="handleLengthOfContentUpdate"
							>
								<template #selected-option="{ option }">
									<i18n-t
										tag="span"
										keypath="builder.aiBlogGenerationWordCount"
									>
										{{ `${option.key}` }}
									</i18n-t>
								</template>

								<template #option="{ option }">
									<i18n-t
										v-qa="`content-length-option-${option.key}`"
										tag="span"
										keypath="builder.aiBlogGenerationWordCount"
									>
										{{ `${option.key}` }}
									</i18n-t>
								</template>
							</ZyroSelect>
						</div>
					</div>
					<div class="ai-blog-generator__description-container">
						<ZyroFieldTextArea
							v-model="fieldValue"
							theme="hostinger"
							bold
							:label="$t('builder.blog.blogPost.description')"
							:placeholder="$t('builder.aiBlogGenerationDescriptionPlaceholder')"
							:min-height="120"
							:error="fieldError"
							is-resizable
							:maxlength="AI_GENERATE_MAX_LENGTH"
							@update:model-value="setFieldValue($event)"
							@text-area-blur="onFieldBlur"
						/>
						<SegmentedProgressBar
							:progress="fieldProgress.progress"
							:colors="fieldProgress.colors"
						/>
						<div class="ai-blog-generator__progress-text">
							<p class="text-body-3">
								{{ fieldProgress.label }}
							</p>
							<span class="text-body-3">{{ fieldValue.length }}/{{ AI_GENERATE_MAX_LENGTH }}</span>
						</div>
					</div>
				</div>

				<slot name="footer">
					<div class="ai-blog-generator__footer-content">
						<HostingerButton
							v-qa="'create-content-button'"
							class="ai-blog-generator__button"
							:is-disabled="isButtonDisabled"
							type="submit"
							@click="handleBlogGeneration"
						>
							<template #icon>
								<ZyroLoader
									v-if="isBlogBeingGenerated"
									size="24px"
									color="var(--color-light)"
								/>
								<Icon
									v-else
									name="auto_awesome"
									is-filled
								/>
							</template>
							{{ $t(
								`builder.${isBlogBeingGenerated ? 'aiBlogGenerationCreatingContent' : 'aiBlogGenerationCreateContent'}`
							) }}
						</HostingerButton>
						<HostingerButton
							v-if="!props.isSkipButtonHidden"
							v-qa="'ai-blog-generation-button-skip'"
							class="ai-blog-generator__footer-skip-button"
							button-type="text"
							@click="$emit('skip')"
						>
							{{ $t('builder.aiBlogGenerationSkip') }}
						</HostingerButton>
					</div>

					<FeatureLockBanner v-if="userStore.areFeaturesLocked" />
				</slot>
			</form>
		</ZyroModal>
	</Teleport>
</template>

<style lang="scss" scoped>
.ai-blog-generator {
	&__progress-text {
		display: flex;
		justify-content: space-between;
		margin-top: 4px;
		color: var(--color-gray);
	}

	&__title-text {
		text-align: center;
		margin-bottom: 24px;
	}

	&__selects {
		display: flex;
		gap: 16px;
		margin-bottom: 16px;

		&>div {
			width: 50%;

			&>label {
				font-size: 14px;
				line-height: 1.7;
				font-weight: 700;
			}
		}
	}

	&__description-container {
		margin-bottom: 24px;
	}

	&__footer-content {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__button {
		margin-bottom: 24px;
	}

	&__footer-skip-button {
		color: $color-primary;

		&:hover {
			cursor: pointer;
		}
	}
}
</style>
