import {
	ref,
	computed,
	watch,
} from 'vue';
import { PINIA_STORES } from '@/constants/stores';
import {
	PAGE_ID_PRIVATE,
	PAGE_TYPE_BLOG,
	PAGE_TYPE_PRIVATE,
	PAGE_TYPE_ECOMMERCE_DYNAMIC_PRODUCT,
} from '@zyro-inc/site-modules/constants';
import {
	SEO_DRAWER_TAB_ECOMMERCE,
	SEO_DRAWER_TAB_DEFAULT,
	DRAWER_SEO,
} from '@/constants';
import { defineStore } from 'pinia';
import { useStore } from 'vuex';
import { useEcommerceStore } from '@/stores/ecommerceStore';
import { useI18n } from 'vue-i18n';
import { SeoDrawerTab } from '@/types/seoTypes';
import { isProductPage } from '@zyro-inc/site-modules/utils/ecommerce';
import { EcommerceProductSeoSettingsData } from '@zyro-inc/site-modules/types';
import { SitePages } from '@hostinger/builder-schema-validator';

const ITEMS_PER_PAGE = 25;

export const useSeoStore = defineStore(PINIA_STORES.SEO, () => {
	const {
		getters,
		state,
		dispatch,
	} = useStore();
	const ecommerceStore = useEcommerceStore();
	const { t } = useI18n();

	const isStoreTypeZyro = computed(() => getters['ecommerce/isStoreTypeZyro']);
	const seoDrawerTabs = computed<SeoDrawerTab[]>(() => [
		{
			id: SEO_DRAWER_TAB_DEFAULT,
			label: t('builder.seoDrawer.mainPages'),
		},
		...ecommerceStore.productsSeo.length > 0 && isStoreTypeZyro.value ? [
			{
				id: SEO_DRAWER_TAB_ECOMMERCE,
				label: t('builder.seoDrawer.productPages'),
			},
		] : [],
	]);

	const searchValue = ref('');

	const currentTab = ref(seoDrawerTabs.value[0]);
	const pagesToDisplay = ref<SitePages>({});
	const currentPage = ref(1);

	const activeDrawer = computed(() => state.gui.activeDrawer);
	const currentLocale = computed<string>(() => state.currentLocale);
	const defaultLocale = computed<string>(() => getters.defaultLocale);
	const sitePages = computed<SitePages>(() => getters.sitePages);
	const dynamicProductPageId = computed(() => Object.entries(sitePages.value)
		.find(([_, page]) => page.type === PAGE_TYPE_ECOMMERCE_DYNAMIC_PRODUCT)?.[0] ?? '');

	const defaultNavigationPages = computed(() => Object.fromEntries(
		Object.entries(sitePages.value).filter(
			([_, page]) => !([
				PAGE_TYPE_BLOG,
				PAGE_TYPE_PRIVATE,
			].includes(page.type || '') || isProductPage(page.type || '')),
		),
	));

	const generateEcommercePages = (products: EcommerceProductSeoSettingsData[]) => {
		const productPages = products.map((product) => ([
			product.id,
			{
				name: product.title,
				slug: product.seo_settings.slug,
				type: PAGE_TYPE_ECOMMERCE_DYNAMIC_PRODUCT,
				blocks: sitePages.value[dynamicProductPageId.value]?.blocks ?? [],
				meta: product.seo_settings,
				productId: product.id,
			},
		]));

		return Object.fromEntries(productPages) as SitePages;
	};

	const ecommercePages = computed<SitePages>(() => (ecommerceStore.isDynamicPageFlowEnabled
		? generateEcommercePages(ecommerceStore.productsSeo)
		: getters.ecommerceProductPages
	));

	const currentTabPages = computed(() => (
		currentTab.value.id === SEO_DRAWER_TAB_ECOMMERCE
			? ecommercePages.value
			: defaultNavigationPages.value
	));
	const totalPages = computed(() => Math.ceil(Object.keys(currentTabPages.value).length / ITEMS_PER_PAGE));

	const setCurrentTab = (tab: { id: string; label: string }) => {
		currentTab.value = tab;
	};

	const setCurrentPage = (page: number) => {
		currentPage.value = page;
	};

	const setSearchValue = (value: string) => {
		searchValue.value = value;
	};

	const filterPagesBySearchQuery = () => {
		const query = searchValue.value.trim();
		const nonAlphabeticCharacters = /[^A-Za-z]/g;
		const escapedQuery = query.replace(nonAlphabeticCharacters, '\\$&');
		const regex = new RegExp(escapedQuery, 'i');

		return Object.fromEntries(
			Object.entries(currentTabPages.value).filter(
				([_, page]) => (page.slug && regex.test(page.slug)) || (page.name && regex.test(page.name)),
			),
		);
	};

	const getCurrentTabPages = () => {
		const pages = searchValue.value ? filterPagesBySearchQuery() : currentTabPages.value;

		return Object.fromEntries(
			Object.entries(pages).slice(0, ITEMS_PER_PAGE * currentPage.value),
		);
	};

	const setPagesToDisplay = (pages: SitePages) => {
		// Remove private page from SEO list
		const {
			[PAGE_ID_PRIVATE]: _private,
			...rest
		} = pages;

		pagesToDisplay.value = rest;
	};

	watch([
		currentTab,
		currentLocale,
		activeDrawer,
	], () => {
		if (
			currentTab.value.id === SEO_DRAWER_TAB_ECOMMERCE
			&& currentLocale.value !== defaultLocale.value
			&& activeDrawer.value === DRAWER_SEO
		) {
			dispatch('updateCurrentLocale', defaultLocale.value);
		}
	});

	const openSEODrawer = ({
		pageId,
		isSeoSlugInvalid = false,
	}: { pageId: string, isSeoSlugInvalid?: boolean}) => {
		dispatch('gui/OPEN_DRAWER', {
			id: DRAWER_SEO,
			settings: {
				pageId,
				isSeoSlugInvalid,
			},
		});

		const page = sitePages.value[pageId];

		const seoDrawerTabId = isProductPage(page?.type || '') ? SEO_DRAWER_TAB_ECOMMERCE : SEO_DRAWER_TAB_DEFAULT;

		setCurrentTab(seoDrawerTabs.value.find((tab) => tab.id === seoDrawerTabId) || seoDrawerTabs.value[0]);
	};

	return {
		currentTab,
		setCurrentTab,
		pagesToDisplay,
		currentPage,
		getCurrentTabPages,
		totalPages,
		setPagesToDisplay,
		setCurrentPage,
		currentTabPages,
		defaultNavigationPages,
		ecommercePages,
		seoDrawerTabs,
		searchValue,
		setSearchValue,
		openSEODrawer,
		dynamicProductPageId,
	};
});
