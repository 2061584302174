<template>
	<div
		ref="builderHeaderRef"
		v-qa="`builder-header-main`"
		class="builder-header"
	>
		<div class="builder-header__items">
			<BuilderSidebarSettingsButton />
			<div class="builder-header__separator" />
			<BuilderHeaderProductPageTemplateButton v-if="isProductPageTemplateButtonVisible" />
		</div>

		<div class="builder-header__items builder-header__items--right">
			<template v-if="isMultilingualSwitcherVisible">
				<BuilderHeaderMultilingualSwitcher />
				<div class="builder-header__separator" />
			</template>
			<div class="builder-header__controls">
				<BuilderHeaderScreenToggleButton />
				<div class="builder-header__separator" />
				<BuilderHeaderHistoryButtons />

				<template v-if="!isMobileScreen">
					<template v-if="customDomain && !isUserPayToPublish">
						<div class="builder-header__separator" />
						<BuilderHeaderDomainConnectionStatus />
					</template>
				</template>
				<div class="builder-header__separator" />
				<BuilderHeaderSaveButton @mouseleave="setIsSavePopupVisible(false)" />
			</div>
			<BuilderHeaderBlogButtons v-if="isCurrentPageTypeBlog" />
			<div class="builder-header__separator" />
			<BuilderHeaderPreviewButton />
		</div>
		<div class="builder-header__separator" />
		<BuilderHeaderPrivateModeButton />
		<BuilderHeaderPublishButton />
	</div>
</template>

<script setup>
import { useStore } from 'vuex';
import {
	computed,
	ref,
	onMounted,
} from 'vue';
import BuilderSidebarSettingsButton from '@/components/builder-view/sidebar/BuilderSidebarSettingsButton.vue';
import BuilderHeaderSaveButton from '@/components/builder-view/headers/BuilderHeaderSaveButton.vue';
import BuilderHeaderScreenToggleButton from '@/components/builder-view/headers/BuilderHeaderScreenToggleButton.vue';
import BuilderHeaderPrivateModeButton from '@/components/builder-view/headers/BuilderHeaderPrivateModeButton.vue';
import BuilderHeaderDomainConnectionStatus from '@/components/builder-view/BuilderHeaderDomainConnectionStatus.vue';
import BuilderHeaderPublishButton from '@/components/builder-view/headers/BuilderHeaderPublishButton.vue';
import BuilderHeaderMultilingualSwitcher from '@/components/builder-view/headers/BuilderHeaderMultilingualSwitcher.vue';
import BuilderHeaderBlogButtons from '@/components/builder-view/headers/BuilderHeaderBlogButtons.vue';
import BuilderHeaderPreviewButton from '@/components/builder-view/headers/BuilderHeaderPreviewButton.vue';
import {
	GUI_NAMESPACE,
	UPDATE_HEADER_HEIGHT,
} from '@/store/builder/gui';
import { SYSTEM_LOCALE } from '@zyro-inc/site-modules/constants';
import { useHeaderPopups } from '@/use/useHeaderPopups';
import BuilderHeaderHistoryButtons from '@/components/builder-view/headers/BuilderHeaderHistoryButtons.vue';
import { useUserAuthorizationState } from '@/use/useUserAuthorizationState';
import { useDomainStore } from '@/stores/domainStore';
import BuilderHeaderProductPageTemplateButton from '@/components/builder-view/headers/BuilderHeaderProductPageTemplateButton.vue';

const {
	getters,
	state,
	dispatch,
} = useStore();
const domainStore = useDomainStore();

const { setIsSavePopupVisible } = useHeaderPopups();
const { isUserPayToPublish } = useUserAuthorizationState();

const builderHeaderRef = ref(null);

const customDomain = computed(() => domainStore.customDomain);
const currentLocale = computed(() => state.currentLocale);
const isCurrentPageTypeBlog = computed(() => getters.isCurrentPageTypeBlog);
const hasLanguages = computed(() => getters.hasLanguages);
const isMultilingualSwitcherVisible = computed(() => hasLanguages.value && currentLocale.value !== SYSTEM_LOCALE);
const isMobileScreen = computed(() => state.gui.isMobileScreen);
const isProductPageTemplateButtonVisible = computed(() => getters.isCurrentPageTypeDynamicProduct);

onMounted(() => {
	dispatch(`${GUI_NAMESPACE}/${UPDATE_HEADER_HEIGHT}`, builderHeaderRef.value.clientHeight);
});
</script>

<style lang="scss" scoped>
@import "./BuilderHeader";
</style>
