<template>
	<BuilderSidebarActionButton
		ref="triggerButton"
		v-qa="'builder-sidebar-btn-feedback'"
		:data-trigger-reference="BUILDER_NPS_TRIGGER_SELECTOR"
		:is-active="isActive"
		:title="title"
		custom-icon="sidebar-feedback"
		@action-click="$emit('action-click')"
	/>
	<Popup
		v-if="isActive"
		is-fixed
		:target-ref="$refs.triggerButton?.$el"
		:padding="isMobileScreen ? 0 : 16"
		auto-update
		@click-outside="closeHandler"
	>
		<BuilderNps
			class="sidebar-popup"
			@close="closeHandler"
		/>
	</Popup>
</template>

<script setup>
import Popup from '@/components/global/Popup.vue';
import BuilderSidebarActionButton from '@/components/builder-view/sidebar/BuilderSidebarActionButton.vue';

import { useStore } from 'vuex';

import { setCookie } from '@zyro-inc/site-modules/utils/cookies';

import BuilderNps from '@/components/builder-nps/BuilderNps.vue';
import { BUILDER_NPS_TRIGGER_SELECTOR } from '@/components/builder-nps/npsSelectors';
import {
	COOKIE_NPS_HIDDEN,
	COOKIE_NPS_HIDDEN_DURATION,
} from '@/constants';
import {
	watch,
	computed,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useNpsStore } from '@/stores';

const props = defineProps({
	isActive: {
		type: Boolean,
		required: true,
	},
	title: {
		type: String,
		required: true,
	},
});
const emit = defineEmits([
	'toggle',
	'close-popup',
	'action-click',
]);
const { t } = useI18n();
const { state } = useStore();
const npsStore = useNpsStore();

const isMobileScreen = computed(() => state.gui.isMobileScreen);

const closeHandler = () => {
	npsStore.setNpsData({
		newQuestion: t('builder.nps.ratingQuestion'),
		isVisible: false,
	});

	setCookie(COOKIE_NPS_HIDDEN, true, COOKIE_NPS_HIDDEN_DURATION);

	emit('close-popup');
};

watch(() => props.isActive, (isNpsActive) => {
	if (isNpsActive) {
		npsStore.setNpsVisible(true);

		return;
	}

	closeHandler();
});
</script>

<style lang="scss" scoped>
@import "./BuilderSidebarPopup";
</style>
