import { PINIA_STORES } from '@/constants/stores';
import { useGuiGetters } from '@/use/useGuiGetters';
import { defineStore } from 'pinia';

// Placeholder for now to reduce test dependency on gui's vuex store
// otherwise we need to mock useStore in tests and after full vuex to pinia migration
// we will need to refactor tests to work with pinia
export const useGuiStore = defineStore(PINIA_STORES.GUI, () => {
	const { isMobileScreen } = useGuiGetters();

	return {
		isMobileScreen,
	};
});
