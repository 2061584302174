import {
	BLANK_LAYOUT_TEMPLATE_ID,
	GAMIFICATION_AI_BUILDER_VISIBILITY_TIMESTAMP,
	GAMIFICATION_VISIBILITY_TIMESTAMP,
} from '@/constants';
import { PINIA_STORES } from '@/constants/stores';
import { useGuiStore } from '@/stores/guiStore';
import { useSiteStore } from '@/stores/siteStore';
import { Achievement } from '@/types/gamification';
import { AI_GENERATED_TEMPLATE_ID } from '@zyro-inc/site-modules/constants';
import { defineStore } from 'pinia';
import {
	computed,
	ref,
} from 'vue';

export const useGamificationStore = defineStore(PINIA_STORES.GAMIFICATION, () => {
	const ws = ref<WebSocket>();
	const achievements = ref<Achievement[]>([]);
	const isGamificationLoaded = ref(false);
	const lastCompletedAchievement = ref<Achievement>();
	const createdAt = ref<number>();
	const siteStore = useSiteStore();
	const guiStore = useGuiStore();

	const completedAchievementsCount = computed(() => achievements.value.filter(({ isCompleted }) => isCompleted).length);
	const isGamificationAvailableForSite = computed(() => {
		const isBlankTemplate = siteStore.siteMeta.template === BLANK_LAYOUT_TEMPLATE_ID;

		if (guiStore.isMobileScreen || isBlankTemplate) {
			return false;
		}

		if (!createdAt.value) {
			return false;
		}

		const siteCreationTimestamp = Math.floor(new Date(createdAt.value).getTime() / 1000);

		return siteStore.siteMeta.template === AI_GENERATED_TEMPLATE_ID
			? siteCreationTimestamp > GAMIFICATION_AI_BUILDER_VISIBILITY_TIMESTAMP
			: siteCreationTimestamp > GAMIFICATION_VISIBILITY_TIMESTAMP;
	});

	const setWebsocketObject = (value?: WebSocket) => {
		if (value instanceof WebSocket) {
			ws.value = value;

			return;
		}

		ws.value = undefined;
	};

	const setAchievements = (value: Achievement[]) => {
		achievements.value = value;
	};

	const setIsGamificationLoaded = (value: boolean) => {
		isGamificationLoaded.value = value;
	};

	const setLastCompletedAchievement = (value: Achievement) => {
		lastCompletedAchievement.value = value;
	};

	const setCreatedAt = (value: number) => {
		createdAt.value = value;
	};

	return {
		ws,
		achievements,
		isGamificationLoaded,
		lastCompletedAchievement,
		createdAt,
		completedAchievementsCount,
		setWebsocketObject,
		setAchievements,
		setIsGamificationLoaded,
		setLastCompletedAchievement,
		setCreatedAt,
		isGamificationAvailableForSite,
	};
});
