import {
	addBreadcrumb,
	captureException,
} from '@sentry/vue';

import {
	saveSite,
	validateSiteJSON,
} from '@/api/SitesApi';

import {
	patcher,
	createDiff,
} from '@/utils/jsondiffpatch';
import { getSiteMetaFlags } from '@/utils/getSiteMetaFlags';
import { TEMPLATES_ACCOUNT_EMAIL } from '@/constants/index';
import { useSiteStore } from '@/stores/siteStore';
import { useEcommerceStore } from '@/stores/ecommerceStore';

const AUTOSAVE_INTERVAL = 120000; // autosave every 2 minutes

export const SET_IS_SAVING = 'SET_IS_SAVING';
export const SET_SITE_DATA_SNAPSHOT = 'SET_SITE_DATA_SNAPSHOT';
export const SET_CLIENT_TIMESTAMP = 'SET_CLIENT_TIMESTAMP';
export const SET_TIMER = 'SET_TIMER';
export const CLEAR_TIMER = 'CLEAR_TIMER';

export const getErrorMessagePath = (error) => {
	switch (error.response?.status) {
	case 401: return 'builder.unauthorizedSavingNotification';
	case 409: return 'builder.changesOnMultipleDevices';
	case 403: return 'builder.embeddedCodeEditDisabled';
	default: return 'builder.defaultSavingNotification';
	}
};

const validateSiteJSONHandler = async ({
	websiteId,
	website,
}) => {
	const { errors } = await validateSiteJSON({
		siteId: websiteId,
		siteData: website,
	});

	if (errors.length) {
		addBreadcrumb({
			category: 'JSON_VALIDATION',
			message: 'Invalid JSON after save',
			data: {
				siteData: website,
				errors,
			},
		});
	}
};

export default {
	namespaced: true,
	state: {
		isSaving: false,
		clientTimestamp: null, // timestamp, received after successful save. it was not renamed due to backwards compatibility
		siteDataSnapshot: null, // used to deep compare with current website data and is reset after successful save
		timer: null, // setTimeout identifier
	},
	getters: {
		// explicitly setting/getting diff as undefined because jsondiffpatch returns undefined when objects are deep equal
		unsavedSiteDataDiff: (state) => {
			const siteStore = useSiteStore();

			return state.siteDataSnapshot
				? createDiff(state.siteDataSnapshot, siteStore.site)
				: undefined;
		},
		hasUnsavedSeoChanges: (state, getters, rootState) => {
			const metaUpdates = rootState.ecommerce.productMetaUpdates;

			return metaUpdates && (Object.keys(metaUpdates).length > 0);
		},
		hasUnsavedChanges: (state, getters) => getters.hasUnsavedSeoChanges || typeof getters.unsavedSiteDataDiff !== 'undefined',
		canSave: (state, getters, rootState) => rootState.websiteId && getters.hasUnsavedChanges && !state.isSaving,
	},
	mutations: {
		[SET_SITE_DATA_SNAPSHOT]: (state, value) => {
			state.siteDataSnapshot = value;
		},
		[SET_IS_SAVING]: (state, value) => {
			state.isSaving = value;
		},
		[SET_CLIENT_TIMESTAMP]: (state, value) => {
			state.clientTimestamp = value;
		},
		[SET_TIMER]: (state, timer) => {
			state.timer = timer;
		},
		[CLEAR_TIMER]: (state) => {
			clearInterval(state.timer);
		},
	},
	actions: {
		updateClientTimestamp: ({ commit }, timestamp) => {
			commit(SET_CLIENT_TIMESTAMP, timestamp);
		},
		createSiteDataSnapshot: ({ commit }, { siteData }) => {
			commit(SET_SITE_DATA_SNAPSHOT, patcher.clone(siteData));
		},
		saveWebsite: async ({
			state,
			rootState,
			rootGetters,
			dispatch,
			commit,
		}, { saveWhenImpersonating = false } = {}) => {
			const siteStore = useSiteStore();
			const ecommerceStore = useEcommerceStore();
			const website = siteStore.site;

			const {
				user,
				websiteId,
			} = rootState;

			if ((user.user?.isStaff && !saveWhenImpersonating) || rootGetters.isLocalDevelopmentPlaygroundMode) {
				return;
			}

			commit(CLEAR_TIMER);
			commit(SET_IS_SAVING, true);

			addBreadcrumb({
				category: 'CLIENT_TIMESTAMP',
				message: 'Before Save',
				data: {
					clientTimestamp: state.clientTimestamp,
				},
			});

			validateSiteJSONHandler({
				websiteId,
				website,
			});

			try {
				const updatedProducts = await ecommerceStore.updateProductsSeoData();

				if (updatedProducts) {
					const updatedProductSeo = ecommerceStore.productsSeo.map((product) => {
						const mathcingProduct = updatedProducts.find((productData) => productData.id === product.id);

						if (!mathcingProduct) {
							return product;
						}

						return {
							...product,
							seo_settings: {
								...mathcingProduct.seo_settings,
							},
						};
					});

					ecommerceStore.updateStoreProductsSeo(updatedProductSeo);
					dispatch('ecommerce/updateStoreProducts', updatedProducts, {
						root: true,
					});
					dispatch('ecommerce/setProductMetaUpdates', null, {
						root: true,
					});
				}

				const siteMetaFlags = getSiteMetaFlags({
					siteData: website,
				});

				const { data } = await saveSite(websiteId, website, state.clientTimestamp, siteMetaFlags);

				addBreadcrumb({
					category: 'CLIENT_TIMESTAMP',
					message: 'After Save',
					data: {
						clientTimestamp: data.clientTimestamp,
					},
				});

				dispatch('updateClientTimestamp', data.clientTimestamp);
				dispatch('createSiteDataSnapshot', {
					siteData: website,
				});
			} catch (error) {
				dispatch('notifications/notify', {
					messageI18nKeyPath: getErrorMessagePath(error),
					submitLabelI18nKeyPath: 'common.reload',
					isDiscardButtonShown: false,
					submitCallback: () => window.location.reload(),
				}, {
					root: true,
				});
				console.error(error);
				captureException(error);

				throw error;
			} finally {
				dispatch('startSavingTimer');
				commit(SET_IS_SAVING, false);
			}
		},
		startSavingTimer: ({
			getters,
			dispatch,
			commit,
			rootState,
		}) => {
			if (rootState.user.user?.isStaff || rootState.user.user?.email === TEMPLATES_ACCOUNT_EMAIL) {
				return;
			}

			const timer = setInterval(() => {
				if (getters.canSave) {
					dispatch('saveWebsite');
				}
			}, AUTOSAVE_INTERVAL);

			commit(SET_TIMER, timer);
		},
	},
};
