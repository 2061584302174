<template>
	<ContextMenu
		:is-enabled="!!selectedLayoutElementId"
		:trigger-ref="triggerRef"
		is-element
		@close-context-menu="handleCloseMenu"
	>
		<div class="mobile-controls">
			<div
				v-if="isMobileScreen"
				class="mobile-controls__heading"
			>
				<p class="text-bold-2">
					{{ $t('builder.editText.moreOptions') }}
				</p>
				<HostingerButton
					v-qa="'mobile-context-menu-btn-close'"
					button-type="plain"
					:title="$t('common.close')"
					@click="handleCloseMenu"
				>
					<template #icon>
						<Icon name="close" />
					</template>
				</HostingerButton>
			</div>
			<ZyroSeparator v-if="isMobileScreen" />
			<ContextMenuButton
				icon="cut"
				:title="$t('builder.contextMenu.gridItem.cut')"
				:shortcut="{
					mac: '⌘ X',
					windows: 'Ctrl+X',
				}"
				@click="executeCommand(COMMAND_CUT)"
			/>
			<ContextMenuButton
				icon="file_copy"
				:title="$t('builder.contextMenu.gridItem.copy')"
				:shortcut="{
					mac: '⌘ C',
					windows: 'Ctrl+C',
				}"
				@click="executeCommand(COMMAND_COPY)"
			/>
			<ContextMenuButton
				icon="file_copy"
				:title="$t('common.duplicate')"
				:shortcut="{
					mac: '⌘ D',
					windows: 'Ctrl+D',
				}"
				@click="executeCommand(COMMAND_DUPLICATE)"
			/>
			<ContextMenuButton
				icon="delete_outline"
				:title="$t('common.delete')"
				:shortcut="{
					mac: '⌫',
					windows: 'Backspace',
				}"
				@click="executeCommand(COMMAND_DELETE)"
			/>
			<ZyroSeparator />
			<ContextMenuButton
				v-if="!isMobileScreen"
				icon="flip_to_front"
				:title="$t('builder.contextMenu.gridItem.sendFront')"
				:shortcut="{
					mac: '⌥ ⌘ ]',
					windows: 'Ctrl+Shift+]',
				}"
				:disabled="isElementZindexMax"
				@click="executeCommand(COMMAND_SEND_TO_FRONT, {
					shouldMoveLeft: false,
					shouldMoveFully: true,
				})"
			/>
			<ContextMenuButton
				icon="flip_to_front"
				:title="$t('builder.contextMenu.gridItem.moveForward')"
				:disabled="isElementZindexMax"
				:shortcut="{
					mac: '⌘ ]',
					windows: 'Ctrl+]',
				}"
				@click="executeCommand(COMMAND_MOVE_FORWARD, {
					shouldMoveLeft: false,
					shouldMoveFully: false,
				})"
			/>
			<ContextMenuButton
				v-if="!isMobileScreen"
				icon="flip_to_back"
				:title="$t('builder.contextMenu.gridItem.sendBack')"
				:shortcut="{
					mac: '⌥ ⌘ [',
					windows: 'Ctrl+Shift+[',
				}"
				:disabled="isElementZindexMin"
				@click="executeCommand(COMMAND_SEND_TO_BACK,{
					shouldMoveLeft: true,
					shouldMoveFully: true,
				})"
			/>
			<ContextMenuButton
				icon="flip_to_back"
				:title="$t('builder.contextMenu.gridItem.moveBackward')"
				:disabled="isElementZindexMin"
				:shortcut="{
					mac: '⌘ [',
					windows: 'Ctrl+[',
				}"
				@click="executeCommand(COMMAND_MOVE_BACKWARD,{
					shouldMoveLeft: true,
					shouldMoveFully: false,
				})"
			/>

			<ZyroSeparator v-if="isImageElement" />
			<ContextMenuButton
				v-if="isImageElement"
				icon="reset_image"
				:title="$t('builder.editImage.tabImage.resetProportions')"
				@click="resetProportions"
			/>
		</div>
	</ContextMenu>
</template>

<script setup>
import { useStore } from 'vuex';

import HostingerButton from '@/components/global/HostingerButton.vue';
import Icon from '@/components/global/Icon.vue';
import ContextMenu from '@/components/context-menu/ContextMenu.vue';
import ContextMenuButton from '@/components/context-menu/ContextMenuButton.vue';
import ZyroSeparator from '@/components/global/ZyroSeparator.vue';

import { useContextMenu } from '@/components/context-menu/useContextMenu';
import { useLayoutContextMenu } from '@/components/context-menu/useLayoutContextMenu';
import { useCropImage } from '@/components/layout-element/useCropImage';
import { useEditGridGallery } from '@/components/builder-controls/edit-gallery/useEditGridGallery';
import { useAiTextGeneration } from '@/use/useAiTextGeneration';
import {
	moveRight,
	moveLeft,
} from '@/utils/array';
import { getIsUserUsingMac } from '@/utils/getIsUserUsingMac';
import { useLayoutElements } from '@/use/useLayoutElements';
import { useGamification } from '@/use/useGamification';
import { useCurrentGridImage } from '@/use/useCurrentGridImage';
import { isAiImageGeneratorOpen } from '@/use/useAiImageGenerator';
import {
	getCode,
	CODE,
} from '@zyro-inc/site-modules/utils/getCode';
import {
	COMMAND_SEND_TO_FRONT,
	COMMAND_SEND_TO_BACK,
	COMMAND_MOVE_FORWARD,
	COMMAND_MOVE_BACKWARD,
	COMMAND_CUT,
	COMMAND_COPY,
	COMMAND_DUPLICATE,
	COMMAND_DELETE,
	GAMIFICATION_TASK_CHANGE_SOCIAL_ICONS,
} from '@/constants';
import {
	ELEMENT_TYPE_SOCIAL_ICONS,
	ELEMENT_TYPE_IMAGE,
} from '@zyro-inc/site-modules/constants';

import {
	computed,
	onMounted,
	onBeforeUnmount,
} from 'vue';
import { addBreadcrumb } from '@sentry/vue';

const CONTEXT_MENU_AVAILABLE_COMMANDS = [
	COMMAND_SEND_TO_FRONT,
	COMMAND_MOVE_FORWARD,
	COMMAND_SEND_TO_BACK,
	COMMAND_MOVE_BACKWARD,
	COMMAND_CUT,
	COMMAND_COPY,
	COMMAND_DUPLICATE,
	COMMAND_DELETE,
];

const props = defineProps({
	triggerRef: {
		type: Element,
		default: null,
	},
});
const emit = defineEmits([
	'close-context-menu',
	'remove-element',
]);

const {
	selectedLayoutElementId,
	copiedElementLocale,
	copiedElementId,
	isElementCut,
} = useLayoutContextMenu();

const { completeAchievement } = useGamification();

const { closeContextMenu } = useContextMenu();

const {
	state,
	getters,
	dispatch,
} = useStore();

const isMac = getIsUserUsingMac();

const isImageElement = computed(() => getters.currentElementType === ELEMENT_TYPE_IMAGE);
const isMobileScreen = computed(() => state.gui.isMobileScreen);
const currentLocale = computed(() => state.currentLocale);
const currentElementBlockId = computed(() => getters.currentElementBlockId);
const currentElementId = computed(() => state.currentElementId);
const currentElement = computed(() => getters.currentElement);
const isElementEditMode = computed(() => state.isElementEditMode);
const siteBlocks = computed(() => getters.siteBlocks);
const blockZIndexes = computed(() => siteBlocks.value[currentElementBlockId.value]?.zindexes || []);

const isTextareaFocused = computed(() => document.querySelector(`[data-element-ref='${currentElementId.value}']`)?.tagName === 'TEXTAREA');

const isColorPickerOpen = computed(() => getters['gui/isColorPickerOpen']);
const isElementZindexMax = computed(() => blockZIndexes.value[blockZIndexes.value.length - 1] === selectedLayoutElementId.value);
const isElementZindexMin = computed(() => blockZIndexes.value[0] === selectedLayoutElementId.value);

const {
	deleteSelectedElement,
	duplicateSelectedElement,
} = useLayoutElements({
	selectedElementId: currentElementId,
});

const {
	croppedImageId,
	exitCropMode,
} = useCropImage();

const { showGalleryManager } = useEditGridGallery();

const { isAiTextGeneratorPopupVisible } = useAiTextGeneration({
	elementId: currentElementId.value,
});

const handleElementDeletion = () => {
	if (!currentElementId.value) {
		return;
	}

	if (currentElement.value?.type === ELEMENT_TYPE_SOCIAL_ICONS) {
		completeAchievement(GAMIFICATION_TASK_CHANGE_SOCIAL_ICONS);
	}

	addBreadcrumb({
		category: 'SHORTCUT:Delete element',
		data: {
			elementType: currentElement.value?.type,
			elementId: currentElementId.value,
		},
	});

	deleteSelectedElement();
};

const cutElement = () => {
	copiedElementId.value = selectedLayoutElementId.value;
	copiedElementLocale.value = currentLocale.value;
	isElementCut.value = true;
};

const copyElement = () => {
	copiedElementId.value = selectedLayoutElementId.value;
	copiedElementLocale.value = currentLocale.value;
	isElementCut.value = false;
};

const setBlockZIndexes = (zindexes) => {
	dispatch('updateBlockData', {
		blockId: currentElementBlockId.value,
		blockData: {
			zindexes,
		},
		merge: true,
	});
};

const updateZIndex = (params) => {
	const {
		shouldMoveLeft = false,
		shouldMoveFully = false,
	} = params;
	const zindexes = shouldMoveLeft
		? moveLeft(blockZIndexes.value, selectedLayoutElementId.value, shouldMoveFully)
		: moveRight(blockZIndexes.value, selectedLayoutElementId.value, shouldMoveFully);

	setBlockZIndexes(zindexes);
};

const handleCloseMenu = () => {
	selectedLayoutElementId.value = null;

	if (props.triggerRef) {
		emit('close-context-menu');
	} else {
		closeContextMenu();
	}
};

const executeCommand = (command, commandProps) => {
	if (!command || !CONTEXT_MENU_AVAILABLE_COMMANDS.includes(command)) {
		return;
	}

	if ([
		COMMAND_SEND_TO_FRONT,
		COMMAND_MOVE_FORWARD,
		COMMAND_SEND_TO_BACK,
		COMMAND_MOVE_BACKWARD,
	].includes(command)) {
		updateZIndex(commandProps);
	}

	if (command === COMMAND_CUT) {
		cutElement();
	}

	if (command === COMMAND_COPY) {
		copyElement();
	}

	if (command === COMMAND_DUPLICATE) {
		duplicateSelectedElement();
	}

	if (command === COMMAND_DELETE) {
		handleElementDeletion();
	}

	handleCloseMenu();
	selectedLayoutElementId.value = null;
};

const onKeyDownExceptions = {
	copy: () => {
		const isAiImageGeneratorOpened = document.querySelector('#ai-image-generator-popup');

		return !!isAiImageGeneratorOpened;
	},
};

const onKeyDownPressed = (event) => {
	const isDialogOpen = document.getElementsByTagName('dialog').length > 0;

	if (!currentElementId.value || isDialogOpen) {
		return;
	}

	if (getCode(event) === CODE.Escape && (isElementEditMode.value || croppedImageId.value)) {
		event.preventDefault();
		dispatch('leaveElementEditMode');
		exitCropMode();
	}

	if (isElementEditMode.value || showGalleryManager.value || isAiTextGeneratorPopupVisible.value) {
		return;
	}

	if ((getCode(event) === CODE.Backspace || getCode(event) === CODE.Delete)
		&& !isColorPickerOpen.value
		&& !isTextareaFocused.value
		&& !isAiImageGeneratorOpen.value
	) {
		event.preventDefault();
		handleElementDeletion();
	}

	// ctrl + d or cmd + d
	if ((event.ctrlKey || event.metaKey) && getCode(event) === CODE.KeyD) {
		event.preventDefault();
		duplicateSelectedElement();
	}

	// cmd + backspace or ctrl + backspace
	if ((event.ctrlKey || event.metaKey)
		&& getCode(event) === CODE.Backspace
		&& !isTextareaFocused.value
		&& !isAiImageGeneratorOpen.value
	) {
		event.preventDefault();
		handleElementDeletion();
	}

	// cmd + c or ctrl + c
	if ((event.ctrlKey || event.metaKey) && getCode(event) === CODE.KeyC) {
		if (onKeyDownExceptions.copy()) {
			return;
		}

		event.preventDefault();
		copiedElementId.value = currentElementId.value;
		copiedElementLocale.value = currentLocale.value;
		isElementCut.value = false;
	}

	// cmd + x or ctrl + x
	if ((event.ctrlKey || event.metaKey) && getCode(event) === CODE.KeyX) {
		event.preventDefault();
		copiedElementId.value = currentElementId.value;
		copiedElementLocale.value = currentLocale.value;
		isElementCut.value = true;
	}

	// Push/send to back
	if ((event.ctrlKey || event.metaKey) && getCode(event) === CODE.BracketLeft) {
		event.preventDefault();

		const updatedZIndexes = moveLeft(
			blockZIndexes.value,
			currentElementId.value,
			isMac ? event.altKey : event.shiftKey,
		);

		setBlockZIndexes(updatedZIndexes);
	}

	// Push/send to front
	if ((event.ctrlKey || event.metaKey) && getCode(event) === CODE.BracketRight) {
		event.preventDefault();

		const updatedZIndexes = moveRight(
			blockZIndexes.value,
			currentElementId.value,
			isMac ? event.altKey : event.shiftKey,
		);

		setBlockZIndexes(updatedZIndexes);
	}
};

const { resetProportions } = useCurrentGridImage();

onMounted(() => {
	window.addEventListener('keydown', onKeyDownPressed);
});

onBeforeUnmount(() => {
	window.removeEventListener('keydown', onKeyDownPressed);
});
</script>
<style lang="scss" scoped>
.mobile-controls {
	padding-bottom: 4px;

	&__heading {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 12px 16px;
		margin-bottom: 1px;
	}
}
</style>
@/stores/useAiTextGeneration
