import { AI_GENERATE_MIN_LENGTH } from '@/constants';
import {
	computed,
	ref,
	watch,
} from 'vue';
import { useI18n } from 'vue-i18n';

export const useAiField = ({
	initialValue,
	errorMessage,
}: {
	initialValue: string;
	errorMessage?: string;
}) => {
	const { t } = useI18n();

	const fieldValue = ref(initialValue);
	const fieldError = ref<string | null>(null);
	const isFieldTouched = ref(false);
	const fieldErrorMessage = ref(errorMessage || t('builder.descriptionMustBeAtLeast', {
		count: AI_GENERATE_MIN_LENGTH,
	}));

	const validateFn = (value: string) => value.trim().length >= AI_GENERATE_MIN_LENGTH;

	const validateField = () => {
		if (validateFn(fieldValue.value)) {
			fieldError.value = null;

			return true;
		}

		fieldError.value = fieldErrorMessage.value;

		return false;
	};

	const setFieldValue = (value: string) => {
		fieldValue.value = value;

		if (!isFieldTouched.value) {
			return;
		}

		validateField();
	};

	const onFieldBlur = () => {
		isFieldTouched.value = true;
	};

	watch(() => isFieldTouched.value, (value) => {
		if (!value) {
			return;
		}

		validateField();
	});

	const fieldProgress = computed(() => {
		const colors = [
			'var(--color-danger)',
			'var(--color-warning)',
			'var(--color-success)',
		];

		const fieldValueLength = fieldValue.value.length;

		if (!fieldValueLength) {
			return {
				progress: 0,
				label: t('builder.AIWebsiteBuilder.descriptionQualityLabelBad'),
				colors,
			};
		}

		if (fieldValueLength < AI_GENERATE_MIN_LENGTH) {
			return {
				progress: 1,
				label: t('builder.AIWebsiteBuilder.qualityLabelBad'),
				colors,
			};
		}

		if (fieldValueLength < AI_GENERATE_MIN_LENGTH * 2) {
			return {
				progress: 2,
				label: t('builder.AIWebsiteBuilder.qualityLabelMedium'),
				colors,
			};
		}

		return {
			progress: 3,
			label: t('builder.AIWebsiteBuilder.descriptionQualityLabelGood'),
			colors,
		};
	});

	return {
		fieldValue,
		fieldError,
		fieldProgress,
		validateField,
		setFieldValue,
		onFieldBlur,
	};
};
