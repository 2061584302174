<template>
	<div
		class="gamification-tips"
		:class="{ 'gamification-tips--with-margin': !!title && addTopMargin }"
	>
		<p
			v-if="!!title"
			class="gamification-tips__title"
		>
			{{ title }}
		</p>
		<div class="gamification-tips__item-list">
			<div
				v-for="(item, index) in sortedAchievements"
				:key="item.id"
				v-qa="`gamification-tasks-task-${item.id}`"
			>
				<GamificationTaskItem
					:tabindex="0"
					:is-last-item="sortedAchievements.length === index + 1"
					:item="item"
					:show-icon="showIcon"
				>
					{{ item.name }}
				</GamificationTaskItem>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import GamificationTaskItem from '@/components/onboarding/GamificationTaskItem.vue';
import { computed } from 'vue';
import { Achievement } from '@/types/gamification';

interface Props {
	achievements: Achievement[];
	title?: string;
	showIcon?: boolean;
	addTopMargin?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	showIcon: false,
	addTopMargin: true,
});

const sortedAchievements = computed(() => [...props.achievements].sort((a, b) => {
	if (!a.achievedAt) {
		return 1;
	}

	if (!b.achievedAt) {
		return -1;
	}

	return new Date(a.achievedAt).valueOf() - new Date(b.achievedAt).valueOf();
}));
</script>
<style lang="scss" scoped>
.gamification-tips {
	&--with-margin {
		margin-top: 16px;
	}

	&__title {
		margin-bottom: 16px;
		font-size: 16px;
		line-height: 24px;
		font-weight: 700;
	}

	&__item-list {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}
}
</style>
