<script setup lang="ts">
import {
	computed,
	ref,
} from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import BlockEditControls from '@/components/builder-controls/BlockEditControls.vue';
import BlockControls from '@/components/block/BlockControls.vue';
import VisibilityTooltip from '@/components/builder-view/VisibilityTooltip.vue';
import { useLayout } from '@/components/block-layout/useLayout';
import {
	SiteElements,
	SiteBlock,
} from '@hostinger/builder-schema-validator';
import {
	BLOCK_STICKY_BAR_MAX_HEIGHT,
	DATA_ATTRIBUTE_ANIMATION_STATE,
	BLOCK_STICKY_BAR_ID,
} from '@zyro-inc/site-modules/constants';
import EditBlockStickyBarTabs from '@/components/builder-controls/edit-block-sticky-bar/EditBlockStickyBarTabs.vue';
import BlockBackground from '@zyro-inc/site-modules/components/blocks/BlockBackground.vue';
import BlockStickyBarLayout from '@zyro-inc/site-modules/components/blocks/sticky-bar/BlockStickyBarLayout.vue';
import StickyBarText from '@/components/block-sticky-bar/StickyBarText.vue';
import {
	getFullWidthSrcset,
	getOptimizedSrc,
} from '@zyro-inc/site-modules/utils/getSrcsets';
import { FULL_WIDTH } from '@zyro-inc/site-modules/utils/getGridItemSize';
import { useSiteEngineAnimations } from '@zyro-inc/site-modules/use/useSiteEngineAnimations';

interface Props {
  data: SiteBlock;
  components: SiteElements;
  transparentHeaderHeight?: number;
	isInPreviewMode?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
	transparentHeaderHeight: 0,
	isInPreviewMode: false,
});
const emit = defineEmits([]);

const {
	state,
	dispatch,
} = useStore();
const { t } = useI18n();
const {
	customAnimationClass,
	animationAttributeStateValue,
} = useSiteEngineAnimations({
	elementData: null,
	data: props.data,
	elementId: null,
	blockId: BLOCK_STICKY_BAR_ID,
});

const { layoutCSSVars } = useLayout(props, emit);

const blockContainerRef = ref<HTMLElement>();
const isTextEditActive = ref(false);

const isMobileView = computed(() => state.gui.isMobileView);
const isHiddenDesktop = computed(() => !isMobileView.value && !!props.data.desktop?.isHidden);
const isHiddenMobile = computed(() => isMobileView.value && !!props.data.mobile?.isHidden);
const backgroundOrigin = computed<string>(() => props.data.background?.origin || '');
const backgroundPath = computed<string>(() => props.data.background?.path || '');

const backgroundSrc = computed(() => (getOptimizedSrc(
	backgroundOrigin.value,
	backgroundPath.value,
	state.websiteId,
	{
		width: FULL_WIDTH,
	} as any,
)));
const backgroundSrcSet = computed(() => (getFullWidthSrcset(
	backgroundOrigin.value,
	backgroundPath.value,
	state.websiteId,
	{
		isMobileFullScreen: false,
	} as any,
)));

const closeBlockEditPopupHandler = () => {
	dispatch('leaveBlockEditMode');
};

const setBlockEditControlsVisibility = (isTextActive: boolean) => {
	isTextEditActive.value = isTextActive;
};
</script>

<template>
	<section
		ref="blockContainerRef"
		class="block-sticky-bar"
	>
		<BlockBackground
			v-if="data.background"
			class="block-sticky-bar__background"
			:overlay-opacity="data.background['overlay-opacity']"
			:type="data.background.current"
			:gradient="data.background.gradient"
			:color="data.background.color"
			:src="backgroundSrc"
			:srcset="backgroundSrcSet"
			:alt="data.background && data.background.alt"
			is-opacity-disabled
		/>
		<BlockStickyBarLayout
			:block-styles="data.settings.styles"
			:style="layoutCSSVars"
			:class="customAnimationClass"
			:[DATA_ATTRIBUTE_ANIMATION_STATE]="animationAttributeStateValue"
		>
			<StickyBarText
				v-for="(item, index) in data.items"
				:key="`sticky-element-${index}`"
				:text-data="item"
				:block-id="BLOCK_STICKY_BAR_ID"
				@set-active="setBlockEditControlsVisibility($event)"
			/>
		</BlockStickyBarLayout>
		<VisibilityTooltip
			:is-hidden-desktop="isHiddenDesktop"
			:is-hidden-mobile="isHiddenMobile"
		/>
		<BlockControls
			:block-id="BLOCK_STICKY_BAR_ID"
			:target-ref="blockContainerRef"
			is-first-block
			:is-pill-disabled="isMobileView"
			:transparent-header-height="transparentHeaderHeight"
			:is-add-block-button-visible="false"
		/>
		<BlockEditControls
			:block-id="BLOCK_STICKY_BAR_ID"
			:target-ref="blockContainerRef"
			:is-block-non-grid-element-active="isTextEditActive"
			is-delete-button-visible
			is-visibility-button-visible
			:is-context-menu-button-visible="false"
			:enter-edit-mode-button-title="t('builder.editBlockButton.editSection')"
			:controls-bar-popup-options="{
				placement: 'bottom-end',
				placementMobile: 'bottom-end',
				offsetMobile: {
					mainAxis: 8,
					crossAxis: 0,
				},
				autoUpdate: true,
				flip: false,
			}"
		>
			<template #edit-mode-popup>
				<EditBlockStickyBarTabs @close="closeBlockEditPopupHandler" />
			</template>
		</BlockEditControls>
	</section>
</template>

<style lang="scss" scoped>
.block-sticky-bar {
	$this: &;

	z-index: $z-index-site-engine-block-grid;
  position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
  width: 100%;
	height: 100%;
	max-height: calc(v-bind(BLOCK_STICKY_BAR_MAX_HEIGHT) * 1px);

	&__background {
		z-index: -1;
		height: 100%;
	}
}
</style>
