import cloneDeep from 'lodash.clonedeep';
import { createNamespacedHelpers } from 'vuex';
import { setTag } from '@sentry/vue';

import {
	MEDIA_MOBILE_BREAKPOINT,
	SYSTEM_LOCALE,
	PAGE_ID_PRIVATE,
} from '@zyro-inc/site-modules/constants';

import EventLogApi from '@/api/EventLogApi';
import {
	DRAWER_PAGES,
	DRAWER_PAGES_DEFAULT_STATE,
	USER_STYLES_MAIN_PAGE_KEY,
	DRAWER_USER_STYLES,
	DRAWER_BLOG,
	DRAWER_ADD_ELEMENT,
	DRAWER_MULTI_PAGE,
	DRAWER_MULTILINGUAL,
} from '@/constants';
import { getSetupPaymentNotificationClosedAtCookieName } from '@/utils/ecommerce';

import {
	getDynamicProductPageData,
	getPageData,
	getShouldLoadDynamicProductPageByPageId,
} from '@zyro-inc/site-modules/utils/getPageData';
import { getPageIdFromPath } from '@zyro-inc/site-modules/utils/page/getPageIdFromPath';
import { getPathParams } from '@zyro-inc/site-modules/utils/page/getPathParams';
import { getDomain } from '@zyro-inc/site-modules/utils/domainUtils';
import { setCookie } from '@zyro-inc/site-modules/utils/cookies';
// Auth service introduces cycle-import error
// eslint-disable-next-line import/no-cycle
import { useEcommerceStore } from '@/stores/ecommerceStore';

// namespacing to use with `createNamespacedHelpers`
export const GUI_NAMESPACE = 'gui';

// action type constants:
export const UPDATE_IS_MOBILE_SCREEN = 'UPDATE_IS_MOBILE_SCREEN';
export const UPDATE_HEADER_HEIGHT = 'UPDATE_HEADER_HEIGHT';
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const OPEN_SIDEBAR = 'OPEN_SIDEBAR';
export const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const OPEN_DRAWER = 'OPEN_DRAWER';
export const CLOSE_DRAWER = 'CLOSE_DRAWER';
export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const OPEN_HEATMAP = 'OPEN_HEATMAP';
export const CLOSE_HEATMAP = 'CLOSE_HEATMAP';
export const TOGGLE_HEATMAP = 'TOGGLE_HEATMAP';
export const CHANGE_DRAWER_PAGE = 'CHANGE_DRAWER_PAGE';
export const CHANGE_DRAWER_OPTIONS = 'CHANGE_DRAWER_OPTIONS';
export const CHANGE_PREVIOUS_DRAWER_PAGE = 'CHANGE_PREVIOUS_DRAWER_PAGE';

// mutation type constants:
export const SET_IS_MOBILE_SCREEN = 'SET_IS_MOBILE_SCREEN';
export const SET_ACTIVE_MODAL = 'SET_ACTIVE_MODAL';
export const SET_SIDEBAR_OPEN = 'SET_SIDEBAR_OPEN';
export const SET_ACTIVE_DRAWER = 'SET_ACTIVE_DRAWER';
export const SET_HEATMAP_OPEN = 'SET_HEATMAP_OPEN';
export const SET_HEADER_HEIGHT = 'SET_HEADER_HEIGHT';
export const SET_DRAWER_PAGE = 'SET_DRAWER_PAGE';
export const SET_BLOCK_RESIZE_INFO = 'SET_BLOCK_RESIZE_INFO';
export const SET_COLOR_PICKER_OPEN = 'SET_COLOR_PICKER_OPEN';

/**
 * TODO: each namespacedHelper export should go to corresponding module state/action/mutation files
 *
 * Considering it's our own pattern, we should document this after refactoring. Smth. like:
 * 'Exported namespaced state/getters/actions/mutations map. Import it together with constants.'
 */
export const { mapState: mapStateGui } = createNamespacedHelpers(GUI_NAMESPACE);
export const { mapActions: mapActionsGui } = createNamespacedHelpers(GUI_NAMESPACE);
export const { mapMutations: mapMutationsGui } = createNamespacedHelpers(GUI_NAMESPACE);

const getPreviewPageData = ({
	previewSiteData,
	defaultLocale,
	locale,
	previewPageId,
	path,
}) => {
	const shouldLoadDynamicProductPage = path ? getShouldLoadDynamicProductPageByPageId({
		pageId: previewPageId,
		website: previewSiteData,
		path,
		defaultLocale,
	}) : false;

	return shouldLoadDynamicProductPage
		? getDynamicProductPageData({
			website: previewSiteData,
			defaultLocale,
		})
		: getPageData({
			siteData: previewSiteData,
			locale,
			pageId: previewPageId,
			isAstro: false,
			isPreviewMode: true,
		});
};

export default {
	namespaced: true,
	state: {
		isMobileView: false,
		isMobileScreen: window.innerWidth < MEDIA_MOBILE_BREAKPOINT,
		isSidebarOpen: true,
		activeModalName: null,
		activeModalSettings: {},
		activeDrawer: null,
		activeDrawerSettings: {},
		builderPreviewContainerRef: null,
		isSiteBeingPublished: false,
		isSiteBeingUpdated: false,
		headerHeight: null,
		isHeatmapOpen: false,
		drawerPage: DRAWER_PAGES_DEFAULT_STATE,
		blockResizeInfo: {},
		isColorPickerOpen: false,
		isAssetManagerVisible: false,
		previewSiteData: null,
		previewPageData: null,
		isP2PBannerShown: false,
		isSetupPaymentProviderNotificationVisible: false,
		currentPreviewProductPageSlug: '',
	},
	getters: {
		isMobileMode: (state) => state.isMobileScreen || state.isMobileView,
		isColorPickerOpen: (state) => state.isColorPickerOpen,
		isSetupPaymentProviderNotificationVisible: (state) => state.isSetupPaymentProviderNotificationVisible,
	},
	mutations: {
		toggleMobileView: (state) => {
			state.isMobileView = !state.isMobileView;
		},
		toggleDesktopView: (state) => {
			state.isMobileView = false;
		},
		toggleSiteBeingPublished: (state) => {
			state.isSiteBeingPublished = !state.isSiteBeingPublished;
		},
		setIsSiteBeingUpdated: (state, value) => {
			state.isSiteBeingUpdated = value;
		},
		setBuilderPreviewContainerRef: (state, { builderPreviewContainerRef }) => {
			state.builderPreviewContainerRef = builderPreviewContainerRef;
		},
		setIsAssetManagerVisible: (state, value) => {
			state.isAssetManagerVisible = value;
		},
		[SET_HEADER_HEIGHT]: (state, height) => {
			state.headerHeight = height;
		},
		[SET_ACTIVE_MODAL]: (state, {
			name,
			settings,
		}) => {
			setTag('activeModal', name ?? 'none');
			state.activeModalName = name;
			state.activeModalSettings = settings;
		},
		[SET_SIDEBAR_OPEN]: (state, isOpenState) => {
			state.isSidebarOpen = isOpenState;
		},
		[SET_HEATMAP_OPEN]: (state, isOpenState) => {
			state.isHeatmapOpen = isOpenState;
		},
		[SET_ACTIVE_DRAWER]: (state, {
			id,
			settings,
		}) => {
			setTag('activeDrawer', id ?? 'none');
			state.activeDrawer = id;
			state.activeDrawerSettings = settings;
		},
		[SET_IS_MOBILE_SCREEN]: (state, isMobileScreen) => {
			state.isMobileScreen = isMobileScreen;
		},
		[SET_DRAWER_PAGE]: (state, {
			drawerPage,
			drawerKey,
		}) => {
			state.drawerPage[drawerKey] = drawerPage;
		},
		[SET_BLOCK_RESIZE_INFO]: (state, blockResizeInfo) => {
			state.blockResizeInfo = blockResizeInfo;
		},
		[SET_COLOR_PICKER_OPEN]: (state, value) => {
			state.isColorPickerOpen = value;
		},
		setPreviewSiteData: (state, value) => {
			state.previewSiteData = value;
		},
		setPreviewPageData: (state, value) => {
			state.previewPageData = value;
		},
		setIsP2PBannerShown: (state, value) => {
			state.isP2PBannerShown = value;
		},
		setActiveDrawerSettings: (state, value) => {
			state.activeDrawerSettings = value;
		},
		setIsSetupPaymentProviderNotificationVisible: (state, value) => {
			state.isSetupPaymentProviderNotificationVisible = value;
		},
		setCurrentPreviewProductPageSlug: (state, value) => {
			state.currentPreviewProductPageSlug = value;
		},
	},
	actions: {
		setAssetManagerVisibility({ commit }, value) {
			commit('setIsAssetManagerVisible', value);
		},
		setBuilderPreviewContainerRef({ commit }, { builderPreviewContainerRef }) {
			commit('setBuilderPreviewContainerRef', {
				builderPreviewContainerRef,
			});
		},
		setIsSiteBeingUpdated({ commit }, value) {
			commit('setIsSiteBeingUpdated', value);
		},
		toggleMobileView: async ({
			dispatch,
			commit,
		}) => {
			// If leaveElementEditMode is not executed before toggling mobile view, text editor breaks (root cause unclear yet)
			await dispatch('leaveElementEditMode', {
				saveToHistory: false,
			}, {
				root: true,
			});
			commit('toggleMobileView');
		},

		[UPDATE_HEADER_HEIGHT]: ({
			state,
			commit,
		}, height) => {
			if (!state.headerHeight && height) {
				commit(SET_HEADER_HEIGHT, height);
			}
		},

		// TODO: Test these three actions for @matasmazeikaa. Dunno how to test these properly
		[CHANGE_DRAWER_PAGE]: ({ commit }, {
			drawerKey,
			pageKey,
			options = {},
		}) => {
			const drawerPageData = DRAWER_PAGES[drawerKey][pageKey];
			const drawerPage = {
				...drawerPageData,
				options: {
					...drawerPageData.options,
					...options,
				},
			};

			commit(SET_DRAWER_PAGE, {
				drawerKey,
				drawerPage,
			});
		},

		[CHANGE_DRAWER_OPTIONS]: ({
			commit,
			state,
		}, {
			drawerKey,
			options = {},
		}) => {
			const drawerPageData = state.drawerPage[drawerKey];
			const drawerPage = {
				...drawerPageData,
				options: {
					...drawerPageData.options,
					...options,
				},
			};

			commit(SET_DRAWER_PAGE, {
				drawerKey,
				drawerPage,
			});
		},

		[CHANGE_PREVIOUS_DRAWER_PAGE]: ({
			commit,
			state,
		}, drawerKey) => {
			const pageKey = state.drawerPage[drawerKey].previousPage
				?? USER_STYLES_MAIN_PAGE_KEY;
			const drawerPage = cloneDeep(DRAWER_PAGES[drawerKey][pageKey]);

			commit(SET_DRAWER_PAGE, {
				drawerPage,
				drawerKey,
			});
		},

		[OPEN_SIDEBAR]: ({ commit }) => commit(SET_SIDEBAR_OPEN, true),

		[OPEN_MODAL]: ({ commit }, {
			name,
			settings = {},
		}) => {
			commit(SET_ACTIVE_MODAL, {
				name,
				settings,
			});
		},

		[CLOSE_MODAL]: ({ commit }) => {
			commit(SET_ACTIVE_MODAL, {
				name: null,
				settings: {},
			});
		},

		[CLOSE_SIDEBAR]: ({
			commit,
			dispatch,
		}) => {
			commit(SET_SIDEBAR_OPEN, false);
			dispatch(CLOSE_HEATMAP);
		},

		[TOGGLE_SIDEBAR]: ({
			state,
			dispatch,
		}) => {
			dispatch(state.isSidebarOpen ? CLOSE_SIDEBAR : OPEN_SIDEBAR);
		},

		[OPEN_HEATMAP]: ({
			state,
			commit,
			dispatch,
		}) => {
			dispatch(CLOSE_DRAWER);

			if (!state.isHeatmapOpen) {
				commit(SET_HEATMAP_OPEN, true);
			}
		},

		[CLOSE_HEATMAP]: ({
			state,
			commit,
		}) => {
			if (state.isHeatmapOpen) {
				commit(SET_HEATMAP_OPEN, false);
			}
		},

		[TOGGLE_HEATMAP]: ({
			state,
			dispatch,
		}) => {
			dispatch(state.isHeatmapOpen ? CLOSE_HEATMAP : OPEN_HEATMAP);
		},

		[OPEN_DRAWER]: ({
			rootState,
			commit,
			dispatch,
		}, {
			id,
			settings,
		}) => {
			if (id === DRAWER_BLOG) {
				if (rootState.user?.id) {
					window.hj('identify', rootState.user.id, {
						'builder.blog.open_sidebar': true,
					});
				}
			}

			dispatch(CLOSE_HEATMAP);
			commit(SET_ACTIVE_DRAWER, {
				id,
				settings,
			});
		},

		[CLOSE_DRAWER]: ({ commit }) => commit(SET_ACTIVE_DRAWER, {
			id: null,
			settings: null,
		}),

		[TOGGLE_DRAWER]: ({
			state,
			dispatch,
		}, name) => {
			const drawerEventNameMap = {
				[DRAWER_ADD_ELEMENT]: 'website_builder.add_element.enter',
				[DRAWER_MULTI_PAGE]: 'website_builder.pages_and_navigation.enter',
				[DRAWER_USER_STYLES]: 'website_builder.global_styles.enter',
				[DRAWER_BLOG]: 'website_builder.blog.enter',
				[DRAWER_MULTILINGUAL]: 'website_builder.languages.enter',
			};

			if (drawerEventNameMap[name] && state.activeDrawer !== name) {
				EventLogApi.logEvent({
					eventName: drawerEventNameMap[name],
				});
			}

			dispatch(state.activeDrawer === name ? CLOSE_DRAWER : OPEN_DRAWER, {
				id: name,
			});
		},

		[UPDATE_IS_MOBILE_SCREEN]: ({ commit }, isMobile) => commit(SET_IS_MOBILE_SCREEN, isMobile),

		updateBlockResizeInfo: ({ commit }, blockResizeInfo) => {
			commit(SET_BLOCK_RESIZE_INFO, blockResizeInfo);
		},

		updateIsColorPickerOpen: ({ commit }, isColorPickerOpen) => commit(SET_COLOR_PICKER_OPEN, isColorPickerOpen),
		setPreviewSiteData: ({ commit }, previewSiteData) => commit('setPreviewSiteData', previewSiteData),
		setPreviewPageData: ({ commit }, previewPageData) => commit('setPreviewPageData', previewPageData),
		updatePreviewSiteData: ({
			rootState,
			dispatch,
		}, previewSiteData) => {
			if (previewSiteData) {
				const {
					currentLocale,
					currentPageId,
				} = rootState;

				const defaultLocale = previewSiteData.meta.defaultLocale || SYSTEM_LOCALE;
				const locale = currentPageId === PAGE_ID_PRIVATE || !Object.keys(previewSiteData.languages).includes(currentLocale)
					? SYSTEM_LOCALE
					: currentLocale;

				const pageData = getPreviewPageData({
					previewSiteData,
					locale,
					defaultLocale,
					previewPageId: currentPageId,
				});

				dispatch('setPreviewSiteData', previewSiteData);
				dispatch('setPreviewPageData', pageData);
			}
		},
		updatePreviewPageData: ({
			state,
			rootState,
			dispatch,
			commit,
		}, { path }) => {
			if (state.previewSiteData) {
				const { currentPageId } = rootState;
				const ecommerceStore = useEcommerceStore();
				const isCurrentPagePrivate = currentPageId === PAGE_ID_PRIVATE;
				const defaultLocale = state.previewSiteData?.meta.defaultLocale ?? SYSTEM_LOCALE;
				const previewPageId = isCurrentPagePrivate
					? PAGE_ID_PRIVATE
					: getPageIdFromPath({
						path,
						siteData: state.previewSiteData,
						products: ecommerceStore.productsSeo,
					});

				const { locale } = isCurrentPagePrivate
					? {
						locale: SYSTEM_LOCALE,
					}
					: getPathParams({
						path,
						defaultLocale,
						languageKeys: Object.keys(state.previewSiteData.languages),
					});

				const pageData = getPreviewPageData({
					previewSiteData: state.previewSiteData,
					locale,
					defaultLocale,
					previewPageId,
					path,
				});

				commit('setCurrentPreviewProductPageSlug', path.split('/')[1]);

				dispatch('setPreviewPageData', pageData);
			}
		},
		setCurrentPreviewProductPageSlug: ({ commit }, value) => commit('setCurrentPreviewProductPageSlug', value),
		setIsP2PBannerShown: ({ commit }, value) => commit('setIsP2PBannerShown', value),
		setActiveDrawerSettings: ({ commit }, value) => commit('setActiveDrawerSettings', value),
		setIsSetupPaymentProviderNotificationVisible: ({
			commit,
			rootGetters,
		}, value) => {
			commit('setIsSetupPaymentProviderNotificationVisible', value);

			if (value) {
				EventLogApi.logEvent({
					eventName: 'website_builder.ecomm_payment_modal.shown',
					eventProperties: {
						builder: true,
					},
				});
			} else {
				setCookie(getSetupPaymentNotificationClosedAtCookieName(rootGetters.siteMeta.ecommerceStoreId), Date.now().toString(), 1, {
					cdomain: getDomain(),
				});
			}
		},
	},
};
