// Generated by ts-to-zod
import { z } from "zod";

export const siteTypographyStylesSchema = z.object({
  "font-family": z.string(),
  "font-size": z.string(),
  "m-font-size": z.string(),
  "font-style": z.string(),
  "font-weight": z.union([
    z.literal(100),
    z.literal(200),
    z.literal(300),
    z.literal(400),
    z.literal(500),
    z.literal(600),
    z.literal(700),
    z.literal(800),
    z.literal(900),
    z.literal("100"),
    z.literal("200"),
    z.literal("300"),
    z.literal("400"),
    z.literal("500"),
    z.literal("600"),
    z.literal("700"),
    z.literal("800"),
    z.literal("900"),
    z.literal("normal"),
    z.literal("bold"),
  ]),
  "line-height": z.string(),
  "text-decoration": z.string(),
  "text-transform": z.string(),
  "letter-spacing": z.union([z.string(), z.number()]),
  color: z.string().optional(),
  "color-hover": z.string().optional(),
});

export const siteGridButtonStyleSchema = z.object({
  "padding-y": z.string(),
  "m-padding-y": z.string(),
  "padding-x": z.string(),
  "m-padding-x": z.string(),
  "font-family": z.string(),
  "font-size": z.string(),
  "m-font-size": z.string(),
  "font-style": z.string(),
  "font-weight": z.union([
    z.literal(100),
    z.literal(200),
    z.literal(300),
    z.literal(400),
    z.literal(500),
    z.literal(600),
    z.literal(700),
    z.literal(800),
    z.literal(900),
  ]),
  "line-height": z.string(),
  "text-decoration": z.string(),
  "text-transform": z.string(),
  "letter-spacing": z.string(),
  "background-color-null": z.string(),
  "background-color-null-hover": z.string(),
  "border-null": z.string(),
  "border-null-hover": z.string(),
  "border-radius": z.number(),
  "box-shadow-null": z.string(),
  "box-shadow-null-hover": z.string(),
  "box-shadow-x": z.string(),
  "box-shadow-x-hover": z.string(),
  "box-shadow-blur": z.string(),
  "box-shadow-blur-hover": z.string(),
  "box-shadow-spread": z.string(),
  "box-shadow-spread-hover": z.string(),
  "box-shadow-y": z.string(),
  "box-shadow-y-hover": z.string(),
  "box-shadow-color": z.string(),
  "box-shadow-color-hover": z.string(),
  "transition-duration": z.string(),
  "transition-timing-function": z.string(),
});

export const siteBlockTypeSchema = z.union([
  z.literal("BlockSlideshow"),
  z.literal("BlockGrid"),
  z.literal("BlockNavigation"),
  z.literal("BlockBlogList"),
  z.literal("BlockBlogHeader"),
  z.literal("BlockEcommerceProduct"),
  z.literal("BlockEcommerceModal"),
  z.literal("BlockEcommerceProductList"),
  z.literal("BlockLayout"),
  z.literal("BlockImageSlideshow"),
]);

export const siteElementTypeSchema = z.union([
  z.literal("GridGallery"),
  z.literal("GridTextBox"),
  z.literal("GridShape"),
  z.literal("GridImage"),
  z.literal("GridButton"),
  z.literal("GridMap"),
  z.literal("GridVideo"),
  z.literal("GridInstagramFeed"),
  z.literal("GridSocialIcons"),
  z.literal("GridForm"),
  z.literal("GridEmbed"),
  z.literal("GridStripeButton"),
  z.literal("GridEcommerceButton"),
  z.literal("SearchBar"),
]);

export const siteBackgroundTypeSchema = z.union([
  z.literal("path"),
  z.literal("origin"),
  z.literal("color"),
  z.literal("gradient"),
  z.literal("image"),
  z.literal("video"),
]);

export const siteBackgroundGradientSchema = z.object({
  angle: z.number().min(0).max(360),
  isAnimated: z.boolean(),
  colors: z.array(
    z.object({
      value: z.string(),
    }),
  ),
});

export const siteEcommerceShoppingCartSchema = z.record(z.unknown()).and(
  z.object({
    translations: z.record(z.string()),
    lang: z.string().optional(),
  }),
);

export const siteElementSettingsSchema = z.object({
  top: z.number().min(0),
  left: z.number().min(0),
  width: z.number().min(0),
  height: z.number().min(0),
  submitButtonFontSize: z.number().optional(),
  inputTextSize: z.number().optional(),
  labelTextSize: z.number().optional(),
  borderRadius: z.union([z.number(), z.string()]).optional(),
});

export const siteElementLinkSchema = z.object({
  svg: z.string(),
  url: z.string(),
  icon: z.string(),
});

export const siteElementLinksSchema = z.array(siteElementLinkSchema);

export const siteElementSchema = z.object({
  type: siteElementTypeSchema,
  locale: z.string().optional(),
  linkType: z.string().optional(),
  linkedPageId: z.string().optional(),
  fontFamily: z.string().optional(),
  fontWeight: z.number().optional(),
  borderRadius: z.union([z.number(), z.string()]).optional(),
  overlayOpacity: z.number().optional(),
  borderColor: z.string().optional(),
  borderColorHover: z.string().optional(),
  borderWidth: z.number().optional(),
  borderWidthHover: z.number().optional(),
  backgroundColor: z.string().optional(),
  desktop: siteElementSettingsSchema.optional(),
  mobile: siteElementSettingsSchema.optional(),
  href: z.string().optional(),
  target: z.string().optional(),
  rel: z.string().optional(),
  naturalWidth: z.number().optional(),
  naturalHeight: z.number().optional(),
  fullResolutionWidth: z.number().optional(),
  fullResolutionHeight: z.number().optional(),
  content: z.string().optional(),
  links: siteElementLinksSchema.optional(),
  svg: z.string().optional(),
  settings: z.record(z.any()).optional(),
  lockProportions: z.boolean().optional(),
  initialElementId: z.string().optional(),
  aiData: z
    .object({
      type: z.string().optional(),
    })
    .optional(),
  id: z.string().optional(),
  animation: z
    .object({
      type: z.string(),
      name: z.string(),
    })
    .optional(),
});

export const ecommerceSortingValueSchema = z.union([
  z.literal(""),
  z.literal("order=ASC&sort_by=price"),
  z.literal("order=DESC&sort_by=price"),
  z.literal("order=DESC&sort_by=created_at"),
]);

export const siteBlockSettingsSchema = z.object({
  styles: z.record(z.unknown()).optional(),
  categories: z.array(z.string()),
});

export const siteEcommerceSortingValueSchema = z.union([
  z.literal(""),
  z.literal("order=ASC&sort_by=price"),
  z.literal("order=DESC&sort_by=price"),
  z.literal("order=DESC&sort_by=created_at"),
  z.literal("order=ASC&sort_by=collection_order"),
]);

export const siteBlockSchema = z.object({
  type: siteBlockTypeSchema,
  settings: siteBlockSettingsSchema.and(z.record(z.unknown())),
  slot: z.string().optional(),
  background: z
    .record(z.unknown())
    .and(
      z.object({
        path: z.string().optional().nullable(),
        origin: z.string().optional().nullable(),
        color: z.string().optional(),
        gradient: siteBackgroundGradientSchema.optional(),
        current: siteBackgroundTypeSchema.optional(),
        "overlay-opacity": z.string().optional(),
        alt: z.string().optional(),
        video: z
          .object({
            videoSrc: z.string(),
            videoThumbnailSrc: z.string(),
          })
          .optional(),
      }),
    )
    .optional(),
  components: z.array(z.string()).optional(),
  zindexes: z.array(z.string()).optional(),
  desktop: z
    .record(z.unknown())
    .and(
      z.object({
        minHeight: z.number().optional(),
      }),
    )
    .optional(),
  mobile: z
    .record(z.unknown())
    .and(
      z.object({
        minHeight: z.number().optional(),
      }),
    )
    .optional(),
  initialBlockId: z.string().optional(),
  locale: z.string().optional(),
  shouldSnapToGuides: z.boolean().optional(),
  shouldSnapToElements: z.boolean().optional(),
  snapRowGap: z.number().optional(),
  snapRowHeight: z.number().optional(),
  isMobileAutoPositioningEnabled: z.boolean().optional(),
  isQuantityPickerEnabled: z.boolean().optional(),
  attachment: z.union([z.literal("unset"), z.literal("fixed")]).optional(),
  htmlId: z.string().optional(),
  isButtonEnabled: z.boolean().optional(),
  buttonDisplay: z.string().optional(),
  aiData: z
    .record(z.unknown())
    .and(
      z.object({
        type: z.string().optional(),
      }),
    )
    .optional(),
  items: z
    .array(
      z.object({
        type: z.string(),
        content: z.string(),
        styles: z.record(z.unknown()),
      }),
    )
    .optional(),
  productSorting: z
    .object({
      enabled: z.boolean(),
      textColor: z.string(),
      sortingOptions: z.array(
        z.object({
          id: z.string(),
          value: siteEcommerceSortingValueSchema,
          label: z.string(),
          isEnabled: z.boolean(),
        }),
      ),
    })
    .optional(),
  productIds: z.array(z.string()).optional(),
  productCategoryId: z.string().optional(),
  isCategoryListEnabled: z.boolean().optional(),
  isFullWidth: z.boolean().optional(),
  isTotalProductCountShown: z.boolean().optional(),
  columnCount: z.number().optional(),
  productsPerPage: z.number().optional(),
  scrollBehaviour: z
    .union([z.literal("pagination"), z.literal("scroll")])
    .optional(),
});

export const sitePageMetaSchema = z.object({
  noindex: z.boolean().optional(),
  title: z.string().optional(),
  password: z.string().optional(),
  ogImageAlt: z.string().optional().nullable(),
  ogImagePath: z.string().optional().nullable(),
  ogImageOrigin: z.string().optional().nullable(),
  passwordDesign: z.string().optional(),
  passwordBackText: z.string().optional(),
  passwordButtonText: z.string().optional(),
  passwordHeadingText: z.string().optional(),
  passwordSubheadingText: z.string().optional(),
  passwordPlaceholderText: z.string().optional(),
});

export const sitePageSchema = z.object({
  type: z.string().optional(),
  locale: z.string().optional(),
  initialPageId: z.string().optional(),
  name: z.string().optional(),
  slug: z.string().optional(),
  date: z.string().optional(),
  isDraft: z.boolean().optional(),
  isScheduled: z.boolean().optional(),
  categories: z.array(z.string()).optional(),
  coverImageAlt: z.string().optional().nullable(),
  coverImagePath: z.string().optional(),
  coverImageOrigin: z.string().optional(),
  minutesToRead: z.string().optional(),
  meta: sitePageMetaSchema.optional(),
  footerSlotIsHidden: z.boolean().optional(),
  pageId: z.string().optional(),
  blocks: z.array(z.string()).optional(),
  productId: z.string().optional(),
});

export const linkTypeSchema = z.union([
  z.literal("Page"),
  z.literal("internal"),
  z.literal("external"),
  z.literal("download"),
  z.literal("anchored-section"),
  z.literal("Folder"),
  z.literal("Link"),
]);

export const siteNavItemSchema = z.object({
  linkType: linkTypeSchema.optional(),
  locale: z.string().optional(),
  href: z.string().optional(),
  name: z.string().optional(),
  target: z.string().optional(),
  rel: z.string().optional(),
  innerLinkType: z.string().optional(),
  innerLinkPageId: z.string().optional(),
  subItems: z.array(z.unknown()).optional(),
  navItemId: z.string().optional(),
  linkedPageId: z.string().optional(),
  isHidden: z.boolean().optional(),
});

export const sitePagesSchema = z.record(sitePageSchema);

export const siteBlocksSchema = z.record(siteBlockSchema);

export const siteElementsSchema = z.record(siteElementSchema);

export const siteLanguageSchema = z.object({
  metaTitle: z.string().optional(),
  name: z.string().optional(),
  homePageId: z.string(),
  pages: sitePagesSchema,
  blocks: siteBlocksSchema,
  elements: siteElementsSchema,
  nav: z.array(siteNavItemSchema),
  isNavHidden: z.boolean(),
  cookieBannerAcceptText: z.string().optional(),
  cookieBannerDeclineText: z.string().optional(),
  cookieBannerDisclaimer: z.string().optional(),
  blogReadingTimeText: z.string().optional(),
  orderPosition: z.number().optional(),
});

export const siteMetaSchema = z.object({
  GAId: z.string().optional(),
  shouldAddWWWPrefixToDomain: z.boolean(),
  version: z.number(),
  template: z.string(),
  isFirstLoad: z.boolean().optional(),
  ecommerceStoreId: z.string().optional(),
  demoEcommerceStoreId: z.string().optional(),
  customMeta: z.string().optional(),
  customBodyElements: z.string().optional(),
  customHeadElements: z.string().optional(),
  typographyStylesId: z.string().optional(),
  colorSetId: z.string().optional(),
  buttonSetId: z.string().optional(),
  facebookMessenger: z.string().optional(),
  facebookPixel: z.string().optional(),
  googleAdSense: z.string().optional(),
  googleAdMob: z.string().optional(),
  googleTagManager: z.string().optional(),
  googleAnalytics: z.string().optional(),
  hotjar: z.string().optional(),
  metaTitle: z.string().optional(),
  metaDescription: z.string().optional(),
  metaHtmlLanguage: z.string().optional(),
  ogImagePath: z.string().optional().nullable(),
  ogImageOrigin: z.string().optional().nullable(),
  ogImageAlt: z.string().optional().nullable(),
  faviconPath: z.string().optional(),
  faviconOrigin: z.string().optional(),
  curatedTemplate: z
    .object({
      link: z.string().optional(),
      author: z.string().optional(),
    })
    .optional(),
  stripePublicApiKey: z.string().optional(),
  whatsAppNumber: z.string().optional(),
  whatsAppMessage: z.string().optional(),
  isCookieBarEnabled: z.boolean().optional(),
  jivoChat: z.string().optional(),
  facebookMessengerLocale: z.string().optional(),
  qa: z.boolean().optional(),
  ecommerceType: z.string().optional(),
  defaultLocale: z.string().optional(),
  isLayout: z.boolean(),
  migrationToLayoutModifications: z
    .object({
      splitSlideshows: z.record(z.any()).optional(),
      imageObjectFitRemoved: z.boolean().optional(),
      unsnappedElementSections: z.record(z.any()).optional(),
    })
    .optional(),
  isPrivateModeActive: z.boolean(),
  aiSalesAssistant: z
    .object({
      isEnabled: z.boolean(),
    })
    .optional(),
  isCustomCodeDisabled: z.boolean().optional(),
});

export const siteStylesSchema = z.object({
  h1: siteTypographyStylesSchema,
  h2: siteTypographyStylesSchema,
  h3: siteTypographyStylesSchema,
  h4: siteTypographyStylesSchema,
  h5: siteTypographyStylesSchema,
  h6: siteTypographyStylesSchema,
  "body-small": siteTypographyStylesSchema,
  body: siteTypographyStylesSchema,
  "body-large": siteTypographyStylesSchema,
  "nav-link": siteTypographyStylesSchema,
  font: z.object({
    primary: z.string(),
    secondary: z.string(),
  }),
  "grid-button-primary": siteGridButtonStyleSchema,
  "grid-button-secondary": siteGridButtonStyleSchema,
});

export const siteFormsSchema = z.record(
  z.object({
    token: z.string(),
  }),
);

export const siteLanguagesSchema = z.record(siteLanguageSchema);

export const siteBlogCategorySchema = z.object({
  name: z.string(),
});

export const siteBlogCategoriesSchema = z.record(siteBlogCategorySchema);

export const siteNavigationBlockSchema = z.record(z.unknown());

export const siteUserSchema = z.record(z.unknown());

export const siteFontSchema = z.object({
  family: z.string(),
  type: z.string(),
  weights: z.array(z.union([z.string(), z.number()])).optional(),
});

export const siteBuilderCompletedStepsSchema = z.object({
  hasChangedLogo: z.boolean().optional(),
});

export const siteDataSchema = z.object({
  builderCompletedSteps: siteBuilderCompletedStepsSchema.optional(),
  meta: siteMetaSchema,
  domain: z.string().optional().nullable(),
  freeDomain: z.string().optional(),
  blogCategories: siteBlogCategoriesSchema,
  languages: siteLanguagesSchema,
  styles: siteStylesSchema,
  forms: siteFormsSchema,
  navigationBlock: siteNavigationBlockSchema.optional(),
  user: siteUserSchema.optional(),
  siteId: z.string().optional(),
  fonts: z.array(siteFontSchema).optional(),
  ecommerceShoppingCart: siteEcommerceShoppingCartSchema.optional(),
});

export const siteLanguageSwitcherLanguageSchema = z.object({
  country: z.string(),
  flagPath: z.string(),
  href: z.string(),
  isHidden: z.boolean(),
  locale: z.string(),
  name: z.string(),
});

export const upsertRegionCountrySchema = z.object({
  country_code: z.string(),
  provinces: z.array(z.string()),
});
