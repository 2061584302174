<script setup lang="ts">
import { useEcommerceAdminStore } from '@/stores/ecommerceAdminStore';
import {
	ECOMMERCE_REDIRECT_PATHS,
	REDIRECT_PARAM_KEYS,
	ECOMMERCE_DEFAUTL_PRODUCT_CATEGORY,
} from '@/constants';
import { useSiteStore } from '@/stores/siteStore';
import { useI18n } from 'vue-i18n';

const ecommerceAdminStore = useEcommerceAdminStore();

const siteStore = useSiteStore();
const { t } = useI18n();

const handleEcommerceCategorySettingsClick = () => {
	ecommerceAdminStore.setIsEcommerceAdminIframeOpen({
		isOpen: true,
		path: ECOMMERCE_REDIRECT_PATHS.CATEGORIES_EDIT,
		params: {
			[REDIRECT_PARAM_KEYS.CATEGORY]: siteStore.currentBlock?.productCategoryId || ECOMMERCE_DEFAUTL_PRODUCT_CATEGORY,
		},
	});
};
</script>

<template>
	<i18n-t
		tag="p"
		class="product-order__message"
		keypath="builder.changeOrderOfProducts"
	>
		<span
			class="z-link"
			@click="handleEcommerceCategorySettingsClick"
		>
			{{ t('common.categorySettings') }}
		</span>
	</i18n-t>
</template>

<style lang="scss" scoped>
.product-order {
	&__message {
		color: $color-gray;
		font-size: 14px;
		line-height: 1.5rem;
	}
}
</style>
