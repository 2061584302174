<template>
	<Teleport
		v-if="isBlockEditControlsVisible"
		to="body"
	>
		<!-- Edit Block popup -->
		<Popup
			v-if="isBlockEditorOpen && targetRef"
			:target-ref="targetRef"
			:portal-selector="SELECTOR_DATA_PORTAL_BUILDER_PREVIEW"
			:placement="editPopupPlacement"
			:auto-update="editPopupOptions.autoUpdate"
			:offset="editPopupOptions.offset"
			:flip="editPopupOptions.flip"
			:padding="editPopupOptions.padding"
			class="block-edit-controls__popup"
			is-mobile-fixed-to-bottom
		>
			<slot name="edit-mode-popup" />
		</Popup>

		<!-- Mobile screen resolution controls bar -->
		<Teleport
			v-else-if="isMobileScreen"
			:to="SELECTOR_DATA_PORTAL_BUILDER_PREVIEW_MOBILE_BOTTOM_BAR"
		>
			<BlockEditControlsBar
				:block-id="blockId"
				:enter-edit-mode-button-title="enterEditModeButtonTitle"
				:is-duplicate-button-visible="isDuplicateButtonVisible"
				:is-visibility-button-visible="isVisibilityButtonVisible"
				:is-delete-button-visible="isDeleteButtonVisible"
				:show-delete-warning="showDeleteWarning"
				:is-reordering-button-visible="isReorderingButtonVisible"
				:is-context-menu-button-visible="isContextMenuButtonVisible"
				:is-stretch-to-full-width-button-visible="isStretchToFullWidthButtonVisible"
				:is-block-with-elements="isBlockWithElements"
				:target-ref="targetRef"
			>
				<template #default>
					<slot name="additional-edit-buttons" />
				</template>
			</BlockEditControlsBar>
		</Teleport>

		<!-- Controls bar popup -->
		<Popup
			v-else
			:target-ref="targetRef"
			:portal-selector="SELECTOR_DATA_PORTAL_BUILDER_PREVIEW"
			:placement="controlsPopupPlacement"
			:auto-update="controlsBarPopupOptions.autoUpdate"
			:offset="controlsPopupOffsetMobile"
			:flip="controlsBarPopupOptions.flip"
			is-controls
		>
			<BlockEditControlsBar
				:block-id="blockId"
				:enter-edit-mode-button-title="enterEditModeButtonTitle"
				:is-duplicate-button-visible="isDuplicateButtonVisible"
				:is-visibility-button-visible="isVisibilityButtonVisible"
				:is-delete-button-visible="isDeleteButtonVisible"
				:show-delete-warning="showDeleteWarning"
				:is-reordering-button-visible="isReorderingButtonVisible"
				:is-block-with-elements="isBlockWithElements"
				:is-context-menu-button-visible="isContextMenuButtonVisible"
				:is-stretch-to-full-width-button-visible="isStretchToFullWidthButtonVisible"
				:target-ref="targetRef"
			>
				<template #prepend>
					<slot name="additional-edit-buttons-prepend" />
				</template>
				<template #default>
					<slot name="additional-edit-buttons" />
				</template>
			</BlockEditControlsBar>
		</Popup>
	</Teleport>
</template>

<script setup>
import {
	computed,
	onMounted,
	onBeforeUnmount,
} from 'vue';
import { useStore } from 'vuex';
import Popup from '@/components/global/Popup.vue';

import BlockEditControlsBar from '@/components/builder-controls/BlockEditControlsBar.vue';

import {
	SELECTOR_DATA_PORTAL_BUILDER_PREVIEW,
	SELECTOR_DATA_PORTAL_BUILDER_PREVIEW_MOBILE_BOTTOM_BAR,
} from '@/constants';
import {
	getCode,
	CODE,
} from '@zyro-inc/site-modules/utils/getCode';

import {
	blockHeightOnResize,
	resizedSectionId,
} from '@/use/useSectionResizing';

const props = defineProps({
	blockId: {
		type: String,
		required: true,
	},
	targetRef: {
		type: HTMLElement,
		default: null,
	},
	enterEditModeButtonTitle: {
		type: String,
		default: '',
	},
	isDuplicateButtonVisible: {
		type: Boolean,
		default: false,
	},
	isVisibilityButtonVisible: {
		type: Boolean,
		default: false,
	},
	isDeleteButtonVisible: {
		type: Boolean,
		default: false,
	},
	showDeleteWarning: {
		type: Boolean,
		default: false,
	},
	isReorderingButtonVisible: {
		type: Boolean,
		default: false,
	},
	isContextMenuButtonVisible: {
		type: Boolean,
		default: true,
	},
	isBlockWithElements: {
		type: Boolean,
		default: false,
	},
	editPopupOptions: {
		type: Object,
		default: () => DEFAULT_EDIT_POPUP_OPTIONS,
	},
	controlsBarPopupOptions: {
		type: Object,
		default: () => DEFAULT_CONTROLS_BAR_POPUP_OPTIONS,
	},
	isBlockNonGridElementActive: {
		type: Boolean,
		default: false,
	},
	isStretchToFullWidthButtonVisible: {
		type: Boolean,
		default: false,
	},
});

const {
	state,
	getters,
	dispatch,
} = useStore();

const isMobileScreen = computed(() => state.gui.isMobileScreen);
const isMobileMode = computed(() => getters['gui/isMobileMode']);
const currentBlockId = computed(() => state.currentBlockId);
const currentElementId = computed(() => state.currentElementId);

const isCurrentBlockResized = computed(() => blockHeightOnResize.value && resizedSectionId.value === props.blockId);
const isBlockSelected = computed(() => currentBlockId.value === props.blockId);
const isBlockEditControlsVisible = computed(() => {
	if (props.isBlockWithElements) {
		return isBlockSelected.value && !currentElementId.value && !isCurrentBlockResized.value;
	}

	return isBlockSelected.value && !props.isBlockNonGridElementActive && !isCurrentBlockResized.value;
});

const isBlockEditorOpen = computed(() => state.isBlockEditorOpen);

const controlsPopupPlacement = computed(() => (isMobileMode.value
	? props.controlsBarPopupOptions.placementMobile
	: props.controlsBarPopupOptions.placement));

const controlsPopupOffsetMobile = computed(() => (isMobileMode.value
	? props.controlsBarPopupOptions.offsetMobile
	: props.controlsBarPopupOptions.offset));

const editPopupPlacement = computed(() => (isMobileMode.value
	? props.editPopupOptions.placementMobile
	: props.editPopupOptions.placement));

const onEscapeClick = (event) => {
	if (getCode(event) === CODE.Escape) {
		dispatch('leaveBlockEditMode');
	}
};

onMounted(() => {
	window.addEventListener('keydown', onEscapeClick);
});

onBeforeUnmount(() => {
	window.removeEventListener('keydown', onEscapeClick);
});
</script>

<script>
const DEFAULT_EDIT_POPUP_OPTIONS = {
	placement: 'right-start',
	placementMobile: 'right-start',
	flip: false,
	offset: {
		mainAxis: 24,
		crossAxis: 24,
	},
	offsetMobile: {
		mainAxis: 24,
		crossAxis: 0,
	},
	autoUpdate: true,
	padding: 24,
};

const DEFAULT_CONTROLS_BAR_POPUP_OPTIONS = {
	placement: 'right-start',
	placementMobile: 'top',
	flip: false,
	offset: {
		mainAxis: 24,
		crossAxis: 24,
	},
	offsetMobile: {
		mainAxis: 24,
		crossAxis: 0,
	},
	autoUpdate: true,
	padding: 24,
};

export default {};
</script>

<style lang="scss" scoped>
.block-edit-controls {
	&__popup {
		z-index: $z-index-controls-edit-block-line;
	}
}
</style>
