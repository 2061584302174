<template>
	<AddElementDrawer v-if="activeDrawer === DRAWER_ADD_ELEMENT" />
	<MultiPageDrawer v-else-if="activeDrawer === DRAWER_MULTI_PAGE" />
	<UserStylesDrawer v-else-if="activeDrawer === DRAWER_USER_STYLES" />
	<BlogDrawer v-else-if="activeDrawer === DRAWER_BLOG" />
	<ChooseStoreDrawer v-else-if="activeDrawer === DRAWER_ADD_ONLINE_STORE" />
	<ManageEcommerceStore v-else-if="activeDrawer === DRAWER_MANAGE_STORE" />
	<ManageEcommerceStoreProducts v-else-if="activeDrawer === DRAWER_MANAGE_STORE_PRODUCTS" />
	<MultilingualDrawer v-else-if="activeDrawer === DRAWER_MULTILINGUAL" />
	<HelpAndResourcesDrawer v-else-if="activeDrawer === DRAWER_HELP_AND_RESOURCES" />
	<GamificationDrawer v-else-if="activeDrawer === DRAWER_GAMIFICATION" />
	<AiWriterDrawer v-else-if="activeDrawer === DRAWER_AI_WRITER" />
	<SeoDrawer v-else-if="activeDrawer === DRAWER_SEO" />
	<AiSalesAssistantDrawer v-else-if="activeDrawer === DRAWER_AI_SALES_ASSISTANT" />
	<GeneralSettingsDrawer v-else-if="activeDrawer === DRAWER_GENERAL_SETTINGS" />
	<ExportDrawer v-else-if="activeDrawer === DRAWER_EXPORT" />
	<AnalyticsDrawer v-else-if="activeDrawer === DRAWER_ANALYTICS" />
	<FormsDrawer v-else-if="activeDrawer === DRAWER_FORMS" />
	<IntegrationsDrawer v-else-if="activeDrawer === DRAWER_INTEGRATIONS" />
</template>

<script setup lang="ts">
import {
	computed,
	watch,
} from 'vue';
import { useStore } from 'vuex';
import SeoDrawer from '@/components/builder-drawers/drawers/SeoDrawer.vue';
import ChooseStoreDrawer from '@/components/builder-drawers/drawers/partials/ecommerceStoreDrawer/ChooseStoreDrawer.vue';
import AddElementDrawer from '@/components/builder-drawers/drawers/AddElementDrawer.vue';
import GamificationDrawer from '@/components/builder-drawers/drawers/GamificationDrawer.vue';
import AiWriterDrawer from '@/components/builder-drawers/drawers/AiWriterDrawer.vue';
import BlogDrawer from '@/components/builder-drawers/drawers/BlogDrawer.vue';
import HelpAndResourcesDrawer from '@/components/builder-drawers/drawers/HelpAndResourcesDrawer.vue';
import ManageEcommerceStore from '@/components/builder-drawers/drawers/partials/ecommerceStoreDrawer/ManageEcommerceStore.vue';
import ManageEcommerceStoreProducts from '@/components/builder-drawers/drawers/partials/ecommerceStoreDrawer/ManageEcommerceStoreProducts.vue';
import MultiPageDrawer from '@/components/builder-drawers/drawers/MultiPageDrawer.vue';
import MultilingualDrawer from '@/components/builder-drawers/drawers/MultilingualDrawer.vue';
import UserStylesDrawer from '@/components/builder-drawers/drawers/UserStylesDrawer.vue';
import GeneralSettingsDrawer from '@/components/builder-drawers/drawers/GeneralSettingsDrawer.vue';
import ExportDrawer from '@/components/builder-drawers/drawers/ExportDrawer.vue';
import AnalyticsDrawer from '@/components/builder-drawers/drawers/AnalyticsDrawer.vue';
import FormsDrawer from '@/components/builder-drawers/drawers/FormsDrawer.vue';
import IntegrationsDrawer from '@/components/builder-drawers/drawers/IntegrationsDrawer.vue';
import AiSalesAssistantDrawer from '@/components/builder-drawers/drawers/AiSalesAssistantDrawer.vue';

import {
	DRAWER_USER_STYLES,
	DRAWER_BLOG,
	DRAWER_MULTI_PAGE,
	DRAWER_ADD_ELEMENT,
	DRAWER_ADD_ONLINE_STORE,
	DRAWER_MULTILINGUAL,
	DRAWER_MANAGE_STORE,
	DRAWER_MANAGE_STORE_PRODUCTS,
	DRAWER_HELP_AND_RESOURCES,
	DRAWER_GAMIFICATION,
	DRAWER_SEO,
	DRAWER_AI_SALES_ASSISTANT,
	DRAWER_AI_WRITER,
	DRAWER_GENERAL_SETTINGS,
	DRAWER_INTEGRATIONS,
	DRAWER_ANALYTICS,
	DRAWER_FORMS,
	DRAWER_EXPORT,
} from '@/constants';

const {
	state,
	dispatch,
} = useStore();

const activeDrawer = computed(() => state.gui.activeDrawer);

watch(activeDrawer, (newVal: string) => {
	if (newVal) {
		dispatch('leaveBlockEditMode');
	}
});
</script>
