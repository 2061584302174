<template>
	<div>
		<HostingerButton
			v-if="!item.isHomepage"
			v-qa="'sitemenusettingspopup-btn-setashomepage'"
			button-type="plain"
			@click="setAsHomepage(), toggle()"
		>
			<template #icon-left>
				<Icon
					name="home"
					dimensions="20px"
				/>
			</template>
			<span class="text-body-2">
				{{ $t('common.setAsHomepage') }}
			</span>
		</HostingerButton>
		<HostingerButton
			v-if="!item.isHomepage"
			v-qa="'sitemenusettingspopup-btn-page-url'"
			button-type="plain"
			@click="openPageSettingsPopup(TABS.GENERAL), toggle()"
		>
			<template #icon-left>
				<Icon
					name="link"
					dimensions="20px"
				/>
			</template>
			<span class="text-body-2">
				{{ $t('builder.pageSettingsModal.buttonPageUrlTitle') }}
			</span>
		</HostingerButton>
		<HostingerButton
			v-qa="'sitemenusettingspopup-btn-rename'"
			button-type="plain"
			@click="openPageSettingsPopup(TABS.GENERAL), toggle()"
		>
			<template #icon-left>
				<Icon
					name="edit"
					dimensions="20px"
				/>
			</template>
			<span class="text-body-2">
				{{ $t('common.rename') }}
			</span>
		</HostingerButton>
		<HostingerButton
			v-qa="'sitemenusettingspopup-btn-duplicate'"
			button-type="plain"
			@click="duplicate(), toggle()"
		>
			<template #icon-left>
				<Icon
					name="file_copy"
					dimensions="20px"
				/>
			</template>
			<span class="text-body-2">
				{{ $t('common.duplicate') }}
			</span>
		</HostingerButton>
		<HideItemButton
			:item="item"
			:toggle="toggle"
		/>
		<DropdownControlButtons :item="item" />
		<HostingerButton
			v-qa="'sitemenusettingspopup-btn-PagePasswordSettings'"
			button-type="plain"
			@click="openPageSettingsPopup(TABS.PASSWORD_PROTECTION), toggle()"
		>
			<template #icon-left>
				<Icon
					name="vpn_key"
					dimensions="20px"
				/>
			</template>
			<span class="text-body-2">
				{{ $t('common.password') }}
			</span>
		</HostingerButton>
		<Divider />
		<HostingerButton
			v-qa="'sitemenusettingspopup-btn-pageSeoSettings'"
			button-type="plain"
			@click="openPageSettingsPopup(TABS.SEO), toggle()"
		>
			<template #icon-left>
				<Icon
					name="search"
					dimensions="20px"
				/>
			</template>
			<span class="text-body-2">
				{{ $t('builder.pageSettingsModal.buttonSeoTitle') }}
			</span>
		</HostingerButton>
		<HostingerButton
			v-qa="'sitemenusettingspopup-btn-pageSocialImage'"
			button-type="plain"
			@click="openPageSettingsPopup(TABS.SOCIAL_IMAGE), toggle()"
		>
			<template #icon-left>
				<Icon
					name="image"
					dimensions="20px"
				/>
			</template>
			<span class="text-body-2">
				{{ $t('common.socialImage') }}
			</span>
		</HostingerButton>
		<CreateQrButton
			:page-id="item.linkedPageId"
			:location="QR_CODE_LOCATION_TYPES.PAGES"
			@click="toggle()"
		/>
		<Divider v-if="!item.isHomepage" />
		<HostingerButton
			v-if="!item.isHomepage"
			v-qa="'sitemenusettingspopup-btn-delete'"
			button-type="plain"
			@click="isDeleteModalShown = true"
		>
			<template #icon-left>
				<Icon
					name="delete"
					dimensions="20px"
				/>
			</template>
			<span class="text-body-2">
				{{ $t('common.delete') }}
			</span>
		</HostingerButton>
		<Teleport
			v-if="isDeleteModalShown"
			to="body"
		>
			<SystemDialogModal
				:title="$t('builder.deletePageModal.title')"
				:primary-button-text="$t('common.cancel')"
				:secondary-button-text="$t('common.delete')"
				secondary-button-color="danger"
				class="delete-modal"
				@click-primary="isDeleteModalShown = false, toggle()"
				@click-secondary="removeItem"
				@close="isDeleteModalShown = false, toggle()"
			>
				<p class="text-body-1">
					{{ $t('builder.deletePageModal.text') }}
					<span class="text-bold-1">{{ item.name }}</span>?
				</p>
				<p class="text-body-1">
					{{ $t('builder.deletePageModal.subText') }}
				</p>
			</SystemDialogModal>
		</Teleport>
	</div>
</template>

<script lang="ts">
import Icon from '@/components/global/Icon.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';

import {
	mapGetters,
	mapActions,
} from 'vuex';

import Divider from '@/components/builder-controls/Divider.vue';
import DropdownControlButtons from '@/components/builder-controls/multipage-drawer/popup-buttons/-partials/DropdownControlButtons.vue';
import HideItemButton from '@/components/builder-controls/multipage-drawer/popup-buttons/-partials/HideItemButton.vue';
import CreateQrButton from '@/components/builder-controls/multipage-drawer/popup-buttons/-partials/CreateQrButton.vue';
import SystemDialogModal from '@/components/builder-modals/modals/SystemDialogModal.vue';
import { generateRandomId } from '@/utils/generateRandomId';
import {
	TABS,
	TabsType,
	QR_CODE_LOCATION_TYPES,
} from '@zyro-inc/site-modules/constants';

import { defineComponent } from 'vue';
import {
	mapActionsGui,
	OPEN_DRAWER,
} from '@/store/builder/gui';
import { DRAWER_SEO } from '@/constants';
import { useSiteStore } from '@/stores/siteStore';
import { storeToRefs } from 'pinia';

export default defineComponent({
	components: {
		Icon,
		HostingerButton,
		Divider,
		DropdownControlButtons,
		HideItemButton,
		CreateQrButton,
		SystemDialogModal,
	},

	props: {
		item: {
			type: Object,
			required: true,
		},
		toggle: {
			type: Function,
			default: () => ({}),
		},
	},
	emits: ['open-page-settings-popup'],
	setup() {
		const siteStore = useSiteStore();
		const { site: website } = storeToRefs(siteStore);

		return {
			TABS,
			QR_CODE_LOCATION_TYPES,
			website,
		};
	},
	data() {
		return {
			isDeleteModalShown: false,
		};
	},
	computed: {
		...mapGetters([
			'homePageId',
			'defaultPages',
		]),
	},

	methods: {
		...mapActions([
			'duplicatePage',
			'removePage',
			'mergePageData',
			'updateHomePage',
		]),
		...mapActions(['updateInternalLinks']),
		...mapActionsGui({
			openDrawer: OPEN_DRAWER,
		}),
		setAsHomepage() {
			// TODO: This should be done by a mapper.
			if (!this.defaultPages[this.homePageId]?.slug) {
				this.mergePageData({
					pageId: this.homePageId,
					pageData: {
						slug: generateRandomId(),
					},
				});
			}

			this.updateHomePage({
				pageId: this.item.linkedPageId,
			});
			this.updateInternalLinks({
				oldLink: `/${this.defaultPages[this.item.linkedPageId]?.slug}`,
				newLink: '/',
			});
		},
		openPageSettingsPopup(tab: TabsType) {
			if (tab === TABS.SEO) {
				this.openDrawer({
					id: DRAWER_SEO,
					settings: {
						pageId: this.item.linkedPageId,
					},
				});

				return;
			}

			this.$emit('open-page-settings-popup', {
				pageId: this.item.linkedPageId,
				tab: tab || TABS.GENERAL,
			});
		},
		duplicate() {
			this.duplicatePage({
				siteData: this.website,
				pageId: this.item.linkedPageId,
			});
		},
		removeItem() {
			this.updateInternalLinks({
				oldLink: `/${this.defaultPages[this.item.linkedPageId]?.slug}`,
				newLink: '',
			});

			this.removePage({
				pageId: this.item.linkedPageId,
			});

			this.isDeleteModalShown = false;

			this.toggle();
		},
	},
});
</script>

<style lang="scss" scoped>
.delete-modal {
	z-index: $z-index-popup;
}
</style>
