import { ref } from 'vue';
import { callGeneratePage } from '@/api/AiApi';
import { getBiggestIncrementedString } from '@zyro-inc/site-modules/utils/modifyString';
import { convertStringToKebabCase } from '@/utils/string';
import {
	addBreadcrumb,
	captureException,
} from '@sentry/vue';
import { useStore } from 'vuex';
import {
	MAX_LENGTH_PAGE_SLUG,
	NPS_TYPE_FEATURE_AI_PAGE_GENERATION,
} from '@/constants';
import EventLogApi from '@/api/EventLogApi';
import { SitePage } from '@hostinger/builder-schema-validator';
import { PAGE_TYPE_DEFAULT } from '@zyro-inc/site-modules/constants';
import { useNotifications } from '@/use/useNotifications';
import { useI18n } from 'vue-i18n';
import { AxiosError } from 'axios';
import { useSiteTextElementPreview } from '@/use/useSiteTextElementPreview';
import { useNpsStore } from '@/stores';

export const useAiPageGenerator = () => {
	const {
		getters,
		dispatch,
	} = useStore();

	const npsStore = useNpsStore();
	const { notify } = useNotifications();
	const { t } = useI18n();
	const { recalculateWebsiteTextHeights } = useSiteTextElementPreview();

	const isGeneratingPage = ref(false);
	const pageDescription = ref('');

	const selectGeneratedPage = ({ pages }: { pages: SitePage }) => {
		const generatedPage = Object.entries(pages).find(([, pageData]) => (pageData as SitePage).type === PAGE_TYPE_DEFAULT);

		if (!generatedPage) {
			captureException('Cannot navigated to newly generated page due to type mismatch - did not find "default" page', {
				tags: {
					errorType: 'AI Page generation error',
				},
			});

			throw Error('Navigation to generated page failed');
		}

		dispatch('updateCurrentPageId', generatedPage[0]);
	};

	/**
 * Converts all conflicting slugs to unique ones
 * @param pages - Pages that might have conflicting slugs
 * @returns pages with unique slugs
 */
	const resolveConflictingPageSlugs = ({ pages }: { pages: SitePage }) => Object.fromEntries(
		Object.entries(pages as Record<string, SitePage>)
			.map(([pageId, pageData]) => {
				const pageName = pageData.name as string;

				const trimmedPageName = pageName.slice(0, MAX_LENGTH_PAGE_SLUG);
				const kebabCasePageName = convertStringToKebabCase(trimmedPageName);

				const pageSlugs = Object.values(getters.currentLanguageData.pages)
					.map((currentPageData) => (currentPageData as SitePage).slug);

				const validatedPageSlug = getBiggestIncrementedString({
					stringToMatch: kebabCasePageName,
					strings: pageSlugs,
				});

				return [
					pageId,
					{
						...pageData,
						slug: validatedPageSlug,
					},
				];
			}),
	);

	const generatePage = async (): Promise<void> => {
		if (pageDescription.value === '') {
			return;
		}

		EventLogApi.logEvent({
			eventName: 'website_builder.add_page.create_page',
			eventProperties: {
				prompt: pageDescription.value,
			},
		});

		addBreadcrumb({
			category: 'CLICK:Generating page',
			data: {
				pageDescription: pageDescription.value,
			},
		});

		isGeneratingPage.value = true;

		try {
			const { data } = await callGeneratePage({
				pageDescription: pageDescription.value,
			});

			const {
				nav,
				pages,
				blocks,
				elements,
			} = data.siteData.languages.system;

			const pagesWithValidatedSlugs = resolveConflictingPageSlugs({
				pages,
			});

			const updatedNav = [
				...getters.currentLanguageData.nav,
				...nav,
			];

			dispatch('mergeLanguageData', {
				languageData: {
					blocks,
					elements,
					pages: pagesWithValidatedSlugs,
					nav: updatedNav,
				},
			});

			recalculateWebsiteTextHeights();

			selectGeneratedPage({
				pages: pagesWithValidatedSlugs,
			});

			EventLogApi.logEvent({
				eventName: 'website_builder.add_page.page_generated',
			});

			npsStore.setNpsData({
				formType: NPS_TYPE_FEATURE_AI_PAGE_GENERATION,
				isVisible: true,
				newQuestion: `${t('builder.npsRateQuestion')} ${t('builder.aiPageGenerator')}`,
			});
		} catch (error) {
			captureException(error);

			if ((error as AxiosError).response?.status === 501) {
				notify({
					headingI18nKeyPath: 'builder.aiPageGeneratorEcomFailureHeading',
					messageI18nKeyPath: 'builder.aiPageGeneratorEcomFailureDescription',
				});

				return;
			}

			notify({
				message: t('builder.aiImageGenerationErrorMessage'),
			});
		}

		isGeneratingPage.value = false;
	};

	return {
		isGeneratingPage,
		pageDescription,
		generatePage,
	};
};
