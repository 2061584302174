<template>
	<template v-if="user || isLocalEnv">
		<BuilderSidebarActionButton
			ref="triggerButtonSettings"
			v-qa="'builder-header-btn-settings'"
			:is-active="isActive"
			:title="title"
			:icon="icon"
			@action-click="$emit('action-click')"
		/>
		<Popup
			v-if="isActive"
			:target-ref="$refs.triggerButtonSettings?.$el"
			@click-outside="$emit('close-popup')"
		>
			<div class="sidebar-popup">
				<ul>
					<li
						v-for="item in SETTINGS_ITEMS"
						:key="item.titlePath"
						class="sidebar-popup__list-item"
						:class="{ 'sidebar-popup__list-item--with-divider': item.hasDivider }"
					>
						<HostingerButton
							v-qa="`builder-header-settingsddl-${$t(item.titlePath)}`"
							button-type="plain"
							class="settings__popup-button"
							@click="handleButtonClick({
								buttonAction: item.clickHandler,
								eventName: item.eventName
							})"
						>
							<div class="text-body-2 settings__popup-button__content">
								<Icon
									:name="item.icon"
									:is-custom="item.isCustomIcon"
								/>

								{{ $t(item.titlePath) }}
								<BetaBadge v-if="item.isBeta" />
							</div>
							<Icon
								v-if="item.isExternalLink"
								name="open_in_new"
								dimensions="20px"
							/>
						</HostingerButton>
					</li>
				</ul>
				<div
					v-if="!isHostingerBrand || isImpersonating"
					class="settings__lang-switcher sidebar-popup__list-item"
					@mouseenter="toggleList"
					@mouseleave="toggleList"
				>
					<HostingerButton
						v-qa="'builder-header-settingsddl-language'"
						button-type="[plain]"
					>
						<template #icon-right>
							<Icon name="language" />
						</template>
						{{ $t('common.language') }}
					</HostingerButton>
					<p class="settings__lang-switcher-text text-body-2">
						{{ currentLanguage }}
					</p>
					<LanguageSwitcher
						v-if="isListOpen"
						class="settings__languages-list"
					/>
				</div>
			</div>
		</Popup>
	</template>
	<template v-else>
		<HostingerButton
			ref="triggerButtonLanguage"
			button-type="plain"
			@click="$emit('action-click')"
		>
			<template #icon>
				<Icon
					name="globe"
					is-custom
				/>
			</template>
		</HostingerButton>
		<Popup
			v-if="isActive"
			:target-ref="$refs.triggerButtonLanguage && $refs.triggerButtonLanguage.$el"
			@click-outside="$emit('close-popup')"
		>
			<LanguageSwitcher />
		</Popup>
	</template>
</template>

<script setup>
import {
	ref,
	computed,
} from 'vue';

import { useStore } from 'vuex';
import BuilderSidebarActionButton from '@/components/builder-view/sidebar/BuilderSidebarActionButton.vue';

import {
	SIDEBAR_SETTINGS_POPUP_ITEM_ID_VERSION_HISTORY,
	SIDEBAR_SETTINGS_POPUP_ITEM_ID_GENERAL,
	SIDEBAR_SETTINGS_POPUP_ITEM_ID_INTEGRATIONS,
	SIDEBAR_SETTINGS_POPUP_ITEM_ID_ANALYTICS,
	SIDEBAR_SETTINGS_POPUP_ITEM_ID_SEO,
	SIDEBAR_SETTINGS_POPUP_ITEM_ID_AI_SALES_ASSISTANT,
	SIDEBAR_SETTINGS_POPUP_ITEM_ID_FORMS,
	SIDEBAR_SETTINGS_POPUP_ITEM_ID_ASSETS,
	SIDEBAR_SETTINGS_POPUP_ITEM_ID_EXPORT,
	SIDEBAR_SETTINGS_POPUP_ITEM_ID_MULTILANGUAGE,
	SIDEBAR_SETTINGS_POPUP_ITEM_ID_BLOG,
	SIDEBAR_SETTINGS_POPUP_ITEM_APPOINTMENTS,
	SIDEBAR_SETTINGS_POPUP_ITEM_ID_HELP,
	DRAWER_HELP_AND_RESOURCES,
	DRAWER_MULTILINGUAL,
	DRAWER_BLOG,
	DRAWER_SEO,
	DRAWER_AI_SALES_ASSISTANT,
	DRAWER_GENERAL_SETTINGS,
	DRAWER_INTEGRATIONS,
	DRAWER_ANALYTICS,
	DRAWER_FORMS,
	DRAWER_EXPORT,
	SIDEBAR_SETTINGS_POPUP_ITEM_ID_PRODUCT_UPDATES,
	ROADMAP_LINK,
} from '@/constants';
import { getLocale } from '@/utils/i18n/supportedLocales';
import { isHostingerBrand } from '@/utils/isHostingerBrand';
import Icon from '@/components/global/Icon.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import Popup from '@/components/global/Popup.vue';
import LanguageSwitcher from '@/components/builder-view/LanguageSwitcher.vue';
import EventLogApi from '@/api/EventLogApi';
import { useI18n } from 'vue-i18n';
import { useSiteVersions } from '@/use/useSiteVersions';
import { useRedirects } from '@/use/useRedirects';
import BetaBadge from '@/components/ui/BetaBadge.vue';

const isLocalEnv = import.meta.env.DEV;

defineProps({
	isActive: {
		type: Boolean,
		required: true,
	},
	title: {
		type: String,
		required: true,
	},
	icon: {
		type: String,
		required: true,
	},
});
const emit = defineEmits([
	'close-popup',
	'action-click',
]);

const {
	state,
	dispatch,
} = useStore();
const { locale } = useI18n();
const { openVersionHistory } = useSiteVersions();

const { redirectToEcommerceBookings } = useRedirects();

const isListOpen = ref(false);

const SETTINGS_ITEMS = [
	{
		id: SIDEBAR_SETTINGS_POPUP_ITEM_ID_BLOG,
		titlePath: 'builder.blog.blogDrawer.title',
		icon: 'checkbook',
		clickHandler: () => {
			dispatch('gui/OPEN_DRAWER', {
				id: DRAWER_BLOG,
			});
		},
	},
	{
		id: SIDEBAR_SETTINGS_POPUP_ITEM_APPOINTMENTS,
		titlePath: 'builder.appointments',
		icon: 'calendar_month',
		clickHandler: () => {
			redirectToEcommerceBookings();
		},
		eventName: 'website_builder.ecomm_bookings.enter',
	},
	{
		id: SIDEBAR_SETTINGS_POPUP_ITEM_ID_GENERAL,
		titlePath: 'siteSettings.nav.general',
		icon: 'captive_portal',
		hasDivider: true,
		clickHandler: () => dispatch('gui/OPEN_DRAWER', {
			id: DRAWER_GENERAL_SETTINGS,
		}),
		eventName: 'website_builder.website_settings_general_settings.enter',
	},
	{
		id: SIDEBAR_SETTINGS_POPUP_ITEM_ID_INTEGRATIONS,
		titlePath: 'siteSettings.nav.integrations',
		icon: 'apps',
		clickHandler: () => dispatch('gui/OPEN_DRAWER', {
			id: DRAWER_INTEGRATIONS,
		}),
		eventName: 'website_builder.website_settings_integrations.enter',
	},
	{
		id: SIDEBAR_SETTINGS_POPUP_ITEM_ID_FORMS,
		titlePath: 'siteSettings.nav.forms',
		icon: 'contact_page',
		clickHandler: () => dispatch('gui/OPEN_DRAWER', {
			id: DRAWER_FORMS,
		}),
		eventName: 'website_builder.website_settings_form_submissions.enter',
	},
	{
		id: SIDEBAR_SETTINGS_POPUP_ITEM_ID_ANALYTICS,
		titlePath: 'builder.analytics',
		icon: 'finance',
		clickHandler: () => dispatch('gui/OPEN_DRAWER', {
			id: DRAWER_ANALYTICS,
		}),
		eventName: 'website_builder.website_settings_analytics.enter',
	},
	{
		id: SIDEBAR_SETTINGS_POPUP_ITEM_ID_SEO,
		titlePath: 'siteSettings.nav.seoV2',
		icon: 'travel_explore',
		hasDivider: true,
		clickHandler: () => {
			dispatch('gui/OPEN_DRAWER', {
				id: DRAWER_SEO,
			});
		},
		eventName: 'website_builder.website_settings_seo.enter',
	},
	{
		id: SIDEBAR_SETTINGS_POPUP_ITEM_ID_AI_SALES_ASSISTANT,
		titlePath: 'siteSettings.nav.aiSalesAssistantV3',
		icon: 'branding_watermark',
		clickHandler: () => {
			dispatch('gui/OPEN_DRAWER', {
				id: DRAWER_AI_SALES_ASSISTANT,
			});
		},
		isBeta: true,
		eventName: 'website_builder.website_settings_ai_sales_assistant.enter',
	},
	{
		id: SIDEBAR_SETTINGS_POPUP_ITEM_ID_ASSETS,
		titlePath: 'builder.assetManagerMediaLibrary',
		icon: 'photo_library',
		clickHandler: () => {
			dispatch('gui/setAssetManagerVisibility', true);
		},
		eventName: 'website_builder.website_settings_media_library.enter',
	},
	{
		id: SIDEBAR_SETTINGS_POPUP_ITEM_ID_MULTILANGUAGE,
		titlePath: 'siteSettings.multiLanguage',
		icon: 'translate',
		clickHandler: () => {
			dispatch('gui/OPEN_DRAWER', {
				id: DRAWER_MULTILINGUAL,
			});
			EventLogApi.logEvent({
				eventName: 'website_builder.languages.enter',
				eventProperties: {
					location: 'drawer',
				},
			});
		},
	},
	{
		id: SIDEBAR_SETTINGS_POPUP_ITEM_ID_VERSION_HISTORY,
		titlePath: 'builder.versionHistoryManage',
		icon: 'backup',
		clickHandler: () => {
			openVersionHistory();
			EventLogApi.logEvent({
				eventName: 'website_builder.backups.enter',
				eventProperties: {
					location: 'drawer',
					create_location: 'website_settings',
				},
			});
		},
	},
	{
		id: SIDEBAR_SETTINGS_POPUP_ITEM_ID_EXPORT,
		titlePath: 'builder.siteSettingsWebsiteExportWordpress',
		icon: 'wordpress',
		isCustomIcon: true,
		clickHandler: () => dispatch('gui/OPEN_DRAWER', {
			id: DRAWER_EXPORT,
		}),
		eventName: 'website_builder.website_settings_export_wordpress.enter',
	},
	{
		id: SIDEBAR_SETTINGS_POPUP_ITEM_ID_HELP,
		titlePath: 'builder.helpAndResources',
		icon: 'help',
		hasDivider: true,
		clickHandler: () => {
			dispatch('gui/OPEN_DRAWER', {
				id: DRAWER_HELP_AND_RESOURCES,
			});
		},
	},
	{
		id: SIDEBAR_SETTINGS_POPUP_ITEM_ID_PRODUCT_UPDATES,
		titlePath: 'builder.whatsNew',
		icon: 'campaign',
		isExternalLink: true,
		clickHandler: () => {
			window.open(ROADMAP_LINK, '_blank').focus();
			EventLogApi.logEvent({
				eventName: 'website_builder.product_updates.enter',
				eventProperties: {
					location: 'drawer',
					create_location: 'website_settings',
				},
			});
		},
	},
];
const user = computed(() => state.user.user);
const currentLanguage = computed(() => getLocale(locale.value).language);
const isImpersonating = computed(() => state.user.user?.isStaff);
const toggleList = () => {
	isListOpen.value = !isListOpen.value;
};

const handleButtonClick = ({
	buttonAction,
	eventName,
}) => {
	buttonAction();

	if (eventName) {
		EventLogApi.logEvent({
			eventName,
			eventProperties: {
				location: 'side_bar_settings_popup',
			},
		});
	}

	emit('close-popup');
};
</script>

<style lang="scss" scoped>
@import "./BuilderSidebarPopup";

.sidebar-popup {
	max-width: 320px;

	ul {
		max-height: 80vh;
		overflow-y: auto;
	}
}

.settings {
	&__languages-list {
		position: absolute;
		top: 0;
		left: $popup-width;
	}

	&__lang-switcher {
		background: $color-light;
		border-top: 1px solid $color-gray-border;
		padding: 4px 0;
		margin-top: 4px;
	}

	&__lang-switcher-text {
		margin: 4px 0 0 8px;
		color: $color-gray;
	}

	&__popup-button {
		width: 100%;

		:deep(.hostinger-button__text) {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__content {
			display: flex;
			align-items: center;
			gap: 8px;
		}
	}
}
</style>
