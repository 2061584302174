import { useStore } from 'vuex';
import { computed } from 'vue';

import { parseTagsCountFromHTML } from '@/utils/rehypeUtils';
import {
	PAGE_TYPE_BLOG,
	PAGE_TYPE_PRIVATE,
	ELEMENT_TYPE_IMAGE,
} from '@zyro-inc/site-modules/constants';

import { isProductPage } from '@zyro-inc/site-modules/utils/ecommerce';
import { useSeoStore } from '@/stores/seoStore';
import { useEcommerceStore } from '@/stores/ecommerceStore';
import { SEO_DRAWER_TAB_ECOMMERCE } from '@/constants';

import {
	getIsSeoTitleValid,
	getIsSeoDescriptionValid,
	getFocusKeywordIncludedInPageSlug,
	getIsProductPageSeoValid,
} from '@/utils/seoValidation';

import { extractText } from '@/utils/extractText';

export const getPageBlockData = ({
	pageId,
	sitePages,
	siteBlocks,
}) => {
	const pageBlocks = sitePages[pageId]?.blocks || [];

	return Object.entries(siteBlocks)
		.filter(([blockId]) => pageBlocks.includes(blockId))
		.sort(([blockIdA], [blockIdB]) => {
			const indexA = pageBlocks.indexOf(blockIdA);
			const indexB = pageBlocks.indexOf(blockIdB);

			return indexA - indexB;
		})
		.reduce((acc, [blockId, blockData]) => {
			acc[blockId] = blockData;

			return acc;
		}, {});
};

export const getPageElements = ({
	pageBlockData,
	siteElements,
}) => {
	const pageElementIds = Object.values(pageBlockData).flatMap(
		(blockData) => blockData.components || [],
	);

	return Object.fromEntries(
		Object.entries(siteElements).filter(([elementId]) => pageElementIds.includes(elementId)),
	);
};

export const getPureTextContentFromPageElements = ({
	pageBlockData,
	siteElements,
}) => {
	const pageElements = getPageElements({
		pageBlockData,
		siteElements,
	});
	const domParser = new DOMParser();

	return Object.entries(pageElements)
		.map(([_, elementData]) => {
			if (elementData.content) {
				const parsedHtml = domParser.parseFromString(
					elementData.content,
					'text/html',
				);

				return parsedHtml ? extractText(parsedHtml) : '';
			}

			return '';
		})
		.filter(
			(content, index, array) => content && array.indexOf(content) === index,
		);
};

export const getTextContentFromPageElements = ({
	pageBlockData,
	siteElements,
}) => {
	const pageElements = getPageElements({
		pageBlockData,
		siteElements,
	});

	return Object.entries(pageElements)
		.map(([_, elementData]) => elementData.content || '')
		.filter(
			(content, index, array) => content && array.indexOf(content) === index,
		);
};

export const getPureContentPageTextElements = ({
	pageId,
	sitePages,
	siteBlocks,
	siteElements,
}) => {
	const pageBlockData = getPageBlockData({
		pageId,
		sitePages,
		siteBlocks,
	});

	return {
		id: pageId,
		content: getPureTextContentFromPageElements({
			pageBlockData,
			siteElements,
		}),
	};
};

export const getPageTextElements = ({
	pageId,
	sitePages,
	siteBlocks,
	siteElements,
}) => {
	const pageBlockData = getPageBlockData({
		pageId,
		sitePages,
		siteBlocks,
	});

	return {
		id: pageId,
		content: getTextContentFromPageElements({
			pageBlockData,
			siteElements,
		}),
	};
};

export const getPageSeoTitle = ({
	pageId,
	pagesToCheck,
}) => pagesToCheck[pageId]?.meta?.title || '';

export const getPageSeoDescription = ({
	pageId,
	pagesToCheck,
}) => pagesToCheck[pageId]?.meta?.description || '';

export const getPageSlug = ({
	pageId,
	pagesToCheck,
}) => pagesToCheck[pageId]?.slug || '';

export const getPagesIdsWithoutUniqueSlug = ({
	sitePages,
	products,
	hiddenProducts,
	productMetaUpdates,
	slugList,
}) => {
	const pagesTypesNotShowedInSeoDrawer = [
		PAGE_TYPE_BLOG,
		PAGE_TYPE_PRIVATE,
	];

	const pagesWithNonUniqueSlug = Object.keys(sitePages)
		.filter((pageId) => {
			const page = sitePages[pageId];

			if (pagesTypesNotShowedInSeoDrawer.includes(page.type)) {
				return false;
			}

			if (page.productId) {
				const product = products.find((p) => p.id === page.productId)
					|| hiddenProducts.find((p) => p.id === page.productId);

				const productSlugUpdated = productMetaUpdates[product.id]?.slug;
				const productSlug = product.seo_settings?.slug;

				return slugList.filter((slug) => slug === productSlugUpdated || slug === productSlug).length > 1;
			}

			const pageSlug = sitePages[pageId].slug;

			return slugList.filter((slug) => slug === pageSlug).length > 1;
		});

	return pagesWithNonUniqueSlug;
};

export const useSeo = () => {
	const {
		getters,
		state,
	} = useStore();
	const seoStore = useSeoStore();
	const ecommerceStore = useEcommerceStore();

	const sitePages = computed(() => getters.sitePages);
	const homePageId = computed(() => getters.homePageId);
	const siteBlocks = computed(() => getters.siteBlocks);
	const siteElements = computed(() => getters.siteElements);
	const products = computed(() => ecommerceStore.productsSeo);
	const hiddenProducts = computed(() => ecommerceStore.hiddenProducts);

	const productSlugs = computed(() => ecommerceStore.productSlugs);
	const pageSlugs = computed(() => Object.values(sitePages.value)
		.filter((page) => page.type !== PAGE_TYPE_BLOG)
		.map((page) => page.slug));
	const slugList = computed(() => [
		...pageSlugs.value,
		...productSlugs.value,
	].filter((slug) => slug));

	const currentPageTextElementContent = computed(() => {
		const pageId = state.currentPageId;

		return getPageTextElements({
			pageId,
			sitePages: sitePages.value,
			siteBlocks: siteBlocks.value,
			siteElements: siteElements.value,
		}).content;
	});

	const getWordCountFromPage = (pageId) => {
		const { content } = getPureContentPageTextElements({
			pageId,
			sitePages: sitePages.value,
			siteBlocks: siteBlocks.value,
			siteElements: siteElements.value,
		});

		if (content.length === 0) return 0;

		return content.join(' ').split(' ').length;
	};

	const getAllImagesFromPage = (pageId) => {
		const pageBlockData = getPageBlockData({
			pageId,
			sitePages: sitePages.value,
			siteBlocks: siteBlocks.value,
		});

		const pageElements = getPageElements({
			pageBlockData,
			siteElements: siteElements.value,
		});

		return Object.entries(pageElements).filter(
			([, elementData]) => elementData.type === ELEMENT_TYPE_IMAGE,
		);
	};

	const getImagesCountWithoutAlt = (pageId) => getAllImagesFromPage(pageId).filter(
		([, elementData]) => !elementData.settings.alt,
	).length;

	const h1CountInPage = computed(() => parseTagsCountFromHTML({
		html: currentPageTextElementContent.value.join(''),
		tagName: 'h1',
	}));
	const isPageWithSingleH1 = computed(() => h1CountInPage.value === 1);

	const getH1CountFromPage = (pageId) => parseTagsCountFromHTML({
		html: getPageTextElements({
			pageId,
			sitePages: sitePages.value,
			siteBlocks: siteBlocks.value,
			siteElements: siteElements.value,
		}).content.join(''),
		tagName: 'h1',
	});

	const isDefaultPageSeoValid = (pageId, pagesToCheck) => {
		const isHomePage = homePageId.value === pageId;

		const seoTitle = getPageSeoTitle({
			pageId,
			pagesToCheck,
		});
		const seoDescription = getPageSeoDescription({
			pageId,
			pagesToCheck,
		});
		const focusKeyword = pagesToCheck[pageId]?.meta?.focusKeyword;
		const pageSlug = getPageSlug({
			pageId,
			pagesToCheck,
		});

		const isSeoDescriptionValid = getIsSeoDescriptionValid({
			seoDescription,
			focusKeyword,
		});

		const isSeoTitleValid = getIsSeoTitleValid({
			seoTitle,
			focusKeyword,
		});
		const isH1Valid = getH1CountFromPage(pageId) === 1;
		const isPageSlugValid = isHomePage ? true : getFocusKeywordIncludedInPageSlug({
			pageSlug,
			focusKeyword,
		});

		const commonChecksPass = isSeoDescriptionValid && isSeoTitleValid && isPageSlugValid;

		const pageType = seoStore.currentTab.id === SEO_DRAWER_TAB_ECOMMERCE
			? seoStore.ecommercePages[pageId]?.type
			: sitePages.value[pageId]?.type;

		if (isProductPage(pageType)) {
			return commonChecksPass;
		}

		return isH1Valid && commonChecksPass;
	};

	const isPageSeoValid = (pageId) => {
		const pageType = seoStore.currentTab.id === SEO_DRAWER_TAB_ECOMMERCE
			? seoStore.ecommercePages[pageId]?.type
			: sitePages.value[pageId]?.type;

		const isPageValid = isProductPage(pageType)
			? getIsProductPageSeoValid({
				pageId,
				products: [
					...products.value,
					...hiddenProducts.value,
				],
				pages: seoStore.ecommercePages,
				productMetaUpdate: state.ecommerce.productMetaUpdates,
				productSlugs: productSlugs.value,
			})
			: isDefaultPageSeoValid(pageId, sitePages.value);

		return isPageValid;
	};

	const allPageSeoStatuses = computed(() => Object.fromEntries(
		Object.entries({
			...seoStore.defaultNavigationPages,
			...seoStore.ecommercePages,
		}).map(([pageId]) => [
			pageId,
			isPageSeoValid(pageId),
		]),
	));

	const currentTabSeoStatuses = computed(() => Object.fromEntries(
		Object.entries(seoStore.currentTabPages).map(([pageId]) => [
			pageId,
			isPageSeoValid(pageId),
		]),
	));

	const isHomePageSeoValid = computed(() => isPageSeoValid(homePageId.value, sitePages.value));

	const areAllPagesSlugsValid = computed(() => {
		const uniqueSlugs = new Set(slugList.value);

		// Check if the products doesn't contain empty slugs
		const noEmptyProductSlugs = productSlugs.value.every((slug) => slug !== '');

		// Check if all slugs are unique
		const isAllSlugsUnique = uniqueSlugs.size === slugList.value.length;

		return noEmptyProductSlugs && isAllSlugsUnique;
	});

	const pagesIdsWithoutUniqueSlug = computed(() => getPagesIdsWithoutUniqueSlug({
		sitePages: sitePages.value,
		products: products.value,
		hiddenProducts: hiddenProducts.value,
		productMetaUpdates: state.ecommerce.productMetaUpdates,
		slugList: slugList.value,
	}));

	const firstPageIdWithoutUniqueSlug = computed(() => pagesIdsWithoutUniqueSlug.value[0]);

	return {
		isHomePageSeoValid,
		h1CountInPage,
		allPageSeoStatuses,
		currentTabSeoStatuses,
		getWordCountFromPage,
		isPageWithSingleH1,
		getImagesCountWithoutAlt,
		getAllImagesFromPage,
		areAllPagesSlugsValid,
		pagesIdsWithoutUniqueSlug,
		firstPageIdWithoutUniqueSlug,
		slugList,
	};
};
