<script setup lang="ts">
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import HostingerButton from '@/components/global/HostingerButton.vue';
import ControlsTooltip from '@/components/ControlsTooltip.vue';
import BlockEditControls from '@/components/builder-controls/BlockEditControls.vue';
import VerticalSeparator from '@/components/global/VerticalSeparator.vue';
import BlockControls from '@/components/block/BlockControls.vue';
import BlockEcommerceProduct from '@zyro-inc/site-modules/components/blocks/ecommerce/BlockEcommerceProduct.vue';
import EditBlockEcommerceProductTabs from '@/components/builder-controls/edit-block-ecommerce-product/EditBlockTabsEcommerceProduct.vue';
import { useBlockEcommerceProduct } from '@zyro-inc/site-modules/components/blocks/ecommerce/useBlockEcommerceProduct';
import { useSiteEngineAnimations } from '@zyro-inc/site-modules/use/useSiteEngineAnimations';
import { DATA_ATTRIBUTE_ANIMATION_STATE } from '@zyro-inc/site-modules/constants';
import {
	DEFAULT_EMPTY_PRODUCT_VALUE,
	DEMO_PRODUCTS,
} from '@zyro-inc/site-modules/constants/ecommerce';
import {
	computed,
	ref,
	watch,
} from 'vue';
import {
	ECOMMERCE_REDIRECT_PATHS,
	REDIRECT_PARAM_KEYS,
} from '@/constants';
import { useEcommerceAdminStore } from '@/stores';
import { useSiteStore } from '@/stores/siteStore';
import { SiteBlock } from '@hostinger/builder-schema-validator';
import { useEcommerceStore } from '@/stores/ecommerceStore';
import {
	EcommerceProduct,
	EcommerceProductSeoSettingsData,
} from '@zyro-inc/site-modules/types';
import ZyroSelect from '@/components/global/ZyroSelect.vue';
import { isProductPage } from '@zyro-inc/site-modules/utils/ecommerce';

const EDIT_POPUP_OPTIONS = {
	placement: 'right-start',
	placementMobile: 'right-start',
	flip: false,
	offset: {
		mainAxis: 8,
		crossAxis: 24,
	},
	offsetMobile: {
		mainAxis: 24,
		crossAxis: 0,
	},
	padding: 8,
	autoUpdate: true,
};

type Props = {
	blockId: string;
	data: SiteBlock;
	areDemoProductsShown?: boolean;
	ecommerceTranslations: Record<string, string>;
	currentPageType?: string;
	isFirstBlock?: boolean;
	transparentHeaderHeight?: number;
}

const props = withDefaults(defineProps<Props>(), {
	areDemoProductsShown: false,
	currentPageType: 'default',
	isFirstBlock: false,
	transparentHeaderHeight: 0,
});

const {
	state,
	getters,
	dispatch,
} = useStore();
const siteStore = useSiteStore();
const { t } = useI18n();

const {
	productId: blockProductId,
	blockStyle,
	blockButtonText,
	blockButtonStyle,
	blockButtonType,
	blockButtonBorderWidth,
	textColorVars,
	imageBorderRadius,
	navigationArrowsColor,
	navigationThumbnailArrowsColor,
	galleryPlacement,
	imageRatio,
	isQuantityPickerEnabled,
} = useBlockEcommerceProduct(props);
const {
	customAnimationClass,
	animationAttributeStateValue,
} = useSiteEngineAnimations({
	data: props.data,
	elementData: null,
	elementId: null,
});
const ecommerceAdminStore = useEcommerceAdminStore();
const ecommerceStore = useEcommerceStore();

const ecommerceProductRef = ref<InstanceType<typeof BlockEcommerceProduct> | null>(null);
const product = ref<EcommerceProduct>(DEFAULT_EMPTY_PRODUCT_VALUE as EcommerceProduct);

const productList = computed(() => ecommerceStore.productsSeo);
const websiteId = computed(() => state.websiteId);
const isMobileView = computed(() => state.gui.isMobileView);
const shoppingCartItems = computed(() => state.ecommerce?.shoppingCartItems);
const variantsQuantity = computed(() => state.ecommerce?.variantsQuantity);
const productId = computed(() => ecommerceStore.currentDynamicPageProductId || blockProductId.value);
const currentOpenProduct = computed(() => productList.value.find((productData) => productData.id === productId.value));
const isCartVisible = computed(() => getters['ecommerce/isCartVisible']);
const products = computed(() => [
	...ecommerceStore.products,
	...ecommerceStore.hiddenProducts,
]);
const isBlockInProductPage = computed(() => isProductPage(props.currentPageType));

const setProduct = async () => {
	if (props.areDemoProductsShown) {
		product.value = DEMO_PRODUCTS[0] as EcommerceProduct;

		return;
	}

	if (productId.value === -1) {
		product.value = DEFAULT_EMPTY_PRODUCT_VALUE;

		return;
	}

	const foundProduct = products.value.find((item) => item.id === productId.value);

	if (foundProduct) {
		product.value = foundProduct;
	} else {
		const fetchedProduct = await ecommerceStore.fetchProductById(productId.value, props.blockId);

		product.value = fetchedProduct || DEFAULT_EMPTY_PRODUCT_VALUE as EcommerceProduct;
	}
};

const handleProductSelect = (data: EcommerceProduct | EcommerceProductSeoSettingsData) => {
	ecommerceStore.setCurrentDynamicPageProductId(data.id as string);
};

const handleEditProductClick = () => {
	if (productId.value !== -1) {
		ecommerceAdminStore.setIsEcommerceAdminIframeOpen({
			isOpen: true,
			path: ECOMMERCE_REDIRECT_PATHS.PRODUCTS_EDIT,
			params: {
				[REDIRECT_PARAM_KEYS.PRODUCT]: productId.value,
			},
		});
	} else {
		ecommerceAdminStore.setIsEcommerceAdminIframeOpen({
			isOpen: true,
			path: ECOMMERCE_REDIRECT_PATHS.PRODUCTS_ADD,
		});
	}
};

const closeBlockEditPopupHandler = () => {
	dispatch('leaveBlockEditMode');
};

watch(productId, async () => {
	await setProduct();
}, {
	immediate: true,
});
</script>

<template>
	<BlockEcommerceProduct
		ref="ecommerceProductRef"
		v-qa="'builder-section-zyroecommerceproduct'"
		class="ecommerce-block-product"
		v-bind="{
			blockId,
			blockStyle,
			blockButtonText,
			blockButtonStyle,
			blockButtonType,
			blockButtonBorderWidth,
			textColorVars,
			imageBorderRadius,
			navigationArrowsColor,
			navigationThumbnailArrowsColor,
			galleryPlacement,
			imageRatio,
			isQuantityPickerEnabled,
			currentPageType
		}"
		:is-loading="ecommerceStore.isLoadingProducts[blockId]"
		:product-data="product"
		:is-mobile-view="isMobileView"
		:is-cart-visible="isCartVisible"
		:translations="ecommerceTranslations"
		:class="customAnimationClass"
		:site-id="websiteId"
		:shopping-cart-items="shoppingCartItems"
		:variants-quantity="variantsQuantity"
		:[DATA_ATTRIBUTE_ANIMATION_STATE]="animationAttributeStateValue"
	/>
	<BlockControls
		:block-id="blockId"
		:target-ref="ecommerceProductRef?.$el"
		:is-first-block="isFirstBlock"
		:transparent-header-height="transparentHeaderHeight"
	/>
	<BlockEditControls
		:block-id="blockId"
		:target-ref="ecommerceProductRef?.$el"
		:is-duplicate-button-visible="!siteStore.isCurrentPageTypeEcommerceProduct"
		:is-delete-button-visible="!siteStore.isCurrentPageTypeEcommerceProduct"
		:is-context-menu-button-visible="!siteStore.isCurrentPageTypeEcommerceProduct"
		is-reordering-button-visible
		:is-visibility-button-visible="!siteStore.isCurrentPageTypeEcommerceProduct"
		:edit-popup-options="EDIT_POPUP_OPTIONS"
		:enter-edit-mode-button-title="t('builder.editBlockButton.editSection')"
	>
		<template #additional-edit-buttons-prepend>
			<template v-if="ecommerceStore.isDynamicPageFlowEnabled && isBlockInProductPage">
				<ZyroSelect
					v-qa="'ecommerce-block-product-select'"
					class="ecommerce-block-product__product-select"
					:options="productList"
					label-key="title"
					:model-value="currentOpenProduct"
					@update:model-value="handleProductSelect"
				>
					<template #option="{ option }">
						<div
							class="ecommerce-block-product__product-select-option
						ecommerce-block-product__product-select-option--list-option"
						>
							{{ option.title }}
						</div>
					</template>

					<template #selected-option="{ option }">
						<div
							class="ecommerce-block-product__product-select-option
						ecommerce-block-product__product-select-option--selected-option"
						>
							{{ option.title }}
						</div>
					</template>
				</ZyroSelect>
				<VerticalSeparator />
			</template>
			<ControlsTooltip>
				<HostingerButton
					v-qa="'builder-section-btn-assignproduct'"
					button-type="plain"
					theme="highlight"
					@click="handleEditProductClick"
				>
					<span class="text-body-2">{{ productId !== -1 ? $t('builder.editProduct') : $t('builder.addProduct') }}</span>
				</HostingerButton>
			</ControlsTooltip>
			<VerticalSeparator />
		</template>

		<template #edit-mode-popup>
			<EditBlockEcommerceProductTabs
				:block-id="blockId"
				:is-product-purchasable="product.purchasable ?? true"
				@close="closeBlockEditPopupHandler"
			/>
		</template>
	</BlockEditControls>
</template>

<style lang="scss" scoped>
.ecommerce-block-product {
	&__product-select {
		width: 190px;
		height: 40px;
		border: none;

		:deep(.vs__dropdown-menu) {
			max-height: 220px;
		}
	}

	&__product-select-option {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;

		&--list-option {
			width: 160px
		}

		&--selected-option {
			width: 120px;
		}
	}
}
</style>
