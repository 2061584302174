<script setup lang="ts">
import { getSettings } from '@/api/AdminStoreApi';
import EventLogApi from '@/api/EventLogApi';
import Icon from '@/components/global/Icon.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import ZyroSvgDeprecated from '@/components/global/ZyroSvgDeprecated.vue';
import { useUserStore } from '@/stores';
import { useRedirects } from '@/use/useRedirects';
import {
	computed,
	watch,
} from 'vue';
import { useStore } from 'vuex';
import { ECOMMERCE_REDIRECT_PATHS } from '@/constants';
import { getCookie } from '@zyro-inc/site-modules/utils/cookies';
import { getSetupPaymentNotificationClosedAtCookieName } from '@/utils/ecommerce';

const DAY_IN_MS = 86400000;

const {
	getters,
	dispatch,
} = useStore();
const userStore = useUserStore();
const { redirectToEcommerce } = useRedirects();

const storeId = computed(() => getters.siteMeta.ecommerceStoreId);

const handleRedirectToEcommerce = async () => {
	await EventLogApi.logEvent({
		eventName: 'website_builder.ecomm_payment.enter',
		eventProperties: {
			builder: true,
			location: 'modal',
		},
	}).catch();
	redirectToEcommerce({
		path: ECOMMERCE_REDIRECT_PATHS.PAYMENT_MANAGEMENT,
	});
};

const handleCloseNotification = () => {
	dispatch('gui/setIsSetupPaymentProviderNotificationVisible', false);
};

const loadSettings = async () => {
	if (!storeId.value) {
		return;
	}

	const closedAt = Number.parseInt(getCookie(getSetupPaymentNotificationClosedAtCookieName(storeId.value)) || '0', 10);
	const diff = Date.now() - closedAt;

	if (!userStore.areFeaturesLocked && (!closedAt || diff > DAY_IN_MS)) {
		const response = await getSettings(storeId.value).catch();

		if (!response.status.paymentsEnabled) {
			dispatch('gui/setIsSetupPaymentProviderNotificationVisible', true);
		}
	}
};

watch(() => [
	storeId,
	userStore.areFeaturesLocked,
], loadSettings, {
	immediate: true,
});
</script>

<template>
	<div class="setup-payment-provider-notification">
		<ZyroSvgDeprecated name="alert-round-orange" />
		<p class="setup-payment-provider-notification__label text-body-2">
			{{ $t('builder.setupPaymentProviderNotification.label') }}
		</p>
		<HostingerButton
			size="sm"
			:title="$t('builder.setupPaymentProviderNotification.setUpPayment')"
			@click="handleRedirectToEcommerce"
		>
			{{ $t('builder.setupPaymentProviderNotification.setUpPayment') }}
		</HostingerButton>
		<HostingerButton
			button-type="plain"
			:title="$t('common.close')"
			class="setup-payment-provider-notification__close-button"
			@click="handleCloseNotification"
		>
			<template #icon>
				<Icon name="close" />
			</template>
		</HostingerButton>
	</div>
</template>

<style scoped lang="scss">
.setup-payment-provider-notification {
	position: fixed;
	bottom: 0;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 12px 48px;
	background-color: $color-light;
	border-top: solid 1px $color-gray-border;

	@media screen and (max-width: $media-mobile) {
		flex-direction: column;
		gap: 8px;
		padding: 12px 24px;
		text-align: center;
	}

	&__label {
		margin-left: 8px;
		margin-right: 16px;
	}

	&__close-button {
		position: absolute;
		top: 8px;
		right: 64px;
	}
}
</style>
