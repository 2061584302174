<template>
	<div class="add-language">
		<div class="add-language__container">
			<ZyroSelect
				v-qa="'multilingual-button-select-language'"
				class="add-language__select"
				:options="languageList"
				:disabled="!languageList.length || isLanguageLimitReached"
				:filter-by="languageFilterBy"
				label-key="name"
				:placeholder="addLabel"
				@update:model-value="handleLanguageClick"
			>
				<template #option="{ option }">
					<div v-qa="`dropdown-language-${option.locale}`">
						{{ option.name }}
					</div>
				</template>
			</ZyroSelect>
			<Teleport
				v-if="isDuplicateDialogShown"
				to="body"
			>
				<SystemDialogModal
					:title="$t('builder.multilingualDrawerDuplicateModalTitle', [selectedLanguage.name])"
					:primary-button-text="$t('common.cancel')"
					:secondary-button-text="$t('builder.multilingualDrawerAddLanguage')"
					primary-button-theme="text"
					width="400px"
					@click-primary="isDuplicateDialogShown = false"
					@click-secondary="handleDuplicateDialogConfirm"
					@close="isDuplicateDialogShown = false"
				>
					<i18n-t
						tag="p"
						class="text-body-1"
						keypath="builder.multilingualDrawerDuplicateModalText"
					>
						<span class="text-bold-1">
							{{ $t("builder.multilingualDrawerDuplicateModalTextSlot") }}
						</span>
					</i18n-t>
				</SystemDialogModal>
			</Teleport>
		</div>
	</div>
</template>

<script setup>
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

import SystemDialogModal from '@/components/builder-modals/modals/SystemDialogModal.vue';
import ZyroSelect from '@/components/global/ZyroSelect.vue';
import { MULTILINGUAL_SUPPORTED_LANGUAGES } from '@/data';
import EventLogApi from '@/api/EventLogApi';
import {
	computed,
	ref,
} from 'vue';

const {
	getters,
	dispatch,
} = useStore();
const { t } = useI18n();

const MAX_LANGUAGES = 4;

const isDuplicateDialogShown = ref(false);
const selectedLanguage = ref({});
const currentLocales = computed(() => getters.siteLanguagesArray.map(({ locale }) => locale));
const isLanguageLimitReached = computed(() => currentLocales.value.length >= MAX_LANGUAGES);
const addLabel = computed(() => {
	if (isLanguageLimitReached.value) {
		return t('builder.multilingualDrawerMaxLanguages');
	}

	return getters.hasLanguages ? t('builder.multilingualDrawerAddLanguage') : t('builder.multilingualDrawerSetStartingLang');
});

const languageList = computed(() => {
	const filtered = [];

	return MULTILINGUAL_SUPPORTED_LANGUAGES.map((language) => {
		const [name] = language.name.split(' (');

		if (filtered.includes(name)) {
			return null;
		}

		filtered.push(name);

		return {
			...language,
			country: null,
			name,
		};
	}).filter((language) => language !== null && !currentLocales.value.includes(language.locale));
});

const languageFilterBy = (option, _label, searchTerm) => {
	const searchTermLowercased = searchTerm.toLowerCase();

	return option.name.toLowerCase().includes(searchTermLowercased) || option.alternateName.toLowerCase().includes(searchTermLowercased);
};

const handleLanguageClick = (language) => {
	selectedLanguage.value = language;

	if (getters.hasLanguages && getters.siteLanguagesArray.length === 1) {
		isDuplicateDialogShown.value = true;

		return;
	}

	dispatch('addLanguage', language);
	EventLogApi.logEvent({
		eventName: 'website_builder.languages.add',
	});
};

const handleDuplicateDialogConfirm = () => {
	dispatch('addLanguage', selectedLanguage.value);
	isDuplicateDialogShown.value = false;
};
</script>
<style lang="scss" scoped>
.add-language {
	$this: &;

	&__select {
		margin-bottom: 8px;
	}

	&__container {
		position: relative;
	}
}
</style>
