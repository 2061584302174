<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import {
	ref,
	computed,
} from 'vue';
import { useStore } from 'vuex';
import {
	DATA_ATTRIBUTE_SELECTOR,
	DATA_ATTRIBUTE_SELECTOR_MODAL_CONTENT,
	MEDIA_MOBILE_BREAKPOINT,
} from '@zyro-inc/site-modules/constants';
import ZyroTextArea from '@/components/global/ZyroTextArea.vue';
import Icon from '@/components/global/Icon.vue';
import ZyroSvgDeprecated from '@/components/global/ZyroSvgDeprecated.vue';
import ZyroCheckbox from '@/components/global/ZyroCheckbox.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import { sendNpsScore } from '@/api/NpsApi';
import {
	NPS_TYPE_BUILDER_GENERAL,
	NPS_TYPE_AI_PREVIEW,
	NPS_TYPE_AI_ONLINE_STORE_PREVIEW,
} from '@/constants';
import EventLogApi from '@/api/EventLogApi';
import { CheckboxOption } from '@/types';
import { useNpsStore } from '@/stores';

const STEP_RATE = 'STEP_RATE';
const STEP_COMMENT = 'STEP_COMMENT';
const STEP_THANKS = 'STEP_THANKS';

type Props = {
	isCloseButtonHidden?: boolean,
	checkboxesData?: CheckboxOption[],
	commentPlaceholder?: string,
	commentStepTitle?: string,
	commentAreaLabel?: string,
};

const props = defineProps<Props>();

const emit = defineEmits([
	'close',
	'send-feedback',
	'select-rate',
	'click-back',
]);

const checkboxOptions = ref(props.checkboxesData?.map((checkbox) => ({
	...checkbox,
	isChecked: false,
})));

const { t } = useI18n();

const { state } = useStore();
const npsStore = useNpsStore();

const currentStep = ref(STEP_RATE);
const comment = ref('');
const score = ref(-1);

const rateStepTitle = computed(() => npsStore.question || t('builder.nps.ratingQuestion'));

const selectScore = ({ scoreToSet }: { scoreToSet: number }) => {
	score.value = scoreToSet;
	currentStep.value = STEP_COMMENT;

	emit('select-rate');
};

const handleBackClick = () => {
	currentStep.value = STEP_RATE;

	emit('click-back');
};

const sendFeedback = () => {
	emit('send-feedback', {
		score,
		comment,
	});

	currentStep.value = STEP_THANKS;

	const markedCheckboxes = checkboxOptions.value?.filter(({ isChecked }) => isChecked).map(({ id }) => id);

	sendNpsScore({
		score: score.value,
		comment: comment.value,
		formType: npsStore.npsData.formType,
		siteId: state.websiteId,
		device: window.screen.width <= MEDIA_MOBILE_BREAKPOINT ? 'mobile' : 'desktop',
		importedWebsiteUrl: npsStore.npsData.importedWebsiteUrl,
		checkboxesData: markedCheckboxes,
	});

	if ([
		NPS_TYPE_AI_PREVIEW,
		NPS_TYPE_AI_ONLINE_STORE_PREVIEW,
		NPS_TYPE_BUILDER_GENERAL,
	].includes(npsStore.npsData.formType)) {
		const checkboxesStatuses = checkboxOptions.value ? Object.fromEntries(checkboxOptions.value.map(({
			id,
			isChecked,
		}) => [
			id,
			isChecked,
		])) : {};

		EventLogApi.logEvent({
			eventName: 'website_builder.feedback.sent',
			eventProperties: {
				rating: score.value,
				feedback: comment.value,
				location: npsStore.npsData.formType,
				...(NPS_TYPE_AI_PREVIEW === npsStore.npsData.formType && {
					...checkboxesStatuses,
				}),
			},
		});
	}
};
</script>

<template>
	<div
		class="feedback"
		:[DATA_ATTRIBUTE_SELECTOR]="DATA_ATTRIBUTE_SELECTOR_MODAL_CONTENT"
	>
		<div
			v-if="currentStep === STEP_RATE"
			class="feedback-container"
		>
			<p class="feedback-container__title text-bold-1">
				{{ rateStepTitle }}
			</p>
			<div class="feedback-container__ratings-row">
				<button
					v-for="rating in 10"
					:key="rating"
					v-qa="`feedback-container-rating-${rating}`"
					theme="outline"
					class="feedback-container__rating text-body-2"
					@click="selectScore({ scoreToSet: rating })"
				>
					{{ rating }}
				</button>
			</div>
			<div class="feedback-container__rating-titles">
				<p class="feedback-container__rating-title text-body-2">
					{{ t('builder.nps.poor') }}
				</p>
				<p class="feedback-container__rating-title text-body-2">
					{{ t('builder.nps.excellent') }}
				</p>
			</div>
		</div>

		<div
			v-else-if="currentStep === STEP_COMMENT"
			class="feedback-container feedback-container--fixed-width"
		>
			<p class="feedback-container__title feedback-container__second-step-title text-bold-1">
				{{ commentStepTitle ?? t('builder.nps.commentTitle') }}
			</p>

			<div
				v-if="checkboxOptions"
				class="feedback-container__checkboxes-wrapper"
			>
				<div
					v-for="checkbox in checkboxOptions"
					:key="checkbox.id"
					class="feedback-container__checkbox"
				>
					<ZyroCheckbox
						:id="checkbox.id"
						v-qa="`feedback-container-checkbox-${checkbox.id}`"
						:model-value="checkbox.isChecked"
						@update:model-value="checkbox.isChecked = !checkbox.isChecked"
					/>
					<p class="text-body-2">
						{{ checkbox.text }}
					</p>
				</div>
			</div>
			<label
				v-if="commentAreaLabel"
				for="comment-area"
				class="feedback-container__text-area-label text-bold-2"
			>
				{{ commentAreaLabel }}
			</label>
			<ZyroTextArea
				id="comment-area"
				v-model="comment"
				theme="hostinger"
				:placeholder="commentPlaceholder ?? t('builder.nps.inputPlaceholder')"
				maxlength="300"
				class="feedback-container__text-area"
			/>

			<HostingerButton
				v-qa="'feedback-container-submit-button'"
				class="feedback-container__submit-button"
				@click="sendFeedback"
			>
				{{ t('common.send') }}
			</HostingerButton>
		</div>

		<div
			v-else-if="currentStep === STEP_THANKS"
			class="feedback-container feedback-container--fixed-width feedback-container--fixed-height"
		>
			<ZyroSvgDeprecated
				class="feedback-container__like-icon"
				name="like-blue"
			/>
			<p class="text-body-2">
				{{ t('builder.nps.thankYou') }}
			</p>
			<slot name="thanks" />
		</div>

		<HostingerButton
			v-if="!props.isCloseButtonHidden"
			:title="t('common.close')"
			class="close-button"
			button-type="plain"
			@click="emit('close')"
		>
			<template #icon>
				<Icon name="close" />
			</template>
		</HostingerButton>

		<HostingerButton
			v-if="currentStep === STEP_COMMENT"
			:title="t('common.goBack')"
			class="back-button"
			button-type="plain"
			@click="handleBackClick"
		>
			<template #icon>
				<Icon
					name="chevron_left"
					dimensions="24px"
				/>
			</template>
		</HostingerButton>
	</div>
</template>

<style lang="scss" scoped>
.feedback {
	position: relative;
}

.close-button {
	position: absolute;
	top: 8px;
	right: 8px;
}

.back-button {
	position: absolute;
	top: 8px;
	left: 8px;
}

.feedback-container {
	display: flex;
	flex-direction: column;
	padding: 16px 24px;
	background-color: $color-light;
	align-items: center;
	text-align: center;
	justify-content: center;

	&__title {
		margin-bottom: 24px;
		color: $color-dark;
		max-width: 450px;

		@media screen and (max-width: $media-mobile) {
			max-width: 246px;
		}
	}

	&__second-step-title {
		max-width: 288px;
	}

	&__ratings-row {
		display: flex;
		flex-wrap: wrap;
		gap: 8px;
		margin-bottom: 8px;
		justify-content: center;
	}

	&__rating {
		border: 1px solid $color-gray-border;
		padding: 8px;
		border-radius: 8px;
		width: 40px;
		height: 40px;
		transition: 0.2s ease;
		color: $color-dark;

		&:hover, &:focus {
			cursor: pointer;
			background-color: $color-gray-light;
		}
	}

	&__rating-titles {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}

	&__rating-title {
		color: $color-gray;
	}

	&__text-area {
		margin-bottom: 16px;
	}

	&__submit-button {
		align-self: flex-end;
	}

	&__like-icon {
		width: 100%;
		margin-bottom: 24px;
	}

	&--fixed-width {
		width: 400px;
	}

	&--fixed-height {
		height: 288px;
	}

	@media screen and (max-width: $media-mobile) {
		width: 100%;
	}

	&__checkbox {
		display: flex;
		align-items: center;
		gap: 8px;
	}

	&__checkboxes-wrapper {
		display: flex;
		gap: 4px;
		flex-direction: column;
		margin-bottom: 24px;
		width: 100%;
	}

	&__text-area-label {
		width: 100%;
		text-align: left;
		margin-bottom: 8px;
	}
}
</style>
