<template>
	<ZyroDrawer
		class="manage-ecommerce-drawer"
		:header-title="$t('builder.onlineStore')"
		is-back-button-visible-on-desktop
		@back="handleBack"
	>
		<template #body>
			<div class="manage-ecommerce-drawer__list">
				<div class="manage-ecommerce-drawer__info">
					<div class="manage-ecommerce-drawer__title-container">
						<p class="manage-ecommerce-drawer__title text-bold-2">
							{{ $t('common.products') }}
						</p>
						<ZyroTooltip
							position="bottom"
							toggle-event="hover"
							mode="dark"
							size="small"
							is-on-drawer
							class="manage-ecommerce-drawer__tooltip"
						>
							<template #trigger>
								<Icon
									v-qa="'drawer-onlinestore-storeproducts-tooltip'"
									name="help"
									dimensions="20px"
								/>
							</template>
							<p class="manage-ecommerce-drawer__tooltip-text text-body-2">
								{{ $t('builder.onlineStoreTooltip') }}
							</p>
						</ZyroTooltip>
					</div>

					<div
						v-qa="'drawer-onlinestore-manageproducts-button'"
						button-type="text"
						class="manage-ecommerce-drawer__manage-products text-bold-2"
						@click="handleManageProductsButtonClick"
					>
						{{ $t('builder.ecommerceManageProducts') }}
					</div>
				</div>
				<ManageEcommercePageItem
					v-for="(page, key) in sortedProductPages"
					:id="key"
					:key="`page-${key}`"
					v-qa="'manage-ecommerce-card-page'"
					:page="page"
					@edit="openEditProductIframe(page.productId)"
					@open-seo-settings="openPageSEOSettings({ pageId: key })"
					@open-settings="(tabName) => {
						tabToOpen = tabName;
						pageToOpenId = key;
					}"
					@open-page="handleOpenPage(key)"
				/>
			</div>
		</template>

		<template #footer>
			<div class="manage-ecommerce-drawer__footer">
				<HostingerButton
					v-qa="'manage-ecommerce-drawer-btn-manage'"
					@click="handleManageStoreClick"
				>
					{{ $t('builder.editStore.title') }}
				</HostingerButton>
				<ManageEcommerceSettings v-if="isStoreTypeZyro" />
			</div>
			<PageSettingsPopup
				v-if="pageToOpenId"
				:page-id="pageToOpenId"
				:open-tab="tabToOpen"
				@close="pageToOpenId = ''"
			/>
		</template>
	</ZyroDrawer>
</template>

<script setup lang="ts">
import Icon from '@/components/global/Icon.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import ZyroDrawer from '@/components/global/ZyroDrawer.vue';
import ZyroTooltip from '@/components/global/ZyroTooltip.vue';

import {
	ref,
	computed,
} from 'vue';

import { useStore } from 'vuex';
import {
	DRAWER_MANAGE_STORE,
	DRAWER_MANAGE_STORE_PRODUCTS,
	DRAWER_SEO,
	ECOMMERCE_REDIRECT_PATHS,
} from '@/constants';
import PageSettingsPopup from '@/components/builder-modals/modals/PageSettingsPopup.vue';
import ManageEcommercePageItem from '@/components/builder-drawers/drawers/partials/ecommerceStoreDrawer/ManageEcommercePageItem.vue';
import ManageEcommerceSettings from '@/components/builder-drawers/drawers/partials/ecommerceStoreDrawer/ManageEcommerceSettings.vue';
import { useRedirects } from '@/use/useRedirects';

import { SitePages } from '@hostinger/builder-schema-validator';
import EventLogApi from '@/api/EventLogApi';
import { TabsType } from '@zyro-inc/site-modules/constants';
import { EcommerceProduct } from '@zyro-inc/site-modules/types/index';
import { useEcommerceAdminStore } from '@/stores';
import { useEcommerceStore } from '@/stores/ecommerceStore';

const {
	dispatch,
	getters,
	state,
} = useStore();

const {
	redirectToEcommerce,
	redirectToEcommerceProducts,
} = useRedirects();
const ecommerceAdminStore = useEcommerceAdminStore();
const ecommerceStore = useEcommerceStore();

const pageToOpenId = ref<string>();
const tabToOpen = ref<TabsType>();

const legacyProductPages = computed<SitePages>(() => getters.ecommerceLegacyProductPages);
const ecommerceProductPages = computed<SitePages>(() => Object.fromEntries(Object.entries(legacyProductPages.value)
	.map(([key, value]) => {
		const product = ecommerceStore.products.find(({ id } : EcommerceProduct) => id === value.productId)
				|| ecommerceStore.hiddenProducts.find(({ id } : EcommerceProduct) => id === value.productId);

		return [
			key,
			{
				...value,
				meta: product?.seo_settings || {},
			},
		];
	})));
const currentLocale = computed<string>(() => state.currentLocale);
const defaultLocale = computed<string>(() => getters.defaultLocale);
const isStoreTypeZyro = computed(() => getters['ecommerce/isStoreTypeZyro']);
const sortedProductPages = computed<SitePages>(() => Object.fromEntries(
	Object.entries(ecommerceProductPages.value)
		.sort(([, a], [, b]) => (a.name || '').localeCompare((b.name || ''))),
));

const openPageSEOSettings = ({ pageId }:{pageId: string}) => {
	dispatch('gui/OPEN_DRAWER', {
		id: DRAWER_SEO,
		settings: {
			pageId,
			goBackCallback: () => {
				dispatch('gui/TOGGLE_DRAWER', DRAWER_MANAGE_STORE_PRODUCTS);
			},
		},
	});
};

const openEditProductIframe = (productId: string | undefined) => {
	if (!productId) {
		return;
	}

	ecommerceAdminStore.setIsEcommerceAdminIframeOpen({
		isOpen: true,
		path: ECOMMERCE_REDIRECT_PATHS.PRODUCTS_EDIT,
		params: {
			product: productId,
		},
	});
};

const handleOpenPage = (key: string) => {
	if (currentLocale.value === defaultLocale.value) {
		dispatch('updateCurrentPageId', key);
	} else {
		dispatch('updateCurrentLocale', defaultLocale.value);
		dispatch('updateCurrentPageId', key);
	}
};

const handleManageProductsButtonClick = async () => {
	await EventLogApi.logEvent({
		eventName: 'website_builder.ecomm_products.add',
		eventProperties: {
			location: 'side_menu',
		},
	});

	redirectToEcommerceProducts();
};

const handleManageStoreClick = async () => {
	await EventLogApi.logEvent({
		eventName: 'website_builder.ecomm.enter',
	});

	redirectToEcommerce();
};

const handleBack = () => {
	dispatch('gui/TOGGLE_DRAWER', DRAWER_MANAGE_STORE);
};
</script>

<style lang="scss" scoped>
.manage-ecommerce-drawer {
	overflow: visible;

	&__title-container {
		display: flex;
		align-items: center;
	}

	&__tooltip {
		position: relative;
		margin-left: 4px;
		cursor: pointer;
	}

	&__tooltip-text {
		max-width: 200px;
		text-align: center;
	}

	&__content {
		display: flex;
		flex-direction: column;
		height: 100%;
		overflow: hidden;
	}

	&__text {
		color: $color-gray;
	}

	&__list {
		width: 100%;
		border-bottom: 1px solid $color-gray-border;
	}

	&__info {
		display: flex;
		justify-content: space-between;
		padding-bottom: 16px;
	}

	&__footer {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}

	&__manage-products {
		display: flex;
		align-items: center;
		color: $color-primary;
		cursor: pointer;
	}
}
</style>
