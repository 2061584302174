import { defineStore } from 'pinia';
import { captureException } from '@sentry/vue';
import { getCookie } from '@zyro-inc/site-modules/utils/cookies';
import {
	NPS_TYPE_BUILDER_GENERAL,
	NPS_MAX_DAYS_AFTER_LAST_SCORE,
	NPS_DATA_MAP,
	COOKIE_NPS_HIDDEN,
} from '@/constants';
import { getLastEvaluation } from '@/api/NpsApi';
import { PINIA_STORES } from '@/constants/stores';
import { ref } from 'vue';

type FormType = keyof typeof NPS_DATA_MAP;

type UpdateNpsData = {
	formType: FormType;
	importedWebsiteUrl?: string;
}

export const useNpsStore = defineStore(PINIA_STORES.NPS, () => {
	const isNpsVisible = ref(false);
	const question = ref('');
	const npsData = ref({
		titleI18Key: '',
		formType: NPS_TYPE_BUILDER_GENERAL,
		maxScoreI18Key: '',
		lowestScoreI18Key: '',
		importedWebsiteUrl: '',
	});

	const setNpsVisible = (isVisible: boolean) => {
		isNpsVisible.value = isVisible;
	};

	const setNpsQuestion = (newQuestion: string) => {
		question.value = newQuestion;
	};

	const updateNpsData = ({
		formType,
		importedWebsiteUrl = '',
	}: UpdateNpsData) => {
		if (!NPS_DATA_MAP[formType]) {
			throw new Error(`Unknown "${formType}" was used`);
		}

		npsData.value = {
			...NPS_DATA_MAP[formType],
			importedWebsiteUrl,
		};
	};

	const refetchNpsModalVisibilityStatus = async ({ formType }:{formType?: FormType} = {}) => {
		try {
			const { daysPassed } = await getLastEvaluation(NPS_TYPE_BUILDER_GENERAL);
			const hasTermAfterLastSubmissionPassed = daysPassed > NPS_MAX_DAYS_AFTER_LAST_SCORE || daysPassed === null;
			const hasTermAfterLastModalClosePassed = getCookie(COOKIE_NPS_HIDDEN) !== 'true';
			const canUpdateNps = hasTermAfterLastSubmissionPassed && hasTermAfterLastModalClosePassed;

			if (canUpdateNps) {
				updateNpsData({
					formType: formType || NPS_TYPE_BUILDER_GENERAL,
				});
			}

			setNpsVisible(canUpdateNps);
		} catch (error) {
			captureException(error);
			setNpsVisible(false);
		}
	};

	type SetNpsData = {
		isVisible: boolean;
		formType?: FormType;
		importedWebsiteUrl?: string;
		newQuestion?: string
	}
	const setNpsData = ({
		isVisible,
		formType,
		importedWebsiteUrl,
		newQuestion,
	}:SetNpsData) => {
		updateNpsData({
			formType: formType || NPS_TYPE_BUILDER_GENERAL,
			importedWebsiteUrl,
		});

		if (newQuestion) {
			setNpsQuestion(newQuestion);
		}

		setNpsVisible(isVisible);
	};

	return {
		isNpsVisible,
		question,
		npsData,
		setNpsVisible,
		setNpsQuestion,
		updateNpsData,
		refetchNpsModalVisibilityStatus,
		setNpsData,
	};
});
