<template>
	<div class="categories-list">
		<EditableItemsWithDropdown
			v-qa="'blog-post-settings-select-categories-btn'"
			:editable-items="editableItems"
			:placeholder="$t('builder.blog.blogPostSettings.writeCategories')"
			:button-text="$t('builder.blog.blogPostSettings.addCategory')"
			:item-placeholder-text="$t('builder.blog.blogPostSettings.itemPlaceholder')"
			:title="$t('builder.blog.blogPostSettings.selectCategories')"
			:dropdown-items="blogStore.categoriesNames"
			:validate-value="validator"
			:selected-items="selectedItems"
			@edit="handleCategoryEdit"
			@update-items="updateItems"
			@add="addCategory"
			@select="selectCategory"
			@deselect="deselectCategory"
		/>
	</div>
</template>

<script>
import {
	mapActions,
	useStore,
} from 'vuex';

import EditableItemsWithDropdown from '@/components/reusable-components/editable-items-list/-partials/EditableItemsWithDropdown.vue';

import {
	computed,
	defineComponent,
} from 'vue';
import { useBlogStore } from '@/stores/blogStore';

export default defineComponent({
	components: {
		EditableItemsWithDropdown,
	},
	setup() {
		const { state } = useStore();
		const blogStore = useBlogStore();

		const activeModalSettings = computed(() => state.gui.activeModalSettings);

		return {
			blogStore,
			activeModalSettings,
		};
	},

	computed: {
		selectedItems() {
			return this.blogStore.getPostCategoryNames(this.activeModalSettings.blogPostId);
		},
		editableItems() {
			return this.selectedItems.map((name) => ({
				name,
			}));
		},
	},

	methods: {
		...mapActions(['mergePageData']),
		validator(categoryName) {
			if (!categoryName) {
				return {
					isValid: false,
					error: this.$t('validate.emptyValue'),
				};
			}

			if (this.blogStore.categoriesNames.includes(categoryName)) {
				return {
					isValid: false,
					error: this.$t('builder.blog.blogPostSettings.error'),
				};
			}

			return {
				isValid: true,
				error: '',
			};
		},
		updateItems(newCategoryNames) {
			this.mergePageData({
				pageId: this.activeModalSettings.blogPostId,
				pageData: {
					categories: newCategoryNames.map(({ name }) => this.blogStore.getCategoryIdByName(name)),
				},
			});
		},
		addCategory(newItem) {
			this.blogStore.addPostToCategory({
				categoryName: newItem,
				postPageId: this.activeModalSettings.blogPostId,
			});
		},
		handleCategoryEdit({
			oldItem,
			newItem,
		}) {
			this.blogStore.editCategory({
				oldName: oldItem.name,
				newName: newItem.name,
			});
		},
		selectCategory(categoryName) {
			this.mergePageData({
				pageId: this.activeModalSettings.blogPostId,
				pageData: {
					categories: [
						...this.blogStore.getPostCategories(this.activeModalSettings.blogPostId),
						this.blogStore.getCategoryIdByName(categoryName),
					],
				},
			});
		},
		deselectCategory(categoryName) {
			const categoryToRemoveId = this.blogStore.getCategoryIdByName(categoryName);

			this.mergePageData({
				pageId: this.activeModalSettings.blogPostId,
				pageData: {
					categories: [
						...this.blogStore.getPostCategories(this.activeModalSettings.blogPostId)
							.filter((categoryId) => categoryId !== categoryToRemoveId),
					],
				},
			});
		},
	},
});
</script>
<style lang="scss" scoped>
.categories-list {
	min-height: 150px;
}
</style>
