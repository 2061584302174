<template>
	<ZyroDrawer
		class="manage-ecommerce-drawer"
		:header-title="$t('builder.onlineStore')"
		@back="closeInnerSidebar"
	>
		<template #header-title-right-slot>
			<CircularProgressBar
				v-if="areEcommerceGamificationTipsShown"
				:percentage="ecommerceGamificationCompletionPercentage"
				size="large"
				class="sidebar-controls__progress-bar"
			>
				{{ completedEcommerceTipsCount }}/{{ ecommerceTips.length }}
			</CircularProgressBar>
		</template>
		<template #body>
			<GamificationTaskList
				v-if="areEcommerceGamificationTipsShown"
				class="manage-ecommerce-drawer__gamification-list"
				:achievements="ecommerceTips"
				:title="t('builder.gamificationFinishYourSetup')"
				:add-top-margin="false"
				show-icon
			/>
			<div class="manage-ecommerce-drawer__list">
				<template v-if="ecommerceStore.isDynamicPageFlowEnabled">
					<div class="manage-ecommerce-drawer__info">
						<p class="manage-ecommerce-drawer__title text-bold-2">
							{{ $t('builder.productPageTemplate') }}
						</p>
						<p class="manage-ecommerce-drawer__description">
							{{ $t('builder.productPageTemplateDescription') }}
						</p>
					</div>
					<div class="manage-ecommerce-drawer__items">
						<div
							v-qa="`manage-ecommerce-item-space_dashboard`"
							class="manage-ecommerce-drawer__item"
							@click="handleEditDynamicProductPageClick"
						>
							<Icon
								class="manage-ecommerce-drawer__item-icon"
								name="space_dashboard"
								dimensions="20px"
							/>
							<p class="manage-ecommerce-drawer__item-title text-body-2">
								{{ $t('builder.editTemplate') }}
							</p>

							<Icon
								class="manage-ecommerce-drawer__item-arrow"
								name="chevron_right"
								dimensions="24px"
							/>
						</div>
					</div>
				</template>

				<div class="manage-ecommerce-drawer__info">
					<p class="manage-ecommerce-drawer__title text-bold-2">
						{{ $t('builder.builderHeaderManage') }}
					</p>
				</div>
				<div class="manage-ecommerce-drawer__items">
					<div
						v-for="(item, index) in storeManagementItems"
						:key="index"
						v-qa="`manage-ecommerce-item-${item.iconName}`"
						class="manage-ecommerce-drawer__item"
						@click="item.action"
					>
						<Icon
							class="manage-ecommerce-drawer__item-icon"
							:name="item.iconName"
							dimensions="20px"
						/>
						<p class="manage-ecommerce-drawer__item-title text-body-2">
							{{ item.title }}
						</p>

						<Icon
							class="manage-ecommerce-drawer__item-arrow"
							name="chevron_right"
							dimensions="24px"
						/>
					</div>
				</div>
			</div>
		</template>

		<template #footer>
			<div class="manage-ecommerce-drawer__footer">
				<HostingerButton
					v-qa="'manage-ecommerce-drawer-btn-manage'"
					@click="handleManageStoreClick"
				>
					{{ $t('builder.editStore.title') }}
				</HostingerButton>
				<ManageEcommerceSettings />
			</div>
			<PageSettingsPopup
				v-if="pageToOpenId"
				:page-id="pageToOpenId"
				:open-tab="tabToOpen"
				@close="pageToOpenId = ''"
			/>
		</template>
	</ZyroDrawer>
</template>

<script setup lang="ts">
import Icon from '@/components/global/Icon.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import ZyroDrawer from '@/components/global/ZyroDrawer.vue';

import { useSidebar } from '@/use/useSidebar';
import { useGamification } from '@/use/useGamification';

import {
	ref,
	computed,
} from 'vue';
import { useStore } from 'vuex';

import {
	DRAWER_MANAGE_STORE_PRODUCTS,
	ECOMMERCE_REDIRECT_PATHS,
} from '@/constants';
import PageSettingsPopup from '@/components/builder-modals/modals/PageSettingsPopup.vue';
import ManageEcommerceSettings from '@/components/builder-drawers/drawers/partials/ecommerceStoreDrawer/ManageEcommerceSettings.vue';
import CircularProgressBar from '@/components/reusable-components/CircularProgressBar.vue';
import GamificationTaskList from '@/components/onboarding/GamificationTaskList.vue';
import { useRedirects } from '@/use/useRedirects';
import EventLogApi from '@/api/EventLogApi';
import { TabsType } from '@zyro-inc/site-modules/constants';
import { useI18n } from 'vue-i18n';
import { useEcommerceAdminStore } from '@/stores/ecommerceAdminStore';
import { useEcommerceStore } from '@/stores/ecommerceStore';

const {
	dispatch,
	state,
	getters,
} = useStore();

const { t } = useI18n();

const { redirectToEcommerce } = useRedirects();
const {
	completedEcommerceTipsCount,
	ecommerceTips,
} = useGamification();
const { closeInnerSidebar } = useSidebar();
const ecommerceAdminStore = useEcommerceAdminStore();
const ecommerceStore = useEcommerceStore();

const pageToOpenId = ref<string>();
const tabToOpen = ref<TabsType>();

const areEcommerceGamificationTipsShown = computed(() => ecommerceTips.value.length !== completedEcommerceTipsCount.value);
const ecommerceGamificationCompletionPercentage = computed(() => (completedEcommerceTipsCount.value / ecommerceTips.value.length) * 100);

const storeManagementItems = [
	{
		title: t('builder.ecommerceShopSettings'),
		iconName: 'settings',
		action: () => {
			ecommerceAdminStore.setIsEcommerceAdminIframeOpen({
				isOpen: true,
				path: ECOMMERCE_REDIRECT_PATHS.STORE_SETTINGS,
			});
		},
	},
	{
		title: t('common.products'),
		iconName: 'sell',
		action: () => {
			if (ecommerceStore.isDynamicPageFlowEnabled) {
				ecommerceAdminStore.setIsEcommerceAdminIframeOpen({
					isOpen: true,
					path: ECOMMERCE_REDIRECT_PATHS.PRODUCTS,
				});

				return;
			}

			dispatch('gui/TOGGLE_DRAWER', DRAWER_MANAGE_STORE_PRODUCTS);
		},
	},
];
const handleEditDynamicProductPageClick = () => {
	if (getters.isCurrentPageTypeDynamicProduct) {
		return;
	}

	const [dynamicProductPageId] = Object.keys(getters.ecommerceDynamicProductPageTemplates);
	const { defaultLocale } = getters;
	const { currentLocale } = state;

	if (currentLocale !== defaultLocale) {
		dispatch('updateCurrentLocale', defaultLocale);
	}

	dispatch('updateCurrentPageId', dynamicProductPageId);
	ecommerceStore.setCurrentDynamicPageProductId(ecommerceStore.products[0].id as string);
};

const handleManageStoreClick = async () => {
	await EventLogApi.logEvent({
		eventName: 'website_builder.ecomm.enter',
	});

	redirectToEcommerce();
};
</script>

<style lang="scss" scoped>
.manage-ecommerce-drawer {
	&__info {
		margin: 16px 0;
	}

	&__description {
		color: $color-gray;
		font-size: 14px;
		line-height: 1.7;
		margin-top: 4px;
	}

	&__item {
		display: flex;
		height: 56px;
		align-items: center;
		border-bottom: 1px solid $color-gray-border;
		padding: 12px 8px;
		cursor: pointer;

		&:hover,
		&:focus {
			background-color: $color-gray-light;
			transition: background-color 0.3s ease 0s;
		}

		&:last-child {
			margin-left: auto;
		}
	}

	&__item-icon {
		margin-right: 8px;
	}

	&__item-title {
		margin-right: 16px;
	}

	&__item-arrow {
		margin-left: auto;
		color: $color-gray-dark;
	}

	&__gamification-list {
		margin-bottom: 16px;
	}

	&__footer {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}
}
</style>
