// eslint-disable-next-line import/no-cycle
import axios from '@/services/http/axios';
import {
	EcommerceUpdateProductsSeoPayload,
	EcommerceProduct,
} from '@zyro-inc/site-modules/types';

const ECOMMERCE_API = `${import.meta.env.VITE_ECOMMERCE_API_URL}/admin/products`;

export const updateProductsSeo = async (storeId: string, payload: EcommerceUpdateProductsSeoPayload[]): Promise<EcommerceProduct[]> => {
	const { data }: {data: any} = await axios.post(`${ECOMMERCE_API}/seo-settings`, {
		products: payload,
	}, {
		headers: {
			'x-ecommerce-id': storeId,
		},
	});

	return data?.products;
};
